import React from 'react';

interface Props {
  href: string;
  children: React.ReactNode;
  className?: string;
}

export default function ExternalLink({
  href,
  children,
  className = '',
}: Props) {
  return (
    <a
      rel="noopener noreferrer"
      target="_blank"
      href={href}
      className={`${className}`}
    >
      {children}
    </a>
  );
}
