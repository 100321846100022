import React from 'react';

import styles from './SeminarFolder.module.css';

import SeminarFile from 'src/components/IFAcomponents/SeminarFile/SeminarFile';
import apiResources, { FirebaseFolder } from 'src/services/apiResources';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import ClosedFolderPicture from './ClosedFolder.png';
import MessageBar from '../MessageBar/MessageBar';

interface SeminarFolderData {
  seminarFolder: FirebaseFolder;
  indent: number;
  isAdmin: boolean;
}

export default function SeminarFolder({
  seminarFolder,
  indent = 0,
  isAdmin,
  ...props
}: SeminarFolderData) {
  // const indentPx = `${indent * 24}px`;
  const [dataUrl, setDataUrl] = React.useState<string>();
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadError, setUploadError] = React.useState('');
  const [attachment, setAttachment] = React.useState({
    id: null,
    name: '',
    href: '',
    isFile: false,
    isUrl: false,
  });

  // Warning: If statements do not make recursive code: What will you do on the 10th or 100th level?
  // Instead of thinking about it in absolute terms - think about it relative to your parent
  const folderIndentSpace =
    indent === 0
      ? styles.imageContainerZero
      : indent === 1
        ? styles.imageContainerOne
        : indent === 2
          ? styles.imageContainerTwo
          : indent === 3
            ? styles.imageContainerThree
            : styles.imageContainerFour;

  const onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const dataUrl = await apiResources.readAsDataURL(file);

      if (dataUrl) {
        setAttachment({ ...attachment, name: file.name });
        setDataUrl(dataUrl as string);
      }
    }
  };

  const uploadAttachment = async (prefixFullPath: string): Promise<void> => {
    if (!dataUrl) return;
    setIsUploading(true);
    const fullPath = `${prefixFullPath}/${attachment.name}`;
    try {
      await apiResources.uploadDatUrl(fullPath, dataUrl);
    } catch {
      setUploadError('Error uploading file');
    }
    setIsUploading(false);
    setDataUrl(undefined);
  };

  return (
    <div>
      <div className={styles.seminarFileContainer}>
        <span>
          <img
            src={ClosedFolderPicture}
            className={`${folderIndentSpace}`}
            alt="Folder Icon"
          />
        </span>
        <p>{seminarFolder?.name}</p>
        {isAdmin && (
          <div className={`${styles.uploadContainer}`}>
            <input type="file" onChange={onFileChange} />
            {dataUrl && (
              <PrimaryButton
                onClick={() => uploadAttachment(seminarFolder.fullPath)}
                disabled={isUploading || !dataUrl}
                className={`${styles.uploadButtonContainer}`}
              >
                Upload
              </PrimaryButton>
            )}
            {uploadError && (
              <MessageBar messageBarType={'error'} messageText={uploadError} />
            )}
          </div>
        )}
      </div>

      {seminarFolder.files &&
        seminarFolder?.files.map((seminar, index) => {
          return (
            <SeminarFile
              seminarFile={seminar}
              fileIndentLevel={indent}
              key={index}
            />
          );
        })}
      {seminarFolder.folders &&
        seminarFolder?.folders.map((folder, index) => {
          return (
            <SeminarFolder
              seminarFolder={folder}
              indent={indent + 1}
              isAdmin={isAdmin}
              key={index}
            />
            // <span>
            //     {folder.name}
            // </span>
          );
        })}
    </div>
  );
}
