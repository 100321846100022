import { IFAEvent } from 'src/types/user';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const shortMonthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export function monthNumFromName(monthName: string) {
  return shortMonthNames.findIndex((shortMonthName) => {
    return monthName.startsWith(shortMonthName);
  });
}

export function getDateForEvent(event: IFAEvent) {
  if (!event.year || !event.month || !event.date) return null;

  try {
    const year = parseInt(event.year);
    const month = monthNumFromName(event.month);
    const day = parseInt(event.date);
    return new Date(year, month, day);
  } catch {
    console.log('invalid date: ', event);
    return null;
  }
}

export function isEventInCurrentMonthOrFuture(e: IFAEvent) {
  const today = new Date();
  if (!e.year) return false;
  const year = parseInt(e.year);
  if (year > today.getUTCFullYear()) return true;
  if (year < today.getUTCFullYear()) return false;

  if (!e.month) return false;
  const month = monthNumFromName(e.month);
  if (month >= today.getUTCMonth()) return true;
  return false;
}

const dateUtils = {
  monthNames,
  shortMonthNames,
  getDateForEvent,
  monthNumFromName,
  isEventInCurrentMonthOrFuture,
};

export default dateUtils;
