import React from 'react';

import { NavLink } from 'react-router-dom';
import { attendee } from 'src/types/user';

import styles from './EventAttendeesList.module.css';

// type EventAttendeesList = {
//     data: attendee[]
// };

interface EventAttendees {
  eventId: string;
  data: attendee[];
}

export default function EventAttendeesList(props: EventAttendees) {
  const attendeeData = props.data;
  const eventId = props.eventId;

  return (
    <div className={`${styles.membersListContainer}`}>
      <table className={`${styles.tableContainer} ${styles.isDesktop}`}>
        <tbody>
          <tr>
            <td>IFA Number</td>
            <td>Name</td>
            <td>Email</td>
            <td>Member</td>
            <td></td>
          </tr>
          {attendeeData &&
            attendeeData.map((attendee, index) => {
              return (
                <tr className={`${styles.tableRowContainer}`} key={index}>
                  <td>{attendee?.ifaNumber}</td>
                  <td>{attendee?.name}</td>
                  <td>{attendee?.email}</td>
                  <td>
                    {attendee?.isMember && 'Yes'}
                    {!attendee?.isMember && 'No'}
                  </td>
                  <td>
                    <div className={`${styles.actionButtons}`}>
                      <NavLink
                        to={`/admin_tools/attendee/${eventId}/${attendee.id}`}
                      >
                        Details
                      </NavLink>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <ul className={`${styles.isMobile} ${styles.list}`}>
        {attendeeData &&
          attendeeData.map((attendee, index) => {
            return (
              <li key={`member-${index}`} className={styles.listItem}>
                <div className={styles.dataRow}>
                  <span>IFA number:</span>
                  <span>{attendee?.ifaNumber}</span>
                </div>
                <div className={styles.dataRow}>
                  <span>Name:</span>
                  <span>{attendee?.name}</span>
                </div>
                <div className={styles.dataRow}>
                  <span>Email:</span>
                  <span>{attendee?.email}</span>
                </div>
                <div className={styles.dataRow}>
                  <span>Member:</span>
                  <span>
                    {attendee?.isMember && 'Yes'}
                    {!attendee?.isMember && 'No'}
                  </span>
                </div>
                <div className={`${styles.actionButtons}`}>
                  <NavLink
                    to={`/admin_tools/attendee/${eventId}/${attendee.id}`}
                  >
                    Details
                  </NavLink>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
