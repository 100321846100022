import React from 'react';
import { Navigate } from 'react-router-dom';

import { User } from 'src/types/user';

import URLS from 'src/config/urls';
import { useQuery } from 'src/utils/hooks';
import { ResetPasswordPage } from './ResetPasswordPage';
import { VerifyEmailPage } from './VerifyEmailPage';

interface AuthActionsProps {
  user?: User;
}

export default function AuthActions({ user }: AuthActionsProps) {
  const query = useQuery();

  const mode = query.get('mode');
  const actionCode = query.get('oobCode');

  if (mode && actionCode) {
    if (mode === 'verifyEmail' && user) {
      return <VerifyEmailPage user={user} actionCode={actionCode} />;
    }

    if (mode === 'resetPassword') {
      return <ResetPasswordPage actionCode={actionCode} />;
    }

    if (mode === 'recoverEmail' && user?.id) {
      return <RecoverEmailPage uid={user.id} actionCode={actionCode} />;
    }
  }

  return <Navigate to={URLS.root} />;
}

function RecoverEmailPage({
  uid,
  actionCode,
}: {
  uid: string;
  actionCode: string;
}) {
  return <div>TO be created RecoverPasswordPage</div>;
}
