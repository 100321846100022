import React from 'react';

import styles from './ReadMorePage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import aboutPicture from './aboutimage.jpg';

interface ReadMorePageProps {
  isSignedIn: boolean;
}

export default function ReadMorePage({ isSignedIn }: ReadMorePageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <div>
        <img src={aboutPicture} alt="About Page Diagram" />
      </div>
      <section className={`${styles.pageBlockContainer}`}>
        <div className={`${styles.headercontainer}`}>
          <PageHeader pageHeader={'Read More'} />
        </div>
        <div className={`${styles.bodycontainer}`}>
          <PageBody
            pageBody={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque tincidunt id erat ac ornare. Sed orci urna, convallis id justo sit amet, vehicula iaculis ex. Aliquam malesuada volutpat posuere. Interdum et malesuada fames ac ante ipsum primis in faucibus. In venenatis porttitor tortor. Pellentesque efficitur nisi condimentum vulputate cursus. Vivamus egestas eros mi, sed tempor tellus faucibus non. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nulla eget ipsum lorem.'
            }
          />
          <PageBody
            pageBody={
              'Vestibulum porttitor, ligula ut egestas aliquet, ante lectus cursus risus, sit amet condimentum dui ipsum quis leo. Phasellus placerat venenatis imperdiet. Cras placerat, velit vitae porttitor tristique, leo ante dictum lorem, sed fermentum arcu velit mattis est. Vestibulum congue ornare velit in finibus. Mauris interdum est eu lacus imperdiet, at sodales lectus tincidunt. Sed eleifend lacinia rhoncus. In hac habitasse platea dictumst. Nulla posuere eros non nibh scelerisque, nec dictum nunc elementum. Donec suscipit eu orci eget dapibus. Quisque sit amet interdum elit, ultrices vulputate enim. In porta luctus elit quis semper.'
            }
          />
          <PageBody
            pageBody={
              'Quisque sit amet suscipit nulla, a aliquet velit. Integer vulputate aliquet ex, interdum sollicitudin augue imperdiet quis. Praesent ultrices, justo quis gravida eleifend, ligula nulla convallis ante, eu semper purus dolor sed lacus. Suspendisse viverra leo diam, vel ultrices nisi venenatis nec. Aenean non efficitur quam. Maecenas eu ligula sit amet justo venenatis ornare nec ut sapien. Maecenas dapibus libero nec ipsum efficitur porta. Sed maximus sed quam vitae iaculis. Donec id sem volutpat, fermentum urna efficitur, vehicula erat. Phasellus posuere blandit ante sit amet commodo. Suspendisse eu nunc eu ipsum porttitor tincidunt.'
            }
          />
          <PageBody
            pageBody={
              'Vivamus id lectus nec nunc sagittis finibus a nec nisi. Cras congue nulla in semper sagittis. Sed interdum, diam et sollicitudin hendrerit, nisi magna consequat mauris, vel imperdiet mauris risus sit amet lectus. Praesent elementum nulla eu justo gravida, eu semper lorem vehicula. Maecenas euismod eu diam id cursus. Vivamus fringilla quam eget ligula efficitur porttitor. Aenean laoreet, dolor eget tempor imperdiet, magna quam lobortis augue, ut venenatis est eros ut orci. Donec sed tristique mauris, sed laoreet sapien. Nulla sed dui tristique tortor hendrerit tempor. Phasellus egestas semper massa, ac tempor odio luctus eget. Sed turpis massa, egestas placerat dictum sit amet, rutrum nec enim. Aliquam maximus quam est, non pretium nunc bibendum tempus. Fusce interdum diam et nibh imperdiet faucibus.'
            }
          />
        </div>
      </section>
      <div>
        <CallToAction />
      </div>
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
