import React from 'react';

import styles from './EventDetailsBlock.module.css';

interface CongressData {
  congress?: string;
  congressDates?: string;
  congressLocation?: string;
}

export default function EventDetailsBlock({
  congress = '',
  congressDates = '',
  congressLocation = '',

  ...props
}: CongressData) {
  return (
    <div className={`${styles.eventDetailsContainer}`}>
      <h2>Event details</h2>
      <table>
        <tbody>
          <tr>
            <td>
              <span className={`${styles.eventDetailsLabel}`}>Congress</span>
            </td>
            <td>{congress}</td>
          </tr>
          <tr>
            <td>
              <span className={`${styles.eventDetailsLabel}`}>Date</span>
            </td>
            <td>{congressDates}</td>
          </tr>
          <tr>
            <td>
              <span className={`${styles.eventDetailsLabel}`}>Location</span>
            </td>
            <td>{congressLocation}</td>
          </tr>
        </tbody>
      </table>
      <hr></hr>
    </div>
  );
}
