import React from 'react';
import * as icons from '../../components/svg';
import ChevronIcon from '../../components/svg/ChevronIcon';

const SvgComponents = () => {
  const iconList = Object.keys(icons).reduce(
    (acc, curr) => [...acc, { Icon: icons[curr], name: curr }],
    []
  );
  return (
    <div>
      <ul className="afi-sg-demo-list afi-sg-demo-list--horizontal">
        {iconList.map(({ Icon, name }, index) => (
          <li className="afi-sg-demo-list-item" key={index} title={name}>
            <Icon className="afi-sg-icon" />
          </li>
        ))}
        <li>
          <ChevronIcon className="afi-sg-icon" direction="right" />
        </li>
        <li>
          <ChevronIcon className="afi-sg-icon" direction="down" />
        </li>
        <li>
          <ChevronIcon className="afi-sg-icon" direction="left" />
        </li>
        <li>
          <ChevronIcon className="afi-sg-icon" direction="up" />
        </li>
      </ul>
    </div>
  );
};

export default SvgComponents;
