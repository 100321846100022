import React from 'react';

interface Props {
  className?: string;
}

export default function ErrorIcon({ className, ...props }: Props) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.576 16.009"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path fill="none" d="M0 0h18.576v16.009H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path
          d="M2.718 16.009A2.7 2.7 0 01.427 14.7a3.037 3.037 0 01-.094-2.94l6.072-9.937A3.505 3.505 0 019.291 0a3.505 3.505 0 012.885 1.823l6.041 9.885a3.029 3.029 0 01-.073 2.989 2.69 2.69 0 01-2.292 1.312H2.718zm6.573-5.187a.995.995 0 11-1 1 .991.991 0 011-1zm0-.99a.7.7 0 01-.667-.667l-.333-2.988a1.012 1.012 0 011-.99 1 1 0 01.99.99l-.334 2.989a.684.684 0 01-.656.667zm-6.573 4.855h13.135A1.375 1.375 0 0017.019 14a1.727 1.727 0 00.052-1.625l-6-9.812a2.262 2.262 0 00-1.781-1.24A2.279 2.279 0 007.5 2.562l-5.99 9.812A1.67 1.67 0 001.562 14a1.345 1.345 0 001.156.687z"
          fill="#eb0909"
        />
      </g>
    </svg>
  );
}
