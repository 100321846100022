import React from 'react';
import { NavLink } from 'react-router-dom';
import { IFACompany } from 'src/types/user';

import styles from './CompaniesList.module.css';

// interface CompanyData {
//   ifaNumber?: string;
//   dateCreated?: string;
//   note?: string;
//   companyName?: string;
//   membershipType?: string;
//   numberOfMembers?: string;
//   paidUp?: boolean;
// }

type CompaniesListProps = {
  data: IFACompany[];
};

export default function CompaniesList(props: CompaniesListProps) {
  const companyData = props.data;
  return (
    <div className={`${styles.membersListContainer}`}>
      <table className={`${styles.tableContainer} ${styles.isDesktop}`}>
        <tbody>
          <tr>
            <td>IFA Number</td>
            <td>Company Name</td>
            <td>Members</td>
            <td></td>
          </tr>
          {companyData &&
            companyData.map((company, index) => {
              return (
                <tr key={`company-${index}`}>
                  <td>{company.companyDetails?.ifaNumber}</td>
                  <td>{company.companyDetails?.companyName}</td>
                  <td>0</td>
                  <td>
                    <div className={`${styles.actionButtons}`}>
                      <NavLink to={`/admin_tools/company/${company.id}`}>
                        Details
                      </NavLink>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <ul className={`${styles.isMobile} ${styles.list}`}>
        {companyData &&
          companyData.map((company, index) => {
            return (
              <li key={`member-${index}`} className={styles.listItem}>
                <div className={styles.dataRow}>
                  <span>IFA number:</span>
                  <span>{company.companyDetails?.ifaNumber}</span>
                </div>
                <div className={styles.dataRow}>
                  <span>Name:</span>
                  <span>{company.companyDetails?.companyName}</span>
                </div>
                <div className={styles.dataRow}>
                  <span>Members:</span>
                  <span>0</span>
                </div>
                <div className={`${styles.actionButtons}`}>
                  <NavLink to={`/admin_tools/company/${company.id}`}>
                    Details
                  </NavLink>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
