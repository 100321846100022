import { isEmail, isAlphanumeric, isNumeric } from 'validator';

const ERROR_MESSAGES = {
  username: {
    isRequired: 'A username is required',
    isIncorrectFormat:
      'Your username should be one word with letters and numbers only',
    isTooShort: 'Your username must be longer than 3 characters',
  },
};
const isNotEmpty = (value) => value && value.length > 0;

const isLongerThan = (minLength, value) => value.length > minLength;

export const hasErrors = (errors) => Object.keys(errors).some((x) => errors[x]);

const isEmpty = (value) =>
  value === undefined || value.length === 0 || value === '' ? true : false;

export const textHasError = (value) => (isEmpty(value) ? true : false);

export const numberHasError = (value) => (isNumeric(value) ? false : true);

export const isNotNumber = (value) => (isNumeric(String(value)) ? false : true);

const isValidEmail = (value) => {
  if (isEmpty(value)) {
    return 'Email is required';
  }
  if (!isLongerThan(3, value)) {
    return 'Email should be longer than 3 characters';
  }
  if (!isEmail(value)) {
    return 'Email entered is not valid';
  }
  return null;
};

const isValidPassword = (value) => {
  const PASSWORD_RE = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).*$/;
  const meetsRequirements = PASSWORD_RE.test(value);
  if (!isNotEmpty(value)) {
    return 'Password is required';
  }
  if (!isLongerThan(7, value)) {
    return 'Password must be at least 8 characters long';
  }
  if (!meetsRequirements) {
    return 'Password must contain at least 1 lowercase letter, 1 uppercase letter, and 1 number';
  }
  return null;
};

const isValidPasswordConfirmation = (value, passwordToMatch) => {
  if (!isNotEmpty(value)) {
    return 'Enter your confirmed password';
  }

  if (passwordToMatch !== value) {
    return 'Passwords do not match';
  }

  return null;
};

const isValidUsername = (value) => {
  if (isEmpty(value)) {
    return ERROR_MESSAGES.username.isRequired;
  }

  if (!isAlphanumeric(value)) {
    return ERROR_MESSAGES.username.isIncorrectFormat;
  }

  if (!isLongerThan(2, value)) {
    return ERROR_MESSAGES.username.isTooShort;
  }

  return null;
};

const displayErrorFromServer = (error, handleError) => {
  switch (error.code) {
    case 'auth/email-already-in-use':
      handleError('email', 'Email is already in use');
      break;
    case 'auth/invalid-email':
      handleError('email', 'Email address is not valid');
      break;
    case 'auth/weak-password':
      handleError('password', 'Password is not strong enough');
      break;
    case 'auth/operation-not-allowed':
      handleError('email', 'Email/password accounts are not enabled');
      break;
    case 'auth/user-disabled':
      handleError('email', 'This account is disabled');
      break;
    case 'auth/user-not-found':
      handleError('email', 'Email address not found.');
      break;
    case 'auth/wrong-password':
      handleError('password', 'Incorrect Password');
      break;
    case 'auth/too-many-requests':
      handleError(
        'email',
        'We have blocked all requests from this device due to unusual activity. Please try again later.'
      );
      break;
    case 'auth/network-request-failed':
      handleError('email', 'There seems to be a problem with your network');
      break;
    default:
      console.error(error);
      // TODO: Log error to server
      break;
  }
};

export {
  isLongerThan,
  isValidEmail,
  isValidPassword,
  isValidPasswordConfirmation,
  isValidUsername,
  isEmpty,
  isNotEmpty,
  displayErrorFromServer,
};
