import React, { useState } from 'react';

import styles from './EventDetailsBlockTest.module.css';

import PrimaryButton from 'src/components/buttons/PrimaryButton';
import apiResources from 'src/services/apiResources';

interface EventBlockTestData {
  eventTheme?: 'dark' | 'light' | 'grey';
  event?: string;
  eventDate?: string;
  eventMonth?: string;
  eventYear?: string;
  eventTimes?: string;
  eventDuration?: string;
  eventLocation?: string;
  eventBrochure?: string;
}

export default function EventDetailsBlockTest({
  eventTheme = 'dark',
  event = '',
  eventDate = '',
  eventMonth = '',
  eventYear = '',
  eventTimes = '',
  eventDuration = '',
  eventLocation = '',
  eventBrochure = '',
  ...props
}: EventBlockTestData) {
  const componentTheme =
    eventTheme === 'grey'
      ? styles.eventDetailsContainerGrey
      : eventTheme === 'light'
        ? styles.eventDetailsContainerLight
        : styles.eventDetailsContainerDark;
  const [isDownloading, setIsDownloading] = useState(false);
  return (
    <div className={`${componentTheme}`}>
      <h2>Event Details</h2>
      <table>
        <tbody>
          <tr>
            <td>
              <span>Event</span>
            </td>
            <td>{event}</td>
          </tr>
          <tr>
            <td>
              <span>Date</span>
            </td>
            <td>
              {eventDate} {eventMonth} {eventYear}
            </td>
          </tr>
          <tr>
            <td>
              <span>Time</span>
            </td>
            <td>{eventTimes}</td>
          </tr>
          <tr>
            <td>
              <span>Duration</span>
            </td>
            <td>{eventDuration}</td>
          </tr>
          <tr>
            <td>
              <span>Location</span>
            </td>
            <td>{eventLocation}</td>
          </tr>
        </tbody>
      </table>
      <hr></hr>
      <PrimaryButton
        className={`${styles.downloadButtonContainer}`}
        disabled={isDownloading}
        onClick={async () => {
          setIsDownloading(true);
          await apiResources.download(eventBrochure);
          setIsDownloading(false);
        }}
      >
        Download Brochure
      </PrimaryButton>
    </div>
  );
}
