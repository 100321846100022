import React from 'react';
import { createRoot } from 'react-dom/client';
// import ReactGA from 'react-ga';

import App from './App/App';
import { unregister } from './serviceWorker';

// if (process.env.REACT_APP_ENV === 'live') {
//   ReactGA.initialize('UA-117924328-1');
// } else {
//   ReactGA.initialize('UA-117924328-2');
// }

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

unregister();
