import React from 'react';
import { personalDetails } from 'src/types/user';

import styles from './PersonalDetails.module.css';

interface PersonalDetails {
  uid?: string;
  personalDetails?: personalDetails;
}

export default function PersonalDetails({
  uid,
  personalDetails,
  ...props
}: PersonalDetails) {
  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Personal Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>IFA Number</td>
            <td>{personalDetails?.ifaNumber}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Title</td>
            <td>{personalDetails?.title}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Name</td>
            <td>{personalDetails?.name}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Email</td>
            <td>{personalDetails?.email}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Mobile</td>
            <td>{personalDetails?.mobile}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
