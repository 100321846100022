import React, { Component, useState } from 'react';
// import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { isValidEmail } from '../../utils/validation';
import URLS from '../../config/urls';

import EmailInput from '../../components/formControls/EmailInput';
import PublicPage from '../../components/page/PublicPage/PublicPage';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import styles from './ForgotPassword.module.css';
import PrimaryButtonLink from 'src/components/buttonLinks/PrimaryButtonLink';

type Props = {};

type State = {
  email: string;
  emailSent: boolean;
  error: string;
  isSubmitting: boolean;
};

function ForgotPassword() {
  const [state, setState] = useState({
    email: '',
    emailSent: false,
    error: '',
    isSubmitting: false,
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState({
      ...state,
      email: e.target.value,
      error: '',
    });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setState({
      ...state,
      isSubmitting: true,
      error: '',
    });

    const { email } = state;
    const errorMsg = isValidEmail(email);

    if (errorMsg) {
      setState({
        ...state,
        error: errorMsg,
        isSubmitting: false,
      });
      return;
    }

    try {
      const response = await fetch(URLS.api.resetPassword, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email,
        }),
      });
      if (!response.ok) {
        console.error(
          `response ${response.status} | ${response.type} | ${response.statusText}`
        );
        setState({
          ...state,
          error: 'There was a problem, please try again',
          isSubmitting: false,
        });
        throw new Error(response.statusText);
      }
      setState({
        ...state,
        emailSent: true,
        isSubmitting: false,
      });
    } catch (error) {
      setState({
        ...state,
        error: 'There was a problem, please try again',
        isSubmitting: false,
      });
    }
  };

  return (
    <PublicPage
      title="Forgot Password?"
      testId="ForgotPassword"
      headerContent={<NavBar isSignedIn={false} />}
    >
      <div className={styles.formContainer}>
        <div className={styles.afi_ForgotPassword_wrapper}>
          <form className={styles.afi_ForgotPassword_form} onSubmit={onSubmit}>
            <p>
              Enter the email address associated with your account, and we’ll
              email you a link to reset your password.
            </p>

            <EmailInput
              className={styles.afi_ForgotPassword_field}
              errorMessage={state.error}
              disabled={state.isSubmitting || state.emailSent}
              hasError={!!state.error}
              name="email"
              onChange={onChange}
              placeholder="Email"
              value={state.email}
            />

            {state.emailSent && (
              <>
                <p>
                  A link to reset your password has been sent to {state.email}.
                </p>

                <PrimaryButtonLink
                  href={URLS.root}
                  className={styles.backToHomepage_btn}
                >
                  Back to Homepage
                </PrimaryButtonLink>
              </>
            )}

            {!state.emailSent && (
              <PrimaryButton
                className={`${styles.afi_ForgotPassword_btn} ${
                  state.emailSent ? styles.visibilityHidden : ''
                }`}
                data-testid="reset-button"
                disabled={state.isSubmitting}
                type="submit"
              >
                {state.isSubmitting ? 'Sending email...' : 'Send reset link'}
              </PrimaryButton>
            )}
          </form>

          {!state.emailSent && (
            <p className="fine-print">
              <span className={styles.afi_password_remembered}>
                Remember your password?
              </span>
              <Link to={URLS.auth.signIn}>Go back to login</Link>.
            </p>
          )}
        </div>
      </div>
    </PublicPage>
  );
}

// ForgotPassword.propTypes = propTypes;

export default ForgotPassword;
