import React from 'react';
import { NavLink } from 'react-router-dom';

import { IconProps } from '../../types/misc';

import styles from './MenuLink.module.css';

import ExternalLink from '../ui/ExternalLink';
import ChevronIcon from '../svg/ChevronIcon';
import { classnames } from '../../utils/universal_utils';

type MenuLinkPropTypes = {
  children: React.ReactNode;
  href: string | object;
  icon?: (props: IconProps) => JSX.Element;
  type: string;
  className?: string;
  linkStyle?: string;
};

export default function MenuLink({
  children,
  className: classes = '',
  href,
  icon: Icon = undefined,
  type,
  linkStyle = '',
}: MenuLinkPropTypes) {
  const VALID_TYPES = ['external', 'internal'];
  const isInvalidType = !VALID_TYPES.includes(type);
  if (isInvalidType) {
    throw new Error(
      `MenuLinkError: '${type}' is an invalid type. Valid types include 'external' or 'internal'.`
    );
  }

  const className = classnames([styles.root, classes, styles[linkStyle]]);
  const TYPE_MAP: any = {
    external: {
      Component: ExternalLink,
      props: { className, href },
    },
    internal: {
      Component: NavLink,
      props: { className, to: href },
    },
  };

  const { Component, props } = TYPE_MAP[type];

  return (
    <Component {...props}>
      {Icon && (
        <div className={styles.leftButtonIcon}>
          <Icon className={styles.iconPadding} />
        </div>
      )}

      {children}

      <div className={styles.rightButtonIcon}>
        <ChevronIcon direction="right" />
      </div>
    </Component>
  );
}
