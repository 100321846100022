import React from 'react';
import { addressDetails } from 'src/types/user';

import styles from './AddressDetails.module.css';

interface AddressDetails {
  uid?: string;
  addressDetails?: addressDetails;
}

export default function AddressDetails({
  uid,
  addressDetails,
  ...props
}: AddressDetails) {
  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Address Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>Address Type</td>
            <td>{addressDetails?.addressType}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Address Line 1</td>
            <td>{addressDetails?.addressLine1}</td>
          </tr>
          <tr>
            <td>Address Line 2</td>
            <td>{addressDetails?.addressLine2}</td>
          </tr>
          <tr>
            <td>Suburb</td>
            <td>{addressDetails?.suburb}</td>
          </tr>
          <tr>
            <td>City</td>
            <td>{addressDetails?.city}</td>
          </tr>
          <tr>
            <td>Province</td>
            <td>{addressDetails?.province}</td>
          </tr>
          <tr>
            <td>Country</td>
            <td>{addressDetails?.country}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
