import React from 'react';
import Downshift from 'downshift';

import styles from './Dropdown.module.css';

import CircleCheckmarkIcon from '../svg/CircleCheckmarkIcon';
import ChevronIcon from '../svg/ChevronIcon';
import BaseButton from '../buttons/BaseButton';

import sharedStyles from '../../assets/css/Shared.module.css';

interface DropdownProps {
  hasError: boolean;
  options: Array<any>;
  placeholder: string;
  value: string;
  onChange: (selectedItem: any) => void;
  className?: string;
  label?: string | React.ReactNode;
  name?: string;
  optionKeyName?: string;
  optionValueName?: string;
  testId?: string;
  tipIcon?: React.ReactNode;
  hideSelectedIcon?: boolean;
}

export default function Dropdown({
  className = '',
  hasError = false,
  label,
  name,
  onChange,
  optionKeyName = 'value',
  optionValueName = 'name',
  options,
  placeholder,
  value: selectedValue,
  testId = 'dropdown',
  tipIcon,
  hideSelectedIcon = false,
}: DropdownProps) {
  // TODO: refactor !!
  const isSelected = (value: string) =>
    !!selectedValue && selectedValue === value;
  const getSelectedItemText = () => {
    const selectedItem = options.find((option) =>
      isSelected(option[optionKeyName])
    );

    if (selectedItem) {
      return selectedItem.name;
    }

    return '';
  };
  const getItemAsString = (item: any) => {
    if (item && item[optionValueName]) {
      return item[optionValueName];
    } else {
      return '';
    }
  };
  return (
    <Downshift
      onChange={onChange}
      selectedItem={selectedValue}
      itemToString={getItemAsString}
    >
      {({
        getToggleButtonProps,
        getItemProps,
        getLabelProps,
        getMenuProps,
        isOpen,
      }) => {
        const hasErrorClass = hasError ? styles['root--has-error'] : '';
        const classNames = [
          className,
          hasErrorClass,
          styles.root,
          isOpen ? styles['root--is-open'] : '',
        ].join(' ');
        const hasSelectedItem = selectedValue !== '';

        return (
          <div data-testid={testId} className={classNames}>
            <div className={sharedStyles.flexRow}>
              {label && <label {...getLabelProps()}>{label}</label>}
              {tipIcon && <div className={sharedStyles.pl1}>{tipIcon}</div>}
            </div>

            <BaseButton className={styles.button} {...getToggleButtonProps()}>
              {hasSelectedItem && !hideSelectedIcon ? (
                <CircleCheckmarkIcon className={styles.selectedIcon} />
              ) : null}

              <span>
                {hasSelectedItem ? getSelectedItemText() : placeholder}
              </span>

              <span
                className={hideSelectedIcon ? styles.iconMoreLeft : styles.icon}
              >
                <ChevronIcon direction={isOpen ? 'up' : 'down'} />
              </span>
            </BaseButton>

            <div className={styles['list-wrapper']}>
              <div className={styles['list-shadow']}>
                {isOpen ? (
                  <ul
                    className={`word ${styles.list}`}
                    {...getMenuProps({ name })}
                  >
                    {options.map((item, index) => (
                      <li
                        className={`${styles['list-item']} ${
                          isSelected(item[optionKeyName])
                            ? styles['list-item-selected']
                            : ''
                        }`}
                        {...getItemProps({
                          key: item[optionKeyName],
                          index,
                          item,
                        })}
                        key={item[optionKeyName]}
                      >
                        {isSelected(item[optionKeyName]) ? (
                          <CircleCheckmarkIcon />
                        ) : null}

                        <span className={styles['list-item-name']}>
                          {item[optionValueName]}
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : null}
              </div>

              <span className={styles['list-arrow']}></span>
            </div>
          </div>
        );
      }}
    </Downshift>
  );
}
