import React from 'react';
import { NavLink } from 'react-router-dom';
import urls from 'src/config/urls';

import styles from './CallToAction.module.css';

export default function CallToAction() {
  return (
    <div className={styles.callToActionContainer}>
      <div className={styles.callToActionPanel}>
        <div className={styles.callToActionText}>
          <h3>
            <p>
              <span>
                Join IFA and be part of the world's independent fiscal network!
              </span>
            </p>
          </h3>
        </div>
        <NavLink
          to={urls.auth.signUpRequestPage}
          className={styles.callToActionLink}
        >
          <div className={styles.callToActionButton}>
            <div className={styles.callToActionButtonText}>Become a member</div>
            <div className={styles.callToActionButtonIcon}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="10"
                viewBox="0 0 27 10"
              >
                <polygon
                  fill="#031D8A"
                  fillRule="evenodd"
                  points="128.95 13.017 132.234 16.282 108 16.282 108 17.718 132.234 17.718 128.95 20.983 129.973 22 135 17 129.973 12"
                  transform="translate(-108 -12)"
                ></polygon>
              </svg>
            </div>
          </div>
        </NavLink>
      </div>
    </div>
  );
}
