import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import api from '../../services/api';
import URLS from '../../config/urls';

import GeneralPageLayout from '../../components/layout/GeneralPageLayout';

import PrimaryButton from '../../components/buttons/PrimaryButton';
import SecondaryButtonLink from '../../components/buttonLinks/SecondaryButtonLink';

import { IFAIcon } from 'src/components/svg';

import sharedStyles from '../../assets/css/Shared.module.css';

interface LocationStateObject {
  state?: {
    forceSignOut?: boolean;
  };
}

const SignOut = () => {
  const location: LocationStateObject = useLocation();
  let CONFIRMED = false;
  if (location?.state?.forceSignOut) {
    CONFIRMED = true;
  }

  const [confirmedSignout, setConfirmedSignout] = useState(CONFIRMED);

  if (confirmedSignout) {
    api.signOut();
    return <Navigate to={URLS.root} />;
  }

  return (
    <GeneralPageLayout
      title="Are You Sure You Want to Logout"
      headerIcon={IFAIcon}
      theme="danger"
    >
      <div className={sharedStyles.inner}>
        <div className={sharedStyles.btnContainer}>
          <PrimaryButton
            type="button"
            theme="plan"
            onClick={() => setConfirmedSignout(true)}
          >
            Yes, logout
          </PrimaryButton>
          <SecondaryButtonLink
            theme="plan"
            href={{
              pathname: URLS.root,
              state: {
                isMenuOpen: true,
              },
            }}
          >
            Whoops, go back!
          </SecondaryButtonLink>
        </div>
      </div>
    </GeneralPageLayout>
  );
};

export default SignOut;
