import React from 'react';
import { IconProps } from '../../types/misc';

export default function CircleCheckmarkIcon({
  className = '',
  ...props
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18.588 18.588"
      className={className}
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path fill="none" d="M0 0h18.588v18.588H0z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)">
        <path d="M9.294 0a9.294 9.294 0 109.294 9.294A9.294 9.294 0 009.294 0zm4.771 6.758l-5.707 5.707a.666.666 0 01-.942 0L4.828 9.877a.666.666 0 01.942-.942l2.117 2.117 5.238-5.238a.666.666 0 11.942.942z" />
      </g>
    </svg>
  );
}
