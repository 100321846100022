import React from 'react';
import { useParams } from 'react-router-dom';

import styles from './IfaSaEventPageOne.module.css';

import { attendee, IFAEvent } from 'src/types/user';
import useFetchDoc from 'src/services/useFetchDoc';
import useFetchAttendees from 'src/services/useFetchAttendees';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import EventTitle from 'src/components/IFAcomponents/EventTitle/EventTitle';
import EventInfo from 'src/components/IFAcomponents/EventInfo/EventInfo';
import EventSpeaker from 'src/components/IFAcomponents/EventSpeakerOne/EventSpeakerOne';
import EventDetailsBlockTest from 'src/components/IFAcomponents/EventDetailsBlockTest/EventDetailsBlockTest';
import EventPayments from 'src/components/IFAcomponents/EventPayments/EventPayments';
import EventMaterials from 'src/components/IFAcomponents/Event Materials/EventMaterials';
import EventAttendeesBlock from 'src/components/IFAcomponents/EventAttendeesBlock/EventAttendeesBlock';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import Event20240425 from '../events/components/Event20240425';

interface IfaSaEventPageOneProps {
  pageTheme?: 'dark' | 'light';
  isSignedIn: boolean;
  isAdmin: boolean;
}

export default function IfaSaEventPageOne({
  pageTheme = 'light',
  isSignedIn,
  isAdmin,
}: IfaSaEventPageOneProps) {
  const { id } = useParams<{ id: string }>();

  const {
    value: event,
    loading,
    error,
  } = useFetchDoc<IFAEvent>('events', id ? id : '');

  const { values: attendeeData } = useFetchAttendees<attendee>(id ? id : '');

  //console.log(event, loading, error);

  const titleTheme =
    pageTheme === 'light'
      ? styles.eventTitleContainerLight
      : styles.eventTitleContainerDark;
  const pageBlockTheme =
    pageTheme === 'light'
      ? styles.pageBlockContainerLight
      : styles.pageBlockContainerDark;
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${titleTheme}`}>
        <EventTitle eventTheme={'light'} eventTitle={event?.name} />
        <EventInfo eventTheme={'light'} eventInfo={event?.bio} />
      </section>
      <section className={`${pageBlockTheme}`}>
        <div className={`${styles.eventSpeakersContainer}`}>
          {event?.speakers &&
            event?.speakers.map((speaker, index) => {
              return (
                <EventSpeaker
                  key={`speaker-${index}`}
                  eventTheme={'grey'}
                  speaker={speaker}
                />
              );
            })}
        </div>
        <Event20240425 id={id} />
        <div>
          <EventDetailsBlockTest
            eventTheme={'dark'}
            event={event?.type}
            eventDate={event?.date}
            eventMonth={event?.month}
            eventYear={event?.year}
            eventTimes={event?.time}
            eventDuration={event?.duration}
            eventLocation={event?.location}
            eventBrochure={event?.brochure}
          />
          <EventPayments
            eventTheme={'dark'}
            eventMemberPrice={'Free'}
            // eventNonMemberPrice={event?.bookingsAndPayments?.nonMember}
            eventId={id}
            bookingInfo={event?.bookingsAndPayments}
          />
          {isAdmin && !event?.bookingsAndPayments?.registrationUrl && (
            <EventAttendeesBlock
              eventTheme={'dark'}
              eventAttendeesTotal={attendeeData.length}
              eventId={id}
            />
          )}
        </div>
      </section>
      {isSignedIn && !event?.downloads?.hide && event?.downloads && (
        <section className={`${pageBlockTheme}`}>
          <EventMaterials eventTheme={'dark'} downloads={event.downloads} />
        </section>
      )}
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
