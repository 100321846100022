import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import urls from 'src/config/urls';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import ExportButton from 'src/components/IFAcomponents/ExportButton/ExportButton';
import CompaniesList from 'src/components/IFAcomponents/CompaniesList/CompaniesList';
import useFetchCollection from 'src/services/useFetchCollection';
import { IFACompany } from 'src/types/user';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import apiCompany from 'src/services/apiCompany';
import styles from './CompaniesListPage.module.css';

interface CompaniesListPageProps {
  isSignedIn: boolean;
}

export default function CompaniesListPage({
  isSignedIn,
}: CompaniesListPageProps) {
  const [searchString, setSearchString] = useState('');
  const navigate = useNavigate();

  const {
    values: companyData,
    loading,
    error,
  } = useFetchCollection<IFACompany>('companies');

  if (!loading) {
    //console.log('data', companyData);
    const data = companyData.filter(
      (company) =>
        searchString === '' ||
        company.companyDetails?.companyName
          ?.toLocaleLowerCase()
          .includes(searchString.toLowerCase())
    );
    //console.log('filtered', data);
  }

  function handleSearch(e: { target: { value: string } }) {
    const searchValue = e.target.value;
    setSearchString(searchValue);
  }

  async function newCompanyClick() {
    const company = await apiCompany.createNewCompany();
    if (company?.id) {
      navigate(`${urls.admin_tools.company}/${company.id}`);
    }
  }

  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={styles.backContainer}>
        <section className={styles.backContainer}>
          <NavLink to={urls.admin_tools.members}>Members</NavLink>
          <span>&nbsp;|&nbsp;</span>
          <NavLink to={urls.admin_tools.companies}>Companies</NavLink>
        </section>
      </section>
      <section className={`${styles.pageBlockContainer}`}>
        <div>
          <PageHeader pageHeader={'Companies List'} />
        </div>
        <div className={`${styles.exportButtonsContainer}`}>
          <div className={`${styles.excelButton}`}>
            <ExportButton buttonName={'Export To Excel'} />
          </div>
          <div className={`${styles.xlsxButton}`}>
            <ExportButton buttonName={'Export As XLSX'} />
          </div>
          <div className={`${styles.searchInputContainer}`}>
            <label>Search</label>
            <input
              className={`${styles.searchInput}`}
              type="text"
              name="search"
              value={searchString}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className={`${styles.createNewContainer}`}>
          <span>Number of Companies: {companyData.length}</span>
          <PrimaryButton onClick={newCompanyClick}>Create New</PrimaryButton>
        </div>
        {loading && <p>Loading</p>}
        {error && (
          <p>
            {error.name}: {error.message}
          </p>
        )}
      </section>
      <section className={`${styles.pageBlockContainer}`}>
        <CompaniesList
          data={companyData.filter(
            (company) =>
              !searchString ||
              company.companyDetails?.companyName
                ?.toLocaleLowerCase()
                .includes(searchString.toLowerCase())
          )}
        />
      </section>
    </>
  );
}
