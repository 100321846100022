import React from 'react';

import styles from './EventDocument.module.css';

import { FirebaseFile } from 'src/services/apiResources';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import apiResources from 'src/services/apiResources';

import DocumentPicture from './Document.png';

interface EventFileData {
  eventFile: FirebaseFile;
  eventTheme?: 'dark' | 'light' | 'grey';
}

export default function EventDocument({
  eventFile,
  eventTheme = 'dark',
  ...props
}: EventFileData) {
  const componentTheme =
    eventTheme === 'grey'
      ? styles.eventDocumentContainerGrey
      : eventTheme === 'light'
        ? styles.eventDocumentContainerLight
        : styles.eventDocumentContainerDark;

  return (
    <div className={`${componentTheme}`}>
      <span>
        <img
          src={DocumentPicture}
          className={styles.imageContainer}
          alt="Document Icon"
        />
      </span>
      <p>{eventFile?.name}</p>

      <div className={`${styles.downloadContainer}`}>
        <PrimaryButton
          className={`${styles.downloadButtonContainer}`}
          onClick={() => {
            apiResources.download(eventFile?.fullPath);
          }}
        >
          Download
        </PrimaryButton>
      </div>
    </div>
  );
}
