import React from 'react';

import styles from './BranchExecutivesPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageSubheader from 'src/components/IFAcomponents/PageSubheader/PageSubheader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import BranchExecutiveTable from 'src/components/IFAcomponents/BranchExecutiveTable/BranchExecutiveTable';

interface BranchExecutivesPageProps {
  isSignedIn: boolean;
}

export default function BranchExecutivesPage({
  isSignedIn,
}: BranchExecutivesPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <PageHeader pageHeader={'Branch Executives'} />
        <PageBody
          pageBody={
            'The main task of the Branch Executive is the management of the South African Association. It is the body responsible for developing the strategy and ensuring the management of the Association.'
          }
        />
        <PageSubheader pageSubheader={'Composition of the Branch Executive'} />
        <PageBody
          pageBody={
            'The Branch Executive consists of the President, the Secretary General, the General Treasurer, and seven Executive Board Members.'
          }
        />
        <BranchExecutiveTable />
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
