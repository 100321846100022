import React from 'react';

import styles from './AfterSignUpRequest.module.css';

import { NavLink } from 'react-router-dom';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import SignUpImage from 'src/assets/img/signup.svg';

interface AfterSignUpRequestProps {
  isSignedIn: boolean;
}

export default function AfterSignUpRequest({
  isSignedIn,
}: AfterSignUpRequestProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={styles.bodyContainer}>
        <div className={styles.imageContainer}>
          <img src={SignUpImage} alt="Sign Up Diagram" />
        </div>
        <PageHeader pageHeader={'Sign Up Request Received'} />
        <p className={styles.text}>
          Thank you for requesting to join the International Fiscal Association
          of South Africa
        </p>
        <p> Please check your emails for further details</p>
        <NavLink className={`${styles.actionButton}`} to={`/root`}>
          Back To Home
        </NavLink>
      </section>
    </>
  );
}
