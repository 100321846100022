import React from 'react';

import styles from './HistoryOfIFAPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import PageBullet from 'src/components/IFAcomponents/PageBullet/PageBullet';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import HistoryOfIFAPicture from './HistoryOfIFAImage.jpeg';

interface HistoryOfIFAPageProps {
  isSignedIn: boolean;
}

const bullData = [
  {
    pageBullet:
      'Publishing the annual cahiers in print and digital, with general reports on international subjects and the reports by countries related thereto. As well as other publications on specific subjects;',
  },
  {
    pageBullet:
      'Organising annual congresses since 1939, regional conferences and seminars. Creating a prominent platform for bringing down the thoughts within IFA members and for making them known to the world, stimulating the discussion and bringing nuances to these.',
  },
  {
    pageBullet:
      'Providing incentives on scientific work such as the Maurice Lauré Prize, the Mitchel B. Carroll Prize and the YIN Scientific Award.',
  },
];

export default function HistoryOfIFAPage({
  isSignedIn,
}: HistoryOfIFAPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <div>
        <img src={HistoryOfIFAPicture} alt="History Of IFA Page Diagram" />
      </div>
      <section className={`${styles.pageBlockContainer}`}>
        {/* <div className={`${styles.headercontainer}`}> */}
        <PageHeader pageHeader={'History of IFA'} />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'The International Fiscal Association (IFA) was established in the winter of 1938 in The Hague, Netherlands, at the end of the interbellum. Though there were indications of a new war, the need had arisen for an independent organisation with certain objects. Along with the increasing internationalisation of the worlds economies, international tax issues had become more numerous and of greater importance.'
          }
        />
        <PageBody
          pageBody={
            'With the need for more studies of comparative fiscal law and the need to cope with these new challenges, a platform was created by a group of people involved, with the aim to encourage the research on international tax issues by:'
          }
        />
        <PageBullet data={bullData} />
        <PageBody
          pageBody={
            'Since the end of the second World War IFA has played an essential role both in the development of certain principles of international taxation and in providing possible solutions to problems arising in their practical implementation.'
          }
        />
        <PageBody
          pageBody={
            'The achievements of IFA are attributable to the amount of high level representatives from both the private and the public sectors, including the Courts, Universities and international governmental and non-governmental organisations, who are actively involved and contribute worldwide with their knowledge and efforts to the research, publications, congresses, seminars, etc. The Association has maintained high standards in its debates and conclusions over the years. In this way, IFA has offered the necessary independent and neutral platform to experts belonging to different sectors of society, where opinions on topics of international taxation can be exchanged with respect for each others background and responsibilities.'
          }
        />
        <PageBody
          pageBody={
            'IFA has grown to a truly global association with around 13,000 members from 116 countries. In 70 countries IFA members have established IFA Branches. For members in countries where an IFA Branch has not been yet established, IFA facilitates direct membership.'
          }
        />
        {/* </div> */}
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <Footer isSignedIn={isSignedIn} />
    </>
  );
}
