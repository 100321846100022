import React from 'react';
import { IconProps } from '../../types/misc';

export default function CircleInfoIcon({
  className = '',
  ...props
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        d="M3.515 20.485a12 12 0 1116.97-16.97 12 12 0 11-16.97 16.97zM2.768 12A9.232 9.232 0 1012 2.768 9.242 9.242 0 002.768 12zm6.7 6.09V16.8l.892-.122c.256-.024.384-.186.384-.49v-4.68q0-.454-.384-.488l-.892-.106V9.619h3.371v6.566c0 .3.128.466.384.49l.89.122v1.293zm1.069-10.366a1.079 1.079 0 01-.331-.908 1.374 1.374 0 01.3-.977 1.5 1.5 0 011.109-.324 1.339 1.339 0 011.057.314 1.581 1.581 0 01.253 1q0 1.152-1.38 1.152a1.661 1.661 0 01-1.008-.257z"
        fill="currentColor"
      />
    </svg>
  );
}
