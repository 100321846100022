import React from 'react';

import styles from './SeminarMaterialsPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import SeminarFolder from 'src/components/IFAcomponents/SeminarFolder/SeminarFolder';
import apiResources from 'src/services/apiResources';
import urls from 'src/config/urls';
import { Navigate } from 'react-router-dom';
import { auth } from 'src/services/apiAuth';

interface SeminarMaterialsPageProps {
  isAdmin: boolean;
}

export default function SeminarMaterialsPage({
  isAdmin,
}: SeminarMaterialsPageProps) {
  const isSignedIn = auth?.currentUser !== null;
  if (!isSignedIn) return <Navigate to={urls.root} />;

  const { folder: seminarsFolder } = apiResources.useSeminars();

  // var seminarMaterials: FirebaseFolder = {
  //     fullPath: 'newsletters',
  //     name: 'newsletters',
  //     files: [{ fullPath: 'newsletters/Root File.pdf', name: 'Root File 1.pdf', size: 0 }, { fullPath: 'newsletters/Root File.pdf', name: 'Root File 2.pdf', size: 0 }, { fullPath: 'newsletters/Root File.pdf', name: 'Root File 3.pdf', size: 0 }],
  //     folders: [{
  //         fullPath: 'newsletters/Newsletter Level 1',
  //         name: 'Newsletter Level 1',
  //         files: [{ fullPath: 'newsletters/Newsletter Level 1/Level1 File.pdf', name: 'Level1 File.pdf', size: 0 }],
  //         folders: [{
  //             fullPath: 'newsletters/Newsletter Level 2',
  //             name: 'Newsletter Level 2',
  //             files: [{ fullPath: 'newsletters/Newsletter Level 2/Level2 File.pdf', name: 'Level2 File.pdf', size: 0 }],
  //             folders: [{
  //                 fullPath: 'newsletters/Newsletter Level 3',
  //                 name: 'Newsletter Level 3',
  //                 files: [{ fullPath: 'newsletters/Newsletter Level 3/Level3 File.pdf', name: 'Level3 File.pdf', size: 0 }],
  //                 folders: []
  //             }]
  //         }]
  //     }]
  // }

  return (
    <>
      <header>
        <NavBar />
      </header>
      {/* {seminarMaterials &&
                seminarMaterials.files.map((seminar) => {
                    return (
                        <SeminarFile seminarFile={seminar} />
                    )
                })} */}
      <section className={`${styles.pageBlockContainer}`}>
        <div>
          <PageHeader pageHeader={'Seminar Materials'} />
        </div>
        <SeminarFolder
          seminarFolder={seminarsFolder}
          indent={0}
          isAdmin={isAdmin}
        />
      </section>
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
