import React, { useCallback, useEffect, useRef, useState } from 'react';

import styles from './ContactUs.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';

import URLS from 'src/config/urls';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import PublicPage from 'src/components/page/PublicPage/PublicPage';
import MessageBar from 'src/components/IFAcomponents/MessageBar/MessageBar';
import PrimaryButtonLink from 'src/components/buttonLinks/PrimaryButtonLink';
import useReCaptcha from 'src/services/useRecaptcha';
import ErrorText from 'src/components/form/ErrorText';
import { isValidEmail } from 'src/utils/validation';
import { useNavigate } from 'react-router-dom';
import urls from 'src/config/urls';
import { recaptchaSiteKey } from 'src/config/recaptcha';
import { verifyRecaptcha } from 'src/services/apiRecaptcha';

interface ContactUsProps {
  isSignedIn: boolean;
}

export default function ContactUs({ isSignedIn }: ContactUsProps) {
  const navigate = useNavigate();
  // setting Up Input States
  const [errorMsg, setErrorMsg] = useState<string>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [messageError, setMessageError] = useState(false);

  function handleNameChange(e: { target: { value: string } }) {
    const nameValue: string = e.target.value;
    setName(nameValue);
  }

  function handleEmailChange(e: { target: { value: string } }) {
    const emailValue: string = e.target.value;
    setEmail(emailValue);
  }

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(e.target.value);

  // Recaptcha Functions
  const { reCaptchaLoaded, generateReCaptchaToken } =
    useReCaptcha(recaptchaSiteKey);

  const hasErrors = () => {
    let errors = 0;

    const emailErrorMessage = isValidEmail(email);
    setEmailErrorMessage(emailErrorMessage ? emailErrorMessage : '');

    if (emailErrorMessage) {
      setEmailError(true);
      errors++;
    }

    if (name.length === 0) {
      setNameError(true);
      errors++;
    }
    if (message.length === 0) {
      setMessageError(true);
      errors++;
    }

    return errors > 0 ? true : false;
  };

  // Submitting Sign Up Request Form
  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (hasErrors()) {
      return;
    }

    const recaptchaToken = await generateReCaptchaToken('submit');

    if (reCaptchaLoaded && recaptchaToken) {
      const { success } = await verifyRecaptcha(recaptchaToken);
      if (success) {
        setIsSubmitting(true);
        setNameError(false);
        setEmailError(false);
        setMessageError(false);
        setErrorMsg('');

        try {
          // Sending Contact Us Alert to Admin
          await fetch(URLS.api.sendContactMeAlert, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              email: email,
              displayName: name,
              message: message,
            }),
          }).then(() => {
            navigate(urls.getInTouch.afterContactUs);
          });
          setIsEmailSent(true);
        } catch (error) {
          console.error(error);
          setErrorMsg('There was a problem, please try again');
          setIsEmailSent(false);
        } finally {
          setIsSubmitting(false);
        }
      }
    } else {
      alert('Please verify that you are a human');
    }
  }

  return (
    <PublicPage
      testId="contact-us"
      title="Contact Us"
      headerContent={<NavBar isSignedIn={isSignedIn} />}
    >
      <section>
        <form onSubmit={onSubmit} className={styles.form}>
          <div className={styles.signUpRequestForm}>
            <h3>Contact Us</h3>
            <div className={styles.formContent}>
              <div className={styles.formSectionOne}>
                <div className={styles.formComponent}>
                  <label>Full Name</label>
                  <input
                    className={styles.searchInput}
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleNameChange}
                    disabled={isSubmitting}
                  />
                  <ErrorText isVisible={nameError}>Name is required</ErrorText>
                </div>

                <div className={styles.formComponent}>
                  <label>Email</label>
                  <input
                    className={styles.searchInput}
                    type="text"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    disabled={isSubmitting}
                  />
                  <ErrorText isVisible={emailError}>
                    {emailErrorMessage}
                  </ErrorText>
                </div>

                {errorMsg && (
                  <div className={styles.formComponent}>
                    <MessageBar
                      messageBarType="error"
                      messageText={errorMsg}
                      className={styles.messageBarError}
                    />
                  </div>
                )}
              </div>
              <div className={styles.formSectionTwo}>
                <div className={styles.formComponent}>
                  <label>Your Message</label>
                  <textarea
                    className={styles.searchInputTwo}
                    name="message"
                    value={message}
                    onChange={handleMessageChange}
                    disabled={isSubmitting}
                  />
                  <ErrorText isVisible={messageError}>
                    Message is required
                  </ErrorText>
                </div>

                {!isEmailSent && (
                  <>
                    {/* <RecaptchaV3
                    siteKey="6Lfx0jUlAAAAADiUdA4H74MedjN6YtmCcv0slkuG"
                    setToken={setToken}
                  /> */}
                    <PrimaryButton
                      className={styles.btnSubmit}
                      data-testid="sign-up-request-button"
                      disabled={isSubmitting || !reCaptchaLoaded}
                      type="submit"
                    >
                      Submit
                    </PrimaryButton>
                  </>
                )}

                {isEmailSent && (
                  <>
                    <MessageBar
                      messageBarType="success"
                      messageText="Your interest has been submitted. We will be in touch with you."
                      className={styles.messageBarSuccess}
                    />

                    <PrimaryButtonLink
                      href={URLS.root}
                      className={styles.backToHomepage_btn}
                    >
                      Back to Homepage
                    </PrimaryButtonLink>
                  </>
                )}
              </div>
            </div>
          </div>
        </form>
      </section>
    </PublicPage>
  );
}
