import React from 'react';

import styles from './YINCommiteeTable.module.css';

export default function YINCommiteeTable() {
  return (
    <div className={`${styles.tablePanel}`}>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td>
              <p>
                <b>Branch Chairman</b>
              </p>
            </td>
            <td>
              <p>Jerome Brink</p>
            </td>
            <td>
              <p>Johannesburg</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>Branch Secretary</b>
              </p>
            </td>
            <td>
              <p>Puleng Mothabeng</p>
            </td>
            <td>
              <p>Johannesburg</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>Membership</b>
              </p>
            </td>
            <td>
              <p>Bobby Wessels</p>
            </td>
            <td>
              <p>Cape Town</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>Social Media</b>
              </p>
            </td>
            <td>
              <p>Ernst Jordaan</p>
            </td>
            <td>
              <p>Cape Town</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>
                <b>Events</b>
              </p>
            </td>
            <td>
              <p>Nicholas Carroll</p>
            </td>
            <td>
              <p>Cape Town</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
