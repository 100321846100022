import { doc, onSnapshot } from 'firebase/firestore';
import React from 'react';
import { database } from 'src/config/firebase';

interface DocumentResult<T> {
  error: Error | null;
  loading: boolean;
  value: T | null;
}

interface FirebaseDocument {
  id?: string;
}

export const useFetchDoc = <T extends FirebaseDocument>(
  collectionName: string,
  id: string
): DocumentResult<T> => {
  const [data, setData] = React.useState<DocumentResult<T>>({
    error: null,
    loading: true,
    value: null,
  });

  React.useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(database, collectionName, id),
      (doc) => {
        setData({
          error: null,
          loading: false,
          value: doc.data() as T,
        });
      },
      (error: Error) => {
        setData({
          error,
          loading: false,
          value: null,
        });
      }
    );
    return unsubscribe;
  }, [collectionName, id]);

  return data;
};

export default useFetchDoc;
