import React from 'react';
import { IconProps } from '../../types/misc';

export default function IFAIcon({ className = '', ...props }: IconProps) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 57.8 48"
      className={className}
      {...props}
      // style={{ enableBackground: 'new 0 0 57.8 48;' }}
      xmlSpace="preserve"
    >
      <g transform="translate(0 .178)">
        <path
          fill="#190387"
          d="M36.2,16.2l2,7.6h-3.9L36.2,16.2z M47.3,8.7c-4.7-4.5-9-5.9-11.3-6c-0.3,0-3.1,0-5.5,1.9s-2.9,4.5-3.3,5.8
                c-0.2,0.7-2,7.4-3.6,13.5h-4.7v0.2h4.6c-1.3,5.1-2.5,9.6-2.5,9.6s-1,4.8-1.7,6.3c-0.8,1.5-1.8,3.2-4.6,3.2s-5.1-2.9-5.8-3.8
                c-0.7-0.8-2.7-4.1-3.8-8.2S3.4,21.4,5,17.1C6.4,12.9,9.1,8,12.5,5.7s7.5-5,13.9-5s11.4,1.9,11.4,1.9s-4.3-2.3-11.3-2.3
                S11.8,2.8,6.2,9C0.5,15.3,0,20.4,0,24.1s1.7,9.5,5.2,13.3c3.5,3.8,7.3,6.6,11,6.6s7.3-1.9,8.5-6.4c0.5-2,2-7.8,3.5-13.6h5.7
                l-1.6,6.4c-0.9,2.7-1.2,2.3-2.7,2.4v0.1H34v-0.2c-0.9,0-1.8-0.2-1.7-1.1c0.1-1,1.6-6.8,1.9-7.8h4l1.3,5.5c0.5,1.9,0.8,3.2-1.3,3.3
                v0.1h8.3v-0.1c-1.4,0.1-2-0.6-2.5-1.7l-4.3-16.8h-6.2v0.2c0,0,0.7,0,1.4,0.3s1,0.9,1,0.9l-2.1,8h-5.7c1.8-7.1,3.6-14.2,3.6-14.2
                s1-6.7,5.7-6.1c4.9,0.6,8.5,7,8.9,7.7c0.4,0.7,3.8,5.7,3.5,12.7s-2,10.5-4.8,14.2s-9.2,9-17.5,9s-13.3-3-13.3-3s3.6,3,13.1,3.3
                c10.3,0.3,17.5-5.9,20.7-9.2c3.1-3.2,5.8-9.4,5.8-14.7C54,18.2,52,13.2,47.3,8.7z"
        />
      </g>
      <path
        fill="#190387"
        d="M51.3,45.1h0.2c0.2,0,0.4-0.1,0.4-0.2c0-0.2-0.1-0.3-0.4-0.3c-0.1,0-0.2,0-0.2,0V45.1z"
      />
      <path
        fill="#190387"
        d="M51.3,46h-0.4v-1.6c0.2,0,0.4-0.1,0.6-0.1c0.3,0,0.5,0.1,0.6,0.1c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.3,0.4l0,0
            c0.2,0.1,0.3,0.2,0.3,0.4c0,0.3,0.1,0.4,0.1,0.4H52c0-0.1-0.1-0.2-0.1-0.4s-0.1-0.3-0.3-0.3h-0.2V46H51.3z"
      />
      <path
        fill="#190387"
        d="M50.3,45.1c0,0.7,0.5,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3s-0.5-1.3-1.3-1.3S50.3,44.4,50.3,45.1z"
      />
      <path
        fill="#190387"
        d="M53.3,45.1c0,0.9-0.7,1.7-1.7,1.7c-0.9,0-1.7-0.7-1.7-1.7c0-0.9,0.8-1.6,1.7-1.6S53.3,44.2,53.3,45.1z"
      />
      <path
        fill="#190387"
        d="M17.4,31c0,1.7,0.8,2.1,2.4,2.1v0.1H11v-0.1c1.6,0,2.4-0.4,2.4-2.1V17c0-1.6-0.8-2.1-2.4-2.1v-0.2h8.8v0.2
            c-1.6,0-2.4,0.6-2.4,2.1V31z"
      />
    </svg>
  );
}
