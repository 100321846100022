import React from 'react';

import styles from './AboutPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import aboutPicture from './aboutimage.jpg';
import AboutBlockElement from 'src/components/IFAcomponents/AboutBlockElement/AboutBlockElement';
import { auth } from 'src/config/firebase';

interface Props {
  isSignedIn?: boolean;
}

export default function AboutPage(props: Props) {
  const isSignedIn = auth.currentUser !== null;
  return (
    <>
      <header>
        <NavBar />
      </header>
      <div>
        <img src={aboutPicture} alt="About Page Diagram" />
      </div>
      <section className={`${styles.pageBlockContainer}`}>
        {/* <div className={`${styles.headercontainer}`}> */}
        <PageHeader pageHeader={'About IFA'} />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'The International Fiscal Association is the only non-governmental and non-sectoral international organization dealing with fiscal matters. IFA is a neutral, independent, non-lobby organisation and as such the only global network of its kind. As a non-profit organisation, IFA provides a neutral and independent platform where representatives of all professions and interests can meet and discuss international tax issues at the highest level.'
          }
        />
        <PageBody
          pageBody={
            'Its objects are the study and advancement of international and comparative law in regard to public finance, specifically international and comparative fiscal law and the financial and economic aspects of taxation.'
          }
        />
        <PageBody
          pageBody={
            'IFA seeks to achieve these objects through its Annual Congresses and the scientific publications relating thereto as well as through scientific research.'
          }
        />
        <PageBody
          pageBody={
            'Although the operations of IFA are essentially scientific in character, the subjects selected take account of current fiscal developments and changes in local legislation.'
          }
        />
        {/* </div> */}
      </section>
      <section className={`${styles.aboutcontainer}`}>
        <div className={`${styles.aboutrow}`}>
          <AboutBlockElement aboutTitle={'History of IFA'} />
          <AboutBlockElement aboutTitle={'Executive Committee'} />
          <AboutBlockElement aboutTitle={'Permanent Scientific Committee'} />
          <AboutBlockElement aboutTitle={'General Council'} />
        </div>
        <div className={`${styles.aboutrow}`}>
          <AboutBlockElement aboutTitle={'Honorary Members'} />
          <AboutBlockElement aboutTitle={'Genreral Secretariat'} />
          <AboutBlockElement aboutTitle={'Cooperation'} />
          <AboutBlockElement aboutTitle={'IFA Merchandise'} />
        </div>
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <Footer isSignedIn={isSignedIn} />
    </>
  );
}
