import React from 'react';

import styles from 'src/components/buttons/TertiaryButton.module.css';
import { ButtonTheme } from 'src/types/misc';

import { classnames } from 'src/utils/universal_utils';
import BaseButtonLink, { BaseButtonLinkProps } from './BaseButtonLink';
import ChevronIcon from '../svg/ChevronIcon';

interface Props extends BaseButtonLinkProps {
  displayIcon?: boolean;
  theme?: ButtonTheme | 'default';
}

function TertiaryButtonLink({
  className,
  children,
  href,
  theme = 'default',
  displayIcon = false,
  ...props
}: Props) {
  return (
    <BaseButtonLink
      className={classnames([styles[theme], styles.button, className])}
      href={href}
      {...props}
    >
      {children}

      {displayIcon ? (
        <span className={styles.buttonIcon}>
          <ChevronIcon direction="right" />
        </span>
      ) : null}
    </BaseButtonLink>
  );
}

export default TertiaryButtonLink;
