import React from 'react';

import Page from '../../components/page/Page';

import styles from './StyleGuide.module.css';
import './Styleguide.css';

// Sections
import Colors from './Colors';
import SvgComponents from './SvgComponents';
import FormComponents from './FormComponents';
import ContentComponents from './ContentComponents';
import Buttons from './Buttons';
import { IFAIcon } from '../../components/svg';

export default function Styleguide() {
  return (
    <Page>
      <header className={styles.header}>
        <IFAIcon className={styles.logo} />
        <h1 className={styles.mainTitle}>Afirefi Styleguide</h1>
      </header>

      <div className={styles.content}>
        <details open>
          <summary>Typography</summary>
          <div>
            <h1 className="page-title">Page Title</h1>
            <h2 className="sub-title">Sub Title</h2>
            <p className="large-text">Secondary Text</p>
            <p>Default Text</p>
            <p className="small-text">Small Text</p>
            <p className="fine-print">Fine Print</p>
            <br />
            <h1>Level 1 Header</h1>
            <h2>Level 2 Header</h2>
            <h3>Level 3 Header</h3>
            <h4>Level 4 Header</h4>
            <h5>Level 5 Header</h5>
            <h6>Level 6 Header</h6>

            <details>
              <summary>Typography Variables</summary>
              <code>
                <pre>
                  --default-font-family: 'Nunito Sans', sans-serif;
                  <br />
                  --font-family-cursive: 'Shadows Into Light Two', cursive;
                  <br />
                  --font-size-title: 28px;
                  <br />
                  --font-size-impact: 24px;
                  <br />
                  --font-size-sub-title: 20px;
                  <br />
                  --font-size-large: 18px;
                  <br />
                  --font-size-small: 14px;
                  <br />
                  --font-size-fine-print: 12px;
                  <br />
                  --font-weight-light: 300;
                  <br />
                  --font-weight-regular: 400;
                  <br />
                  --font-weight-semi-bold: 600;
                  <br />
                  --font-weight-bold: 700;
                  <br />
                </pre>
              </code>
            </details>
          </div>
        </details>

        <details open>
          <summary>Colors</summary>

          <Colors />
        </details>

        <details open>
          <summary>SVG Icons</summary>

          <SvgComponents />
        </details>

        <details className={styles.buttonsGroup} open>
          <summary>Buttons</summary>

          <Buttons />
        </details>

        <details open>
          <summary>Content Components</summary>

          <ContentComponents />
        </details>

        <details open>
          <summary>Form Components</summary>

          <FormComponents />
        </details>

        <details open>
          <summary>Page Components</summary>
          <div></div>
        </details>

        <details open>
          <summary>Page Layout Templates</summary>
          <div></div>
        </details>
      </div>
    </Page>
  );
}
