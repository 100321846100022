import React from 'react';
import { FirebaseFile } from 'src/services/apiResources';
import apiResources from 'src/services/apiResources';

import styles from './NewsArticle.module.css';
import { ArrowToRight } from 'src/components/svg';

interface NewsletterFileData {
  newsletterFile: FirebaseFile;
}

export default function NewsArticle({
  newsletterFile,
  ...props
}: NewsletterFileData) {
  return (
    <article className={styles.newsarticlecontainer}>
      <img
        src="https://www.ifa.nl/images/placeholder.jpg?anchor=center&mode=crop&width=500&height=300"
        alt="IFA Logo"
      ></img>

      <h3>Newsletter</h3>
      {/* <p>
          This Newsletters contains information on the word of the president, the launch of the IFA App and many more
        </p> */}
      <p>{newsletterFile.name}</p>
      <div className={styles.btnprimary}>
        <button
          className={styles.btnprimarydesign}
          onClick={() => {
            apiResources.download(newsletterFile.fullPath);
          }}
        >
          Download
          <span className={`${styles.btnprimaryicon}`}>
            <ArrowToRight />
          </span>
        </button>
      </div>
    </article>
  );
}
