import React, { useState } from 'react';
import { membershipTypeDetails } from 'src/types/user';
import CompanySelector from 'src/components/IFAcomponents/CompanySelector/CompanySelector';
import api from 'src/services/api';

import styles from './MembershipTypeForm.module.css';
import apiCompany from 'src/services/apiCompany';

interface MembershipTypeDetails {
  uid: string;
  membershipTypeDetails?: membershipTypeDetails;
}

export default function MembershipTypeForm({
  uid,
  membershipTypeDetails,
  ...props
}: MembershipTypeDetails) {
  // Company Selector State and Function

  const [companySelectorView, setCompanySelectorView] = useState(false);

  const handleCompanySelectorView = () =>
    setCompanySelectorView(!companySelectorView);

  const [searchString, setSearchString] = useState('');

  function handleSearch(e: { target: { value: string } }) {
    const searchValue = e.target.value;
    setSearchString(searchValue);
  }

  // Membership Type
  function selectMembershipType(e: React.ChangeEvent<HTMLSelectElement>) {
    const membershipTypeValue = e.target.value as
      | 'Individual'
      | 'Corporate'
      | 'YIN';
    if (uid && membershipTypeDetails?.membershipType !== membershipTypeValue) {
      api.updateUser(uid, {
        membershipTypeDetails: {
          ...membershipTypeDetails,
          membershipType: membershipTypeValue,
        },
      });
    }
  }
  // BillingParty
  function selectBillingParty(e: React.ChangeEvent<HTMLSelectElement>) {
    const billingPartyValue = e.target.value as 'Individual' | 'Organisation';
    if (uid && membershipTypeDetails?.billingParty !== billingPartyValue) {
      api.updateUser(uid, {
        membershipTypeDetails: {
          ...membershipTypeDetails,
          billingParty: billingPartyValue,
        },
      });
    }
  }
  // Company
  function handleCompanyChange(e: { target: { value: string } }) {
    const companyValue: string = e.target.value;
    if (uid && membershipTypeDetails?.company !== companyValue) {
      api.updateUser(uid, {
        membershipTypeDetails: {
          ...membershipTypeDetails,
          company: companyValue,
        },
      });
    }
  }

  // dob
  function handleDOBChange(e: { target: { value: string } }) {
    const dobValue: string = e.target.value;
    if (uid && membershipTypeDetails?.dob !== dobValue) {
      api.updateUser(uid, {
        membershipTypeDetails: {
          ...membershipTypeDetails,
          dob: dobValue,
        },
      });
    }
  }

  // New Company SUbmission

  async function addNewCompnany(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    try {
      // Creating New Company Object
      const newCompany = apiCompany.getNewCompanyData();

      // Assigning The Name To The New Company Object

      newCompany.companyDetails.companyName = searchString;

      /* eslint-disable  @typescript-eslint/no-unused-vars */
      const newCompanySubmission =
        await apiCompany.createNewUserCompany(newCompany);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Membership Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>Membership Type</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <select
                  onChange={selectMembershipType}
                  className={`${styles.addressTypeInputContainer}`}
                >
                  <option selected disabled>
                    Please select your membership type
                  </option>
                  <option value="Individual">Individual</option>
                  <option value="Corporate">Corporate</option>
                  <option value="YIN">YIN</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Billing Party</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <select
                  onChange={selectBillingParty}
                  className={`${styles.addressTypeInputContainer}`}
                >
                  <option selected disabled>
                    Please select your billing party
                  </option>
                  <option value="Individual">Individual</option>
                  <option value="Organisation">Organisation</option>
                </select>
              </div>
            </td>
          </tr>
          {membershipTypeDetails?.membershipType === 'YIN' && (
            <tr>
              <td className={`${styles.tableContainerName}`}>Date Of Birth</td>
              <td>
                <div className={`${styles.searchInputContainer}`}>
                  <input
                    className={`${styles.searchInput}`}
                    type="text"
                    name="company"
                    value={membershipTypeDetails?.dob}
                    onChange={handleDOBChange}
                  />
                </div>
              </td>
            </tr>
          )}
          <tr>
            <td className={`${styles.tableContainerName}`}>Company</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="company"
                  value={membershipTypeDetails?.company}
                  onChange={handleCompanyChange}
                />
                <div>
                  <button
                    className={`${styles.selectCompany}`}
                    onClick={handleCompanySelectorView}
                  >
                    Select
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      {companySelectorView && <h2>Company Search</h2>}
      {companySelectorView && (
        <div className={`${styles.searchInputContainer}`}>
          <label>Search</label>
          <form onSubmit={addNewCompnany}>
            <input
              className={`${styles.searchInput}`}
              type="text"
              name="search"
              value={searchString}
              onChange={handleSearch}
            />
            <button className={styles.buttonContainer}>Add New</button>
          </form>
        </div>
      )}
      {companySelectorView && (
        <CompanySelector
          uid={uid}
          membershipTypeDetails={membershipTypeDetails}
          filterString={searchString}
        />
      )}
    </div>
  );
}
