import React from 'react';

import styles from './PrimaryButton.module.css';
import { ButtonTheme } from '../../types/misc';

import BaseButton from './BaseButton';
import { classnames } from '../../utils/universal_utils';

interface Props {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean;
  theme?: ButtonTheme;
  hideIcon?: boolean;
}

export default function PrimaryButton({
  children,
  className = '',
  type = 'button',
  disabled = false,
  theme = 'primary',
  hideIcon = false,
  ...props
}: Props) {
  const classNames = classnames([styles[theme], styles.button, className]);

  return (
    <BaseButton
      className={classNames}
      type={type}
      disabled={disabled}
      {...props}
    >
      {children}
    </BaseButton>
  );
}
