import { useState, useCallback, Ref } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * useStateObject()
 * @param {object} initialState
 */

interface State {
  [key: string]: any;
}
export function useStateObject(initialState: State) {
  const [state, _setState] = useState<State>({ ...initialState });
  const setState = useCallback(
    (newState: any) => {
      _setState((prevState) => ({ ...prevState, ...newState }));
    },
    [_setState]
  );
  return [state, setState] as [any, (params: State) => void];
}

/**
 * useCombinedRefs()
 * Combines many refs into one. Useful for combining many ref hooks
 * https://github.com/facebook/react/issues/13029#issuecomment-497641073
 *
 * @param  {...any} refs any number of ref arguments
 */
export const useCombinedRefs = <T extends any>(
  ...refs: Array<Ref<T>>
): Ref<T> =>
  useCallback(
    (element: T) =>
      refs.forEach((ref) => {
        if (!ref) {
          return;
        }

        // Ref can have two types - a function or an object. We treat each case.
        if (typeof ref === 'function') {
          return ref(element);
        }

        // As per https://github.com/facebook/react/issues/13029
        // it should be fine to set current this way.
        (ref as any).current = element;
      }),
    refs
  );

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}
