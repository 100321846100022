import React from 'react';
import { companyAddressDetails } from 'src/types/user';
import apiCompany from 'src/services/apiCompany';

import styles from './CompanyAddressDetailsForm.module.css';

interface CompanyAddressDetails {
  uid?: string;
  companyAddressDetails?: companyAddressDetails;
}

export default function CompanyAddressDetailsForm({
  uid,
  companyAddressDetails,
  ...props
}: CompanyAddressDetails) {
  /////////////////////////////////////////////Functions To Change Strings///////////////////////////////////////
  // Address Type
  function selectAddressType(e: React.ChangeEvent<HTMLSelectElement>) {
    const addressTypeValue: string = e.target.value;
    if (uid && companyAddressDetails?.addressType !== addressTypeValue) {
      apiCompany.updateCompany(uid, {
        companyAddressDetails: {
          ...companyAddressDetails,
          addressType: addressTypeValue,
        },
      });
    }
  }

  // Address Line 1
  function handleAddressLine1Change(e: { target: { value: string } }) {
    const value: string = e.target.value;
    if (uid && companyAddressDetails?.addressLine1 !== value) {
      apiCompany.updateCompany(uid, {
        companyAddressDetails: {
          ...companyAddressDetails,
          addressLine1: value,
        },
      });
    }
  }

  // Address Line 2
  function handleAddressLine2Change(e: { target: { value: string } }) {
    const value: string = e.target.value;
    if (uid && companyAddressDetails?.addressLine2 !== value) {
      apiCompany.updateCompany(uid, {
        companyAddressDetails: {
          ...companyAddressDetails,
          addressLine2: value,
        },
      });
    }
  }

  // Suburb
  function handleSuburbChange(e: { target: { value: string } }) {
    const value: string = e.target.value;
    if (uid && companyAddressDetails?.suburb !== value) {
      apiCompany.updateCompany(uid, {
        companyAddressDetails: { ...companyAddressDetails, suburb: value },
      });
    }
  }

  // City
  function handleCityChange(e: { target: { value: string } }) {
    const value: string = e.target.value;
    if (uid && companyAddressDetails?.city !== value) {
      apiCompany.updateCompany(uid, {
        companyAddressDetails: { ...companyAddressDetails, city: value },
      });
    }
  }

  // Province
  function handleProvinceChange(e: { target: { value: string } }) {
    const value: string = e.target.value;
    if (uid && companyAddressDetails?.province !== value) {
      apiCompany.updateCompany(uid, {
        companyAddressDetails: { ...companyAddressDetails, province: value },
      });
    }
  }

  // Country
  function handleCountryChange(e: { target: { value: string } }) {
    const value: string = e.target.value;
    if (uid && companyAddressDetails?.country !== value) {
      apiCompany.updateCompany(uid, {
        companyAddressDetails: { ...companyAddressDetails, country: value },
      });
    }
  }

  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Address Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>Address Type</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <select
                  onChange={selectAddressType}
                  className={`${styles.addressTypeInputContainer}`}
                >
                  <option selected disabled>
                    Please select your address type
                  </option>
                  <option value="Postal">Postal</option>
                  <option value="Physical">Physical</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Address Line 1</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="addressLine1"
                  value={companyAddressDetails?.addressLine1}
                  onChange={handleAddressLine1Change}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Address Line 2</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="addressLine2"
                  value={companyAddressDetails?.addressLine2}
                  onChange={handleAddressLine2Change}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Suburb</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="suburb"
                  value={companyAddressDetails?.suburb}
                  onChange={handleSuburbChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>City</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="city"
                  value={companyAddressDetails?.city}
                  onChange={handleCityChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Province</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="province"
                  value={companyAddressDetails?.province}
                  onChange={handleProvinceChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Country</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="country"
                  value={companyAddressDetails?.country}
                  onChange={handleCountryChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
