import React from 'react';

import styles from './YINPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import PageBullet from 'src/components/IFAcomponents/PageBullet/PageBullet';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';

import peopleOne from './people-1.jpg';
import peopleTwo from './people-2.jpg';
import peopleThree from './people-3.jpg';
import peopleFour from './people-4.jpg';
import peopleFive from './people-5.jpg';
import urls from 'src/config/urls';
import { NavLink } from 'react-router-dom';
import SecondaryButtonLink from 'src/components/buttonLinks/SecondaryButtonLink';

interface YINPageProps {
  isSignedIn: boolean;
}

const bullData = [
  {
    pageBullet: 'facilitate communication among younger members;',
  },
  {
    pageBullet:
      'initiate and support social activities and programmes aimed primarily (but not exclusively) at younger members;',
  },
  {
    pageBullet:
      'identify opportunities for (and possible barriers to) increased participation of young members in IFA;',
  },
  {
    pageBullet:
      'provide a platform for young tax professionals to build valuable networks with fellow tax professionals; and',
  },
  {
    pageBullet:
      'provide local and global discussion forums for young tax professionals throughout the world.',
  },
];

export default function YINPage({ isSignedIn }: YINPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        {/* <div className={`${styles.headercontainer}`}> */}
        <PageHeader pageHeader={'Young IFA Network'} />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'The Young IFA Network (YIN) is a member group within the International Fiscal Association (IFA) of which IFA South Africa represents the South African branch.'
          }
        />

        <PageBody
          pageBody={
            'To attract more members in the early stages of their careers, IFA in 2005 adopted the Young IFA Network (YIN) initiative, aimed at attracting an increasing amount of younger tax professionals to its ranks, which members are committed to developing and proposing ideas and implement programmes, both at the Branch level and worldwide, to make IFA more attractive to young members.'
          }
        />

        <div className={styles.threeColumn}>
          <img src={peopleOne} alt="People" />
          <img src={peopleTwo} alt="People" />
          <img src={peopleThree} alt="People" />
        </div>

        <PageBody pageBody={'The YIN Committee will strive to:'} />
        <PageBullet data={bullData} />
        <PageBody
          pageBody={
            'The YIN grouping for the South African branch targets members under the age of 40 years.  The aim of the YIN grouping is to attract new young members to the branch and provide a forum for them to interact both on a technical as well as a social level. '
          }
        />

        <div className={styles.twoColumn}>
          <img src={peopleFour} alt="People" />
          <img src={peopleFive} alt="People" />
        </div>
        <PageBody
          pageBody={
            'While YIN South Africa members are full members of IFA South Africa, additional events will be organised for this grouping specifically.'
          }
        />
        <PageBody
          pageBody={
            'YIN South Africa was established in April 2015 and is currently led by Jerome Brink.'
          }
        />
        <div className={styles.buttons}>
          <SecondaryButtonLink href={urls.about_ifa.yinCommittee}>
            YIN Committee
          </SecondaryButtonLink>

          <SecondaryButtonLink href={urls.getInTouch.yinContactUs}>
            YIN Contact Us
          </SecondaryButtonLink>
        </div>
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
