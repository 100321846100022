import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import GeneralRoutes from './GeneralRoutes';
import './App.css';

import api from '../services/api';
import 'video-react/dist/video-react.css';

import { ScrollToTop } from './ScrollToTop';
import smoothscroll from 'smoothscroll-polyfill';
import firebase, { database } from 'src/config/firebase';
import { IFAUser, User } from 'src/types/user';
import ContextProvider from 'src/services/contextProvider';
import { InitialState } from 'src/services/context';
import { doc, onSnapshot } from 'firebase/firestore';
import {
  getAuth,
  onAuthStateChanged,
  User as FirebaseUser,
} from 'firebase/auth';
import Loading from 'src/pages/loading/Loading';
smoothscroll.polyfill();

const noop = () => {};

const unloadedUser = {
  uid: null,
  username: '',
  email: null,
};

function App(): JSX.Element {
  const [isLoading, setIsLoading] = useState(true);
  const [isSignedIn, setIsSignedIn] = useState(false);

  const [currentUser, setCurrentUser] = useState<any>(unloadedUser);
  const [authUser, setAuthUser] = useState<any>({});
  const [authData, setAuthData] = useState<any>({});
  const [member, setMember] = useState<any>({});

  useEffect(() => {
    const auth = getAuth(firebase);
    return onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        //console.log('1) Logged in');
        setAuthData({
          email: authUser.email,
          uid: authUser.uid,
          username: authUser.displayName || '',
          emailVerified: authUser.emailVerified,
          accountLastSignInTime: authUser.metadata.lastSignInTime,
          accountCreatedTime: authUser.metadata.creationTime,
          photoURL: authUser.photoURL || '',
        });
        setAuthUser(authUser);
        setIsSignedIn(true);

        InitialState.isSignedIn = authUser !== null;
        InitialState.currentUserId = authUser.uid;
        InitialState.currentUserEmail = authUser.email || undefined;
        InitialState.currentUserDisplayName = authUser.displayName || undefined;
      } else {
        //console.log('Logged out');

        setIsLoading(false);
        setIsSignedIn(false);
        setAuthData({});
        setAuthUser({});
        setCurrentUser(unloadedUser);
        setMember({});

        InitialState.isSignedIn = false;
        InitialState.currentUserId = undefined;
        InitialState.currentUserEmail = undefined;
        InitialState.currentUserDisplayName = undefined;
        InitialState.isAdmin = undefined;
        InitialState.initialised = false;
      }
    });
  }, []);

  useEffect(() => {
    if (!authData.uid) return noop;
    return api.user(authData.uid).getInRealtime.userData1((data: any) => {
      setIsLoading(false);
      setCurrentUser(data);
      setMember(data as IFAUser);

      const isAdmin = data?.role === 'tech-admin';
      InitialState.isAdmin = isAdmin;
      InitialState.initialised = true;
    });
  }, [authData.uid]);

  const user = {
    ...unloadedUser,
    ...authData,
    ...currentUser,
  };

  if (isLoading) {
    return <Loading title="Loading ..." />;
  } else {
    return (
      <React.StrictMode>
        <ContextProvider>
          <BrowserRouter>
            <ScrollToTop />
            <GeneralRoutes
              isSignedIn={isSignedIn}
              isLoading={isLoading}
              authUser={authUser}
              member={member}
              user={user}
              setCurrentUser={setCurrentUser}
            />
          </BrowserRouter>
        </ContextProvider>
      </React.StrictMode>
    );
  }
}

export default App;
