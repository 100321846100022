import React from 'react';

import styles from './Colors.module.css';
import List from '../../components/ui/List';
import PropTypes from 'prop-types';

const PLAN_SECTION = [
  { name: 'Plan', value: 'var(--plan-color)', variable: '--plan-color' },
  { name: 'Dream Blue', value: '#0265de', variable: '--dream-blue' },
  {
    name: 'Plan Hover',
    value: 'var(--plan-color-hover)',
    variable: '--plan-color-hover',
  },
];

const MAINTAIN_SECTION = [
  {
    name: 'Maintain',
    value: 'var(--maintain-color)',
    variable: '--maintain-color',
  },
  { name: 'River Blue', value: '#01689f', variable: '--river-blue' },
  {
    name: 'Maintain Hover',
    value: 'var(--maintain-color-hover)',
    variable: '--maintain-color-hover',
  },
];

const MANAGE_SECTION = [
  {
    name: 'Manage',
    value: 'var(--manage-color)',
    variable: '--manage-color',
  },
  { name: 'Bright Teal', value: '#02d0de', variable: '--bright-teal' },
  {
    name: 'Manage Hover',
    value: 'var(--manage-color-hover)',
    variable: '--manage-color-hover',
  },
];

const MOTIVATE_SECTION = [
  {
    name: 'Motivate',
    value: 'var(--motivate-color)',
    variable: '--motivate-color',
  },
  {
    name: 'Mustard Yellow',
    value: 'var(--mustard-yellow)',
    variable: '--mustard-yellow',
  },
  {
    name: 'Motivate Hover',
    value: 'var(--motivate-color-hover)',
    variable: '--motivate-color-hover',
  },
];

const EXPENDITURE_SECTION = [
  {
    name: 'Expenditure',
    value: 'var(--night-blue)',
    variable: '--expenditure-color',
  },
  { name: 'Night Blue', value: '#222c5e', variable: '--night-blue' },
  {
    name: 'Expenditure Hover',
    value: 'var(--expenditure-color-hover)',
    variable: '--expenditure-color-hover',
  },
];

const GRAY_COLORS = [
  { name: 'Light Gray Soft', variable: '--gray-light-soft', value: '#f4f3f2' },
  { name: 'Light Gray Hard', variable: '--gray-light-hard', value: '#cdcdd3' },
  {
    name: 'Medium Gray Soft',
    variable: '--gray-medium-soft',
    value: '#8f8e94',
  },
  {
    name: 'Medium Gray Hard',
    variable: '--gray-medium-hard',
    value: '#818181',
  },
  { name: 'Dark Gray Soft', variable: '--gray-dark-soft', value: '#697a86' },
  { name: 'Dark Gray Hard', variable: '--gray-dark-hard', value: '#4e4c59' },
];

const GENERAL_COLORS = [
  { name: 'Jordy Blue', variable: '--jordy-blue', value: '#80b2ee' },
  { name: 'Blue', value: '#0057c1', variable: '--blue' },
  {
    name: 'Medium Electric Blue',
    variable: '--medium-electric-blue',
    value: '#00479d',
  },
  { name: 'Teal', value: '#20a4af', variable: '--teal' },
  { name: 'Afirefi Red', value: '#cc2a3c', variable: '--afirefi-red' },
  { name: 'Peach', value: '#f56c6c', variable: '--peach' },
  { name: 'Amethyst', variable: '--amethyst', value: '#995dd1' },
  { name: 'Sea Buckthorn', variable: '--sea-buckthorn', value: '#fda527' },
  { name: 'White', value: '#fff', variable: '--white' },
  { name: 'Black', value: '#000', variable: '--black' },
];

const OTHER_COLORS = [
  { name: 'Property', variable: '--property-color', value: 'var(--amethyst)' },
  {
    name: 'Household',
    variable: '--household-color',
    value: ' var(--amethyst)',
  },
  { name: 'Investment', variable: '--investment-color', value: '#da4ab5' },
  { name: 'Art', variable: '--art-color', value: '#fc5a69' },
  {
    name: 'Jewelry',
    variable: '--jewelry-color',
    value: 'var(--sea-buckthorn)',
  },
  {
    name: 'Personal',
    variable: '--personal-color',
    value: ' var(--sea-buckthorn)',
  },
  { name: 'Cash', variable: '--cash-color', value: 'var(--dream-blue)' },
  {
    name: 'Dwelling',
    variable: '--dwelling-color',
    value: ' var(--river-blue)',
  },
  { name: 'Other', variable: '--other-color', value: ' #697a86' },
  { name: 'Business', variable: '--business-color', value: ' var(--teal)' },
  {
    name: 'Retirement',
    variable: '--retirement-color',
    value: 'var(--bright-teal)',
  },
];

const LIFE_ELEMENT_COLORS = [
  { name: 'Create', variable: '--create-color', value: ' var(--night-blue)' },
  { name: 'Sleep', variable: '--sleep-color', value: ' var(--river-blue)' },
  { name: 'Play', variable: '--play-color', value: ' var(--teal)' },
  { name: 'Ritual', variable: '--ritual-color', value: ' #a3ca61' },
  { name: 'Explore', variable: '--explore-color', value: ' #dc70c1' },
  { name: 'Essential', variable: '--essential-color', value: ' #fd6265' },
  { name: 'Lead', variable: '--lead-color', value: ' var(--sea-buckthorn)' },
];

export default function Colors() {
  return (
    <div>
      <section>
        <h2>Section Colors</h2>

        <div className={styles.sectionColumns}>
          <List className={`${styles.sectionColors}`}>
            {PLAN_SECTION.map((color) => {
              return (
                <li key={color.variable}>
                  <ColorSwatch minWidth="220px" color={color} />
                </li>
              );
            })}
          </List>

          <List className={`${styles.sectionColors}`}>
            {MOTIVATE_SECTION.map((color) => {
              return (
                <li key={color.variable}>
                  <ColorSwatch minWidth="220px" color={color} />
                </li>
              );
            })}
          </List>

          <List className={`${styles.sectionColors}`}>
            {MAINTAIN_SECTION.map((color) => {
              return (
                <li key={color.variable}>
                  <ColorSwatch minWidth="220px" color={color} />
                </li>
              );
            })}
          </List>

          <List className={`${styles.sectionColors}`}>
            {MANAGE_SECTION.map((color) => {
              return (
                <li key={color.variable}>
                  <ColorSwatch minWidth="220px" color={color} />
                </li>
              );
            })}
          </List>

          <List className={`${styles.sectionColors}`}>
            {EXPENDITURE_SECTION.map((color) => {
              return (
                <li key={color.variable}>
                  <ColorSwatch minWidth="220px" color={color} />
                </li>
              );
            })}
          </List>
        </div>
      </section>

      <section>
        <h2>General</h2>

        <List className={styles.colorList}>
          {GENERAL_COLORS.map((color) => {
            return (
              <li key={color.variable}>
                <ColorSwatch color={color} />
              </li>
            );
          })}
        </List>
      </section>

      <section>
        <h2>Grays</h2>

        <List className={styles.colorList}>
          {GRAY_COLORS.map((color) => {
            return (
              <li key={color.variable}>
                <ColorSwatch minWidth="250px" color={color} />
              </li>
            );
          })}
        </List>
      </section>

      <section>
        <h2>Life Element colors</h2>

        <List className={styles.colorList}>
          {LIFE_ELEMENT_COLORS.map((color) => {
            return (
              <li key={color.variable}>
                <ColorSwatch color={color} />
              </li>
            );
          })}
        </List>
      </section>

      <section>
        <h2>Other colors</h2>

        <List className={styles.colorList}>
          {OTHER_COLORS.map((color) => {
            return (
              <li key={color.variable}>
                <ColorSwatch color={color} />
              </li>
            );
          })}
        </List>
      </section>
    </div>
  );
}

function ColorSwatch({ color, minWidth }) {
  return (
    <div className={styles.colorBlock} style={{ minWidth: minWidth }}>
      <div
        className={styles.colorSwatch}
        style={{ backgroundColor: `${color.value}` }}
      />

      <div className={styles.colorDetails}>
        <div className={styles.colorName}>
          <h4>{color.name}</h4>
          <small>{color.value}</small>
        </div>
      </div>
    </div>
  );
}

ColorSwatch.propTypes = {
  color: PropTypes.any,
  minWidth: PropTypes.any,
};
