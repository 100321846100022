import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import urls from 'src/config/urls';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PersonalDetails from 'src/components/IFAcomponents/PersonalDetails/PersonalDetails';
import PersonalDetailsForm from 'src/components/IFAcomponents/PersonalDetailsForm/PersonalDetailsForm';
import AddressDetails from 'src/components/IFAcomponents/AddressDetails/AddressDetails';
import AddressDetailsForm from 'src/components/IFAcomponents/AddressDetailsForm/AddressDetailsForm';
import TaxStatus from 'src/components/IFAcomponents/TaxStatus/TaxStatus';
import MembershipType from 'src/components/IFAcomponents/MembershipType/MembershipType';
import MembershipTypeForm from 'src/components/IFAcomponents/MembershipTypeForm/MembershipTypeForm';
import TaxStatusForm from 'src/components/IFAcomponents/TaxStatusForm/TaxStatusForm';
import api from 'src/services/api';
import Loading from '../loading/Loading';
import { IFAUser } from 'src/types/user';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './MemberDetailsPage.module.css';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import MessageBar from 'src/components/IFAcomponents/MessageBar/MessageBar';

interface MemberDetailsPageProps {
  isSignedIn: boolean;
}

export default function MemberDetailsPage({
  isSignedIn,
}: MemberDetailsPageProps) {
  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<IFAUser>();
  const [edit, setEdit] = useState(false);
  const navigate = useNavigate();

  const [isSendingInvite, setIsSendingInvite] = useState<boolean>(false);
  const [inviteSent, setInviteSent] = useState<boolean>(false);
  const [inviteError, setInviteError] = useState<string>();

  const handleEdit = () => setEdit(!edit);

  const handleSendInvite = async () => {
    const email = user?.personalDetails?.email;
    const fullName = user?.personalDetails?.name ?? '';
    if (!email) {
      setInviteError('Please enter email address first');
      return;
    }

    if (id) {
      try {
        setInviteSent(false);
        setIsSendingInvite(true);
        await api.adminSendInviteEmail(id, email, fullName);
        setInviteSent(true);
        window.setTimeout(() => {
          setIsSendingInvite(false);
        }, 2000);
      } catch {
        setIsSendingInvite(false);
        setInviteError('There was a problem sending this invite');
      }
    }
  };

  React.useEffect(() => {
    if (id) {
      const unsubscribe = api
        .user(id)
        .getInRealtime.userData1((data: IFAUser) => {
          setUser(data);
        });

      return unsubscribe;
    }
  }, [id]);

  if (!user) return <Loading title="Loading ..." />;
  else
    return (
      <>
        <header>
          <NavBar isSignedIn={isSignedIn} />
        </header>
        <section className={styles.backContainer}>
          <NavLink to={urls.admin_tools.members}>Back to Members</NavLink>
        </section>
        <section className={styles.pageBlockContainer}>
          <div className={styles.memberNameContainer}>
            <PageHeader pageHeader={user.personalDetails?.name} />
          </div>
          <div className={styles.buttonsContainer}>
            {!edit && (
              <>
                <button className={styles.buttonContainer} onClick={handleEdit}>
                  Edit
                </button>
                <button
                  className={styles.buttonContainer}
                  onClick={() => navigate(-1)}
                >
                  Back
                </button>
              </>
            )}
            {edit && (
              <button className={styles.buttonContainer} onClick={handleEdit}>
                Done
              </button>
            )}
          </div>

          <div className={styles.detailsContainer}>
            {!edit && (
              <PersonalDetails
                uid={id}
                personalDetails={user.personalDetails}
              />
            )}
            {edit && (
              <PersonalDetailsForm
                uid={id}
                personalDetails={user.personalDetails}
              />
            )}
            {!edit && (
              <div className={styles.memberDetailContainer}>
                <MembershipType
                  uid={id}
                  membershipTypeDetails={user.membershipTypeDetails}
                />
                <div className={styles.adminButtons}>
                  <PrimaryButton
                    disabled={isSendingInvite}
                    onClick={handleSendInvite}
                  >
                    Send Sign-In Email Link
                  </PrimaryButton>
                  {inviteError && (
                    <MessageBar
                      messageBarType="error"
                      messageText={inviteError}
                      className={styles.emailSentNotification}
                    />
                  )}
                  {inviteSent && (
                    <MessageBar
                      messageBarType="success"
                      messageText="Sent!"
                      className={styles.emailSentNotification}
                    />
                  )}
                </div>
              </div>
            )}
            {edit && (
              <MembershipTypeForm
                uid={id ? id : ''}
                membershipTypeDetails={user.membershipTypeDetails}
              />
            )}
            {!edit && (
              <AddressDetails uid={id} addressDetails={user.addressDetails} />
            )}
            {edit && (
              <AddressDetailsForm
                uid={id}
                addressDetails={user.addressDetails}
              />
            )}
            {!edit && (
              <TaxStatus uid={id} taxStatusDetails={user.taxStatusDetails} />
            )}
            {edit && (
              <TaxStatusForm
                uid={id}
                taxStatusDetails={user.taxStatusDetails}
              />
            )}
          </div>
        </section>
      </>
    );
}
