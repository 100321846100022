import React from 'react';

import styles from './CompanySelector.module.css';

import { membershipTypeDetails } from 'src/types/user';

import CompanySelectorList from '../CompanySelectorList/CompanySelectorList';

import { IFACompany } from 'src/types/user';
import useFetchCollection from 'src/services/useFetchCollection';

interface CompanySelectorProps {
  filterString: string;
  uid: string;
  membershipTypeDetails?: membershipTypeDetails;
}

export default function CompanySelector({
  filterString,
  uid,
  membershipTypeDetails,
}: CompanySelectorProps) {
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const {
    values: companyData,
    loading,
    error,
  } = useFetchCollection<IFACompany>('companies');

  const filteredCompanyData = companyData.filter(
    (company) =>
      filterString === '' ||
      company.companyDetails?.companyName
        ?.toLocaleLowerCase()
        .includes(filterString.toLowerCase())
  );

  return (
    <div className={`${styles.companySelectorContainer}`}>
      <CompanySelectorList
        uid={uid}
        membershipTypeDetails={membershipTypeDetails}
        data={filteredCompanyData}
      />
    </div>
  );
}
