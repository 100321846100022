import React from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowToRight } from 'src/components/svg';

import styles from './BlockElements.module.css';

interface BlockData {
  smallTitle?: string;
  bigTitle?: string;
  blockInfo?: string;
  blockLinkText?: string;
  blockLink?: string;
}

export default function BlockElement({
  smallTitle = '',
  bigTitle = '',
  blockInfo = '',
  blockLinkText = 'Read more',
  blockLink = '/main/read_more',
  ...props
}: BlockData) {
  return (
    <div className={styles.container}>
      <div className={styles.blockcontainer}>
        <div>
          <h3>{smallTitle}</h3>
          <h2>{bigTitle}</h2>
        </div>
        <p>{blockInfo}</p>
        <div className={styles.btnprimary}>
          <NavLink to={blockLink} className={styles.btnprimarytext}>
            {blockLinkText}
            <span className={styles.btnprimaryicon}>
              <ArrowToRight />
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
