import React from 'react';

import styles from './ScientificProgramme.module.css';

interface ScientificProgrammeData {
  mainSubjectOne?: string;
  mainSubjectOneReporters?: string;
  mainSubjectTwo?: string;
  mainSubjectTwoReporters?: string;
}

export default function ScientificProgramme({
  mainSubjectOne = '',
  mainSubjectOneReporters = '',
  mainSubjectTwo = '',
  mainSubjectTwoReporters = '',
}: ScientificProgrammeData) {
  return (
    <div className={`${styles.scientificProgrammeContainer}`}>
      <h1>Scientific Programme</h1>
      <hr></hr>
      <h3>Main Subjects</h3>
      <div className={`${styles.mainSubjectsContainer}`}>
        <div className={`${styles.mainSubjectOneContainer}`}>
          <div className={`${styles.mainSubjectOne}`}>
            <p>{mainSubjectOne}</p>
            <hr></hr>
            <div className={`${styles.mainSubjectOneReportersContainer}`}>
              <h4>General Reporters</h4>
              <p>{mainSubjectOneReporters}</p>
            </div>
          </div>
        </div>
        <div className={`${styles.mainSubjectTwoContainer}`}>
          <div className={`${styles.mainSubjectTwo}`}>
            <p>{mainSubjectTwo}</p>
            <hr></hr>
            <div className={`${styles.mainSubjectTwoReportersContainer}`}>
              <h4>General Reporters</h4>
              <p>{mainSubjectTwoReporters}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
