import React from 'react';

import styles from './CongressCancunPage.module.css';

import congressCancunPagePicture from './CongressCancunImage.jpeg';

import congressCancunPageLogo from './CongressCancunLogo.jpeg';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import EventDetails from 'src/components/IFAcomponents/EventDetails/EventDetails';
import EventDetailsBlock from 'src/components/IFAcomponents/EventDetailsBlock/EventDetailsBlock';
import ScientificProgramme from 'src/components/IFAcomponents/scientificProgramme/ScientificProgramme';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';

interface CongressCancunPageProps {
  isSignedIn: boolean;
}

export default function CongressCancunPage({
  isSignedIn,
}: CongressCancunPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <div>
        <img
          src={congressCancunPagePicture}
          alt="Congress Cancun Page Diagram"
        />
      </div>
      <section className={`${styles.pageBlockContainer}`}>
        <div className={`${styles.containerColumnOne}`}>
          <EventDetails
            congress={'IFA 2023 Cancun'}
            congressDetails={
              'With great pleasure we welcome you to the 75th Congress of IFA in Cancun, Mexico from 10-14 September 2023. We will put together the latest scientific topics to provide participants an excellent forum to exchange and debate, meet and interact with friends, colleagues, sponsors and exhibitors.'
            }
            locationDetails={
              'Cancun offers a wide range of activities for visitors of all ages, from a splendorous landscape to a visit to the mystical ruins that will take you on an incredible journey back to the Mayan civilization. More information will follow soon!'
            }
          />
        </div>
        <div className={`${styles.containerColumnTwo}`}>
          <img src={congressCancunPageLogo} alt="Congress Cancun Page Logo" />
          <EventDetailsBlock
            congress={'IFA 2023 Cancun'}
            congressDates={'September 10-14 2023'}
            congressLocation={'Cancun, Mexico'}
          />
        </div>
      </section>
      <section className={`${styles.scientificBlockContainer}`}>
        <ScientificProgramme
          mainSubjectOne={
            'Sharing and shifting losses - The "new" profit shifting?'
          }
          mainSubjectOneReporters={'René Matteotti (Switzerland)'}
          mainSubjectTwo={'Good faith in domestic and international tax law'}
          mainSubjectTwoReporters={
            'Craig Elliffe (New Zealand) and Sjoerd Douma (Netherlands)'
          }
        />
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
