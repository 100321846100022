import React from 'react';

import styles from './TabButtons.module.css';
import { IconProps } from '../../types/misc';

interface TabButton {
  label: string;
  icon?: (props: IconProps) => JSX.Element;
}
export interface TabButtonsProps {
  tabs: TabButton[];
  switchTabs: (i: number) => void;
  activeTab: number;
}

export default function TabButtons({
  tabs,
  switchTabs,
  activeTab,
}: TabButtonsProps) {
  return (
    <div className={styles.tabButtons}>
      {tabs.map(({ icon: Icon, label }, i) => (
        <button
          key={i}
          className={styles.tabButton}
          onClick={() => switchTabs(i)}
          disabled={activeTab === i}
          type="button"
        >
          <div className={styles.tabButtonInner}>
            {Icon ? <Icon className={styles.tabIcon} /> : null}
            {label}
          </div>
        </button>
      ))}
    </div>
  );
}
