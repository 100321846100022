import { addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { database } from 'src/config/firebase';
import { attendee } from 'src/types/user';

export function getNewAttendeeData(): attendee {
  return {
    email: '',
    ifaNumber: '',
    name: '',
    number: '',
    isMember: false,
  };
}

export async function createNewAttendee(
  eventId: string,
  newAttendee: attendee
): Promise<attendee> {
  delete newAttendee.id;

  const document = await addDoc(
    collection(database, `events/${eventId}/attendees`),
    newAttendee
  );
  return { id: document.id, ...newAttendee };
}

export function updateAttendee(
  eventId: string,
  id: string,
  data: Partial<attendee>
) {
  // Remove id property when saving
  delete data.id;

  return updateDoc(doc(database, `events/${eventId}/attendees`, id), {
    ...data,
  });
}

export default {
  getNewAttendeeData,
  createNewAttendee,
  updateAttendee,
};
