import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { auth } from 'src/config/firebase';
import urls from 'src/config/urls';

import styles from './NavBar.module.css';
// import navBarLogo from './logo.svg';
import navBarLogo from './logo-ifasa.svg';
//import navBarLogo from './logo-ifasa-bold.svg';

import navBarLogoMobile from './logo-mob.svg';
// import searchPicture from './search.svg';
import loginPicture from './login.svg';
import { useStateContext } from 'src/services/contextProvider';

interface NavBarProps {
  isSignedIn?: boolean;
}

export default function NavBar(props: NavBarProps) {
  const isSignedIn = auth.currentUser !== null;
  const { isAdmin } = useStateContext();
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  return (
    <div className={`${styles.navBarComponent}`}>
      <div className={`${styles.navBarHeader}`}>
        <nav className={`${styles.navBar}`}>
          <NavLink to={urls.root} className={`${styles.navBarImageLink}`}>
            <img
              className={`${styles.navBarImageMobile}`}
              src={navBarLogoMobile}
              alt="IFA South Africa Logo"
            ></img>
            <img
              className={`${styles.navBarImage}`}
              src={navBarLogo}
              alt="IFA South Africa Logo"
            ></img>
          </NavLink>
          <button className={`${styles.navBarMenu}`} onClick={handleClick}>
            <div className={`${styles.navBarMenuIcon}`}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <div className={`${styles.navBarMenuText}`}>
              <span>Menu</span>
            </div>
          </button>
          {!isSignedIn && (
            <div className={`${styles.navBarLogin}`}>
              <NavLink
                to={urls.auth.signIn}
                className={`${styles.navBarLoginButton}`}
              >
                <div className={`${styles.navBarLoginText}`}>
                  <img src={loginPicture} alt="Login Diagram"></img>
                  <span>Login</span>
                </div>
              </NavLink>
            </div>
          )}
          {isSignedIn && (
            <div className={`${styles.navBarLogout}`}>
              <NavLink
                to={urls.auth.signOut}
                className={`${styles.navBarLogoutButton}`}
              >
                <div className={`${styles.navBarLogoutText}`}>
                  <img src={loginPicture} alt="Login Diagram"></img>
                  <span>Logout</span>
                </div>
              </NavLink>
            </div>
          )}

          {/* <div className={`${styles.navBarSearch}`}>
            <img src={searchPicture} alt="Search Diagram"></img>
          </div> */}
        </nav>
      </div>
      {click && (
        <div className={`${styles.navBarDropdownMenu}`}>
          <div className={`${styles.navBarDropdownMenuContainer}`}>
            <div className={`${styles.navBarDropdownMenuListContainer}`}>
              <ul className={`${styles.menuElementList}`}>
                <li className={`${styles.menuElementTitle}`}>
                  <NavLink
                    to={urls.about_ifa.root}
                    className={styles.menuElementTitleLink}
                  >
                    About IFA
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={urls.about_ifa.historyofifa}
                    className={styles.menuElementListLink}
                  >
                    History of IFA
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={urls.about_ifa.membership}
                    className={`${styles.menuElementListLink}`}
                  >
                    Membership
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={urls.about_ifa.ifa_sa_constitution}
                    className={`${styles.menuElementListLink}`}
                  >
                    IFA SA Constitution
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={urls.about_ifa.branch_executives}
                    className={`${styles.menuElementListLink}`}
                  >
                    Branch Executives
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={urls.about_ifa.cooperation}
                    className={`${styles.menuElementListLink}`}
                  >
                    Cooperation
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={urls.about_ifa.yin}
                    className={`${styles.menuElementListLink}`}
                  >
                    What is YIN?
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={urls.about_ifa.yinCommittee}
                    className={`${styles.menuElementListLink}`}
                  >
                    YIN Committee
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={urls.getInTouch.yinContactUs}
                    className={`${styles.menuElementListLink}`}
                  >
                    YIN Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
            {/* <div className={`${styles.navBarDropdownMenuListContainer}`}>
              <ul className={`${styles.menuElementList}`}>
                <li className={`${styles.menuElementTitle}`}>
                  <NavLink
                    to="/events"
                    className={`${styles.menuElementTitleLink}`}
                  >
                    Events
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/events/seminars"
                    className={`${styles.menuElementListLink}`}
                  >
                    Seminars
                  </NavLink>
                </li>
              </ul>
            </div> */}
            <div className={`${styles.navBarDropdownMenuListContainer}`}>
              <ul className={`${styles.menuElementList}`}>
                <li className={`${styles.menuElementTitle}`}>
                  <a
                    href="https://www.ifa.nl/congresses"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementTitleLink}`}
                  >
                    Congresses
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/congresses/ifa-2022-berlin"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    IFA 2022 Berlin
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/congresses/ifa-2023-cancun"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    IFA 2023 Cancun
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/congresses/ifa-2024-cape-town"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    IFA 2024 Cape Town
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/congresses/ifa-2025-lisbon"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    IFA 2025 Lisbon
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/congresses/ifa-2026-melbourne"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    IFA 2026 Melbourne
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/congresses/ifa-2027-stockholm"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    IFA 2027 Stockholm
                  </a>
                </li>
              </ul>
            </div>
            <div className={`${styles.navBarDropdownMenuListContainer}`}>
              <ul className={`${styles.menuElementList}`}>
                <li className={`${styles.menuElementTitle}`}>
                  <a
                    href="https://www.ifa.nl/research-awards"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementTitleLink}`}
                  >
                    Reasearch & Awards
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/research-awards/maurice-laur%C3%A9-prize"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    Maurice Lauré Prize
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/research-awards/mitchell-b-carroll-prize"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    Mitchel B. Carroll Prize
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/research-awards/ifa-president-yin-scientific-award"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    IFA President YIN Scientific Award
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/research-awards/poster-programme"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    Poster Programme
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/research-awards/drt-research-programme"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    DRT Research Programme
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.ifa.nl/research-awards/ifa-research-associate"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`${styles.menuElementListLink}`}
                  >
                    IFA Research Associate
                  </a>
                </li>
              </ul>
            </div>
            {isSignedIn && (
              <div className={`${styles.navBarDropdownMenuListContainer}`}>
                <ul className={`${styles.menuElementList}`}>
                  <li className={`${styles.menuElementTitle}`}>
                    <NavLink
                      to="/research_awards"
                      className={`${styles.menuElementTitleLink}`}
                    >
                      My IFA Account
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/my_membership"
                      className={`${styles.menuElementListLink}`}
                    >
                      My Membership
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/calendar"
                      className={`${styles.menuElementListLink}`}
                    >
                      My Events
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={urls.auth.setPassword}
                      className={styles.menuElementListLink}
                    >
                      Set Password
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
            {isSignedIn && isAdmin && (
              <div className={`${styles.navBarDropdownMenuListContainer}`}>
                <ul className={`${styles.menuElementList}`}>
                  <li className={`${styles.menuElementTitle}`}>
                    <NavLink
                      to="/research_awards"
                      className={`${styles.menuElementTitleLink}`}
                    >
                      Administration Tools
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={urls.admin_tools.members}
                      className={`${styles.menuElementListLink}`}
                    >
                      Members
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={urls.admin_tools.companies}
                      className={`${styles.menuElementListLink}`}
                    >
                      Companies
                    </NavLink>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
