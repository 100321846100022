import React from 'react';

import styles from './ExportButton.module.css';

interface ButtonData {
  buttonName?: string;
}

export default function ExportButton({
  buttonName = '',
  ...props
}: ButtonData) {
  return (
    <button className={`${styles.buttonContainer}`}>
      <span>{buttonName}</span>
    </button>
  );
}
