import React from 'react';
import { NavLink } from 'react-router-dom';

import FooterLogo from '../FooterLogo/FooterLogo';
import styles from './Footer.module.css';
import URLS from 'src/config/urls';

interface FooterProps {
  isSignedIn: boolean;
}

export default function Footer({ isSignedIn }: FooterProps) {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerPanel}>
        <div className={styles.footerElement1}>
          <ul className={styles.footerElementList}>
            <li className={styles.footerElementTitle}>
              <NavLink
                to="/about_ifa"
                className={styles.footerElementTitleLink}
              >
                About IFA
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink
                to="/about_ifa/membership"
                className={styles.footerElementItemLink}
              >
                Membership
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink
                to="/about_ifa/ifa_sa_constitution"
                className={styles.footerElementItemLink}
              >
                IFA SA Constitution
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink
                to="/about_ifa/branch_executives"
                className={styles.footerElementItemLink}
              >
                Branch Executive
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink
                to="/about_ifa/yin"
                className={styles.footerElementItemLink}
              >
                What is YIN?
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink
                to={URLS.about_ifa.yinCommittee}
                className={styles.footerElementItemLink}
              >
                YIN Commitee
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink
                to={URLS.getInTouch.yinContactUs}
                className={styles.footerElementItemLink}
              >
                YIN Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={styles.footerElement1}>
          <ul className={styles.footerElementList}>
            <li className={styles.footerElementTitle}>Events</li>
            <li className={styles.footerElementItem}>
              <NavLink
                to="/congresses"
                className={styles.footerElementItemLink}
              >
                Congresses
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink
                to="/events/seminars"
                className={styles.footerElementItemLink}
              >
                Seminars and Webinars
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink to="/calendar" className={styles.footerElementItemLink}>
                Calendar
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink to="#0" className={styles.footerElementItemLink}>
                News
              </NavLink>
            </li>
          </ul>
        </div>
        {isSignedIn && (
          <div className={styles.footerElement1}>
            <ul className={styles.footerElementList}>
              <li className={styles.footerElementTitle}>
                <NavLink
                  to="/member_resources/newsletters"
                  className={styles.footerElementTitleLink}
                >
                  Member Resources
                </NavLink>
              </li>
              <li className={styles.footerElementItem}></li>
              <li className={styles.footerElementItem}>
                <NavLink
                  to="/member_resources/newsletters"
                  className={styles.footerElementItemLink}
                >
                  Newsletters
                </NavLink>
              </li>
              <li className={styles.footerElementItem}>
                <NavLink
                  to="/member_resources/seminar_materials"
                  className={styles.footerElementItemLink}
                >
                  Seminar Materials
                </NavLink>
              </li>
              <li className={styles.footerElementItem}>
                <NavLink
                  to="/research_awards"
                  className={styles.footerElementItemLink}
                >
                  Research & Awards
                </NavLink>
              </li>
            </ul>
          </div>
        )}
        <div className={styles.footerElement1}>
          <ul className={styles.footerElementList}>
            <li className={styles.footerElementTitle}>Quick Links</li>
            <li className={styles.footerElementItem}>
              <a
                href="https://www.ifa.nl"
                className={styles.footerElementItemLink}
                target="_new"
              >
                Central IFA
              </a>
            </li>
            <li className={styles.footerElementItem}>
              <a
                href="https://www.ibfd.org/"
                className={styles.footerElementItemLink}
                target="_new"
              >
                IBFD
              </a>
            </li>
            <li className={styles.footerElementItem}>
              <a
                href="https://www.ataftax.org/"
                className={styles.footerElementItemLink}
                target="_new"
              >
                ATAF
              </a>
            </li>
          </ul>
        </div>
        <div className={styles.footerElement1}>
          <ul className={styles.footerElementList}>
            <li className={styles.footerElementTitle}>Get in touch</li>
            <li className={styles.footerElementItem}>
              <NavLink
                to="https://www.ifa.nl/branches-regions"
                className={styles.footerElementItemLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Branches & Regions
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink
                to="https://www.ifa.nl/about-ifa/general-secretariat"
                className={styles.footerElementItemLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                General Secretariat
              </NavLink>
            </li>
            <li className={styles.footerElementItem}>
              <NavLink
                to={URLS.getInTouch.contact}
                className={styles.footerElementItemLink}
              >
                Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.footerlogocontainer}>
        <FooterLogo />
      </div>
      <div className={styles.footerElementDetails}>
        <p>
          Copyright {new Date().getFullYear()} IFA. All rights reserved.{' '}
          <NavLink to="#0" className={styles.footerElementDetailsLink}>
            Sitemap
          </NavLink>{' '}
          |
          <NavLink to="#0" className={styles.footerElementDetailsLink}>
            {' '}
            Privacy Policy
          </NavLink>
        </p>
      </div>
    </div>
  );
}
