import React from 'react';

import styles from './Checkbox.module.css';
import { classnames } from '../../utils/universal_utils';

type CheckboxPropTypes = {
  checked: boolean;
  className?: string;
  id: string;
  label?: string | React.ReactNode;
  onChange: (e: any) => void;
};

const Checkbox = ({
  checked,
  className,
  id,
  label,
  onChange,
}: CheckboxPropTypes) => {
  const classNames = classnames([styles.root, 'border-box', className]);
  return (
    <label className={classNames} htmlFor={id}>
      <input
        checked={checked}
        className={styles.checkbox}
        data-testid={id}
        id={id}
        name={id}
        onChange={onChange}
        type="checkbox"
      />

      <span className={styles.label}>{label}</span>
    </label>
  );
};

export default Checkbox;
