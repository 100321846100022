import * as React from 'react';

import styles from './AuthStyles.module.css';
import { User } from 'src/types/user';

import URLS from 'src/config/urls';
import api from 'src/services/api';
import PublicPage from 'src/components/page/PublicPage/PublicPage';
import ErrorText from 'src/components/form/ErrorText';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import { applyActionCode, Auth } from 'firebase/auth';

interface VerifyEmailPageProps {
  actionCode: string;
  user: User;
}

type VerifyStatus =
  | 'could-not-verify-email'
  | 'loading'
  | 'email-verified'
  | 'sending-verification-email'
  | 'could-not-send-email'
  | 'verification-email-sent';

export function VerifyEmailPage({ actionCode, user }: VerifyEmailPageProps) {
  const { id: uid, email } = user;
  const [status, setStatus] = React.useState<VerifyStatus>('loading');
  const [verifyError, setVerifyError] = React.useState('');

  React.useEffect(() => {
    async function verifyUserEmail(auth: Auth, actionCode: string) {
      return applyActionCode(auth, actionCode)
        .then(() => setStatus('email-verified'))
        .catch(() => {
          setStatus('could-not-verify-email');

          setVerifyError(
            'Your verification has expired. You will need to verify your email again'
          );
        });
    }

    verifyUserEmail(api.auth, actionCode);
  }, [actionCode]);

  const sendVerificationEmail = async () => {
    setStatus('sending-verification-email');

    try {
      const response = await fetch(URLS.api.verifyEmail, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: email,
        }),
      });

      if (!response.ok) {
        console.error(
          `response ${response.status} | ${response.type} | ${response.statusText}`
        );

        setStatus('could-not-send-email');

        throw new Error('Network response was not ok');
      }

      setStatus('verification-email-sent');
    } catch (error) {
      console.error('Verify fetch error: ', error);

      setStatus('could-not-send-email');
    }
  };

  const updateUserStatus = async () => {
    console.log('Verify email', uid);
    //   api.updateUserStatus(uid, 'new-user-unsubscribed').catch((error) => {
    //     // TODO: display error to user
    //     console.error('updateUserStatus ERROR:', error);
    //   });
  };

  // if (user.status === 'new-user-unsubscribed') {
  //   return <Redirect to={URLS.subscription.subscribe} />;
  // }

  return (
    <PublicPage testId="verify-email-page">
      <article className={styles.main}>
        {status === 'loading' ? (
          <h1 className={styles.subTitle}>Verifying your email...</h1>
        ) : null}

        {status === 'email-verified' ? (
          <>
            <h1 className={styles.subTitle}>Your email has been verified</h1>

            <PrimaryButton onClick={updateUserStatus}>Continue</PrimaryButton>
          </>
        ) : null}

        {verifyError ? (
          <>
            <h1 className={styles.subTitle}>
              We could not verify your email address
            </h1>

            <ErrorText isVisible={status === 'could-not-send-email'}>
              We could not send verification email. Please try again later.
            </ErrorText>

            <PrimaryButton
              className={styles.button}
              disabled={status === 'sending-verification-email'}
              onClick={sendVerificationEmail}
            >
              {status === 'sending-verification-email'
                ? 'Sending verification email...'
                : 'Resend verification email'}
            </PrimaryButton>
          </>
        ) : null}
      </article>
    </PublicPage>
  );
}
