import React from 'react';

import styles from './IFAResearchAssociatePage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';

interface IFAResearchAssociatePageProps {
  isSignedIn: boolean;
}

export default function IFAResearchAssociatePage({
  isSignedIn,
}: IFAResearchAssociatePageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <PageHeader pageHeader={'IFA Research Associate'} />
        <p>
          Annually IFA engages a Research Associate who assists the Permanent
          Scientific Committee (PSC) in the preparation of the scientific
          programme of the annual Congresses.
        </p>
        <p>
          The job of the Research Associate is to investigate and develop topics
          that have been suggested as possible Congress or Seminar subjects. The
          Research Associate develops a bibliography for each topic. Working
          from the bibliography, the Research Associate then prepares a thorough
          analysis of the topic concerned, including identification of aspects
          which may be interesting to discuss in the specific context of the
          yearly IFA Congresses.
        </p>
        <p>
          Throughout the process, the Research Associate will be supervised in
          day-to-day work, while the overall responsibility is with the Chair of
          the PSC. The Research Associate attends meetings of the PSC and works
          closely with its Chair and Vice-Chairs.
        </p>
      </section>
      <section className={`${styles.pageBlockContainer}`}>
        <div className={`${styles.competitionRulesContainer}`}>
          <h2>IFA Research Associate position 2020/2021</h2>
        </div>
        <p>
          <strong>Who should apply?</strong>
          <p>
            Candidates with an academic background in taxation, particularly
            international taxation, some work experience and a working knowledge
            of English and one or (preferably) two other languages. Applications
            together with a Cover letter, Curriculum Vitae and a list of
            referees should be forwarded to Anja van Zwietering
            (a.gensecr@ifa.nl)
            <p>
              <strong>Deadline:</strong> before 1 February 2021.
            </p>
          </p>
        </p>
        <p>
          <strong>Where and when ?</strong>
          <p>
            The position is for a period of six months, ideally beginning in
            August and ending January/February of the following year. Candidates
            applying for this position must be able to make themselves available
            full-time for the entire period. Depending the developments of the
            pandemic, the position will most likely be filled in by remote
            working.
          </p>
        </p>
        <p>
          <strong>Stipend</strong>
          <p>
            The remuneration for the position depends on the individual's
            qualifications and the period during which he or she will be able to
            work. In addition, the expenses of attending the Annual IFA Congress
            and the January/February 2022 meeting of the PSC will be covered. In
            view of the continuing uncertainty due to the worldwide health
            situation, full and concrete details also with a view to its
            congress format for the 2021 Congress will follow at a later stage
            once more is known on future developments and the impact of the
            Covid-19 pandemic.
          </p>
        </p>
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <Footer isSignedIn={isSignedIn} />
    </>
  );
}
