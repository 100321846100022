import React from 'react';

import styles from './SecondaryButton.module.css';
import { ButtonTheme } from '../../types/misc';

import BaseButton from './BaseButton';
import ChevronIcon from '../svg/ChevronIcon';
import { classnames } from '../../utils/universal_utils';

interface Props {
  children: React.ReactNode;
  displayIcon?: boolean;
  theme?: ButtonTheme | 'default';
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function SecondaryButton({
  children,
  type = 'button',
  disabled = false,
  className = '',
  theme = 'default',
  displayIcon = false,
  ...props
}: Props) {
  const classNames = classnames([styles[theme], styles.button, className]);

  return (
    <BaseButton
      disabled={disabled}
      type={type}
      className={classNames}
      {...props}
    >
      {children}

      {displayIcon ? (
        <div className={styles.buttonIcon}>
          <ChevronIcon direction="right" />
        </div>
      ) : null}
    </BaseButton>
  );
}
