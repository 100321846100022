import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from '../buttons/BaseButton.module.css';

export interface BaseButtonLinkProps {
  children: React.ReactNode;
  href: string | object;
  className?: string;
}

export default function BaseButtonLink({
  children,
  className = '',
  href,
  ...props
}: BaseButtonLinkProps) {
  return (
    <NavLink to={href} className={`${styles.button} ${className}`} {...props}>
      {children}
    </NavLink>
  );
}
