import React from 'react';

import styles from './EventPayments.module.css';

import { NavLink } from 'react-router-dom';

import { auth } from 'src/config/firebase';
import { bookingsAndPayments } from 'src/types/user';

interface EventBlockTestData {
  eventTheme?: 'dark' | 'light' | 'grey';
  eventMemberPrice?: string;
  eventNonMemberPrice?: string;
  eventId?: string;
  bookingInfo?: bookingsAndPayments;
}

export default function EventPayments({
  eventTheme = 'dark',
  eventMemberPrice = '',
  eventNonMemberPrice = '',
  eventId = '',
  bookingInfo,
  ...props
}: EventBlockTestData) {
  const isSignedIn = auth.currentUser !== null;
  const componentTheme =
    eventTheme === 'grey'
      ? styles.eventDetailsContainerGrey
      : eventTheme === 'light'
        ? styles.eventDetailsContainerLight
        : styles.eventDetailsContainerDark;
  return (
    <div className={`${componentTheme}`}>
      <h2>Bookings & Payments</h2>
      <table>
        <tbody>
          {eventMemberPrice && (
            <tr>
              <td>
                <span>Member Price</span>
              </td>
              <td>{eventMemberPrice}</td>
            </tr>
          )}
          {eventNonMemberPrice && (
            <tr>
              <td>
                <span>Price</span>
              </td>
              <td>{eventNonMemberPrice}</td>
            </tr>
          )}
        </tbody>
      </table>
      <hr></hr>
      {bookingInfo?.registrationUrl && (
        <div className={`${styles.registerationContainer}`}>
          <div className={`${styles.registerationButtonContainer}`}>
            <a
              href={bookingInfo?.registrationUrl}
              className={`${styles.registerButton}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Registration
            </a>
          </div>
        </div>
      )}
      {!bookingInfo?.registrationUrl && !isSignedIn && (
        <div className={`${styles.registerationContainer}`}>
          <div className={`${styles.registerationButtonContainer}`}>
            <NavLink
              to={`/events/registration/non_member/${eventId}`}
              className={`${styles.registerButton}`}
            >
              Register As Guest
            </NavLink>
          </div>
        </div>
      )}
      {!bookingInfo?.registrationUrl && isSignedIn && (
        <div className={`${styles.registerationContainer}`}>
          <div className={`${styles.registerationButtonContainer}`}>
            <NavLink
              to={`/events/registration/member/${eventId}`}
              className={`${styles.registerButton}`}
            >
              Register As Member
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
}
