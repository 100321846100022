import React from 'react';

import styles from './CooperationPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';

interface CooperationPageProps {
  isSignedIn: boolean;
}

export default function CooperationPage({ isSignedIn }: CooperationPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>

      <section className={`${styles.pageBlockContainer}`}>
        {/* <div className={`${styles.headercontainer}`}> */}
        <PageHeader pageHeader={'Cooperation'} />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'IFA has a consultative status with the United Nations Economic and Social Council. In that capacity, it is represented at meetings of the UN Committee of Experts on International Tax Cooperation.'
          }
        />
        <PageBody
          pageBody={
            'IFA also maintains close contact with the fiscal activities of the EU, the CIAT, the EATLP, the ECLA, the ICC, the OECD and various other organisations, including its Sister Organisation, the International Bureau of Fiscal Documentation.'
          }
        />

        <PageBody
          pageBody={
            'An illustration of IFAs unique role in the world of taxation, where the public and private sectors are natural adversaries, is the fact that at each Congress a joint IFA/OECD Seminar is held with Panelists belonging to both organisations to discuss issues of common interest.'
          }
        />
        <PageBody
          pageBody={
            'Recently also a joint IFA/EU Seminar was initiated at each Congress that aims to discuss international tax measures adopted by the European Commission as well as the impacts of such measures.'
          }
        />

        {/* </div> */}
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <Footer isSignedIn={isSignedIn} />
    </>
  );
}
