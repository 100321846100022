import React from 'react';

import styles from './CongressMelbournePage.module.css';

import congressMelbournePagePicture from './CongressMelbourneImage.jpeg';

// import congressMelbournePageLogo from './CongressMelbourneLogo.jpeg';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import EventDetails from 'src/components/IFAcomponents/EventDetails/EventDetails';
import EventDetailsBlock from 'src/components/IFAcomponents/EventDetailsBlock/EventDetailsBlock';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';

interface CongressMelbournePageProps {
  isSignedIn: boolean;
}

export default function CongressMelbournePage({
  isSignedIn,
}: CongressMelbournePageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <div>
        <img
          src={congressMelbournePagePicture}
          alt="Congress Melbourne Page Diagram"
        />
      </div>
      <section className={`${styles.pageBlockContainer}`}>
        <div className={`${styles.containerColumnOne}`}>
          <EventDetails
            congress={'IFA 2025 Melbourne'}
            congressDetails={
              'In 2025 we welcome you in Lisbon! Dates will follow.'
            }
            locationDetails={''}
          />
        </div>
        <div className={`${styles.containerColumnTwo}`}>
          {/* <img src={congressBerlinPageLogo} alt="Congress Berlin Page Logo" /> */}
          <EventDetailsBlock
            congress={'IFA 2025 Melbourne'}
            congressDates={'Unconfirmed'}
            congressLocation={'Melbourne, Portugal'}
          />
        </div>
      </section>
      {/* <section className={`${styles.scientificBlockContainer}`}>
        <ScientificProgramme
          mainSubjectOne={
            'Group approach and separate entity appraoch in domestic and international tax law'
          }
          mainSubjectOneReporters={
            'Johanna Hey (Germany) and Arne Schnitger (Germany)'
          }
          mainSubjectTwo={
            'Big data and tax - dometic and international taxation of data driven business'
          }
          mainSubjectTwoReporters={'Gary Sprague (USA)'}
        />
      </section> */}
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
