import React from 'react';
import { IconProps } from '../../types/misc';

export default function AddIcon({ className = '', ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 24 24"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M21.705 10.223h-8.963V1.251C12.742.565 12.177 0 11.491 0h-.017c-.686 0-1.251.565-1.251 1.251v9.02H1.251c-.686 0-1.251.565-1.251 1.251v.018c0 .686.565 1.251 1.251 1.251h9.021v9.021c0 .686.565 1.251 1.251 1.251h.017c.686 0 1.251-.565 1.251-1.251v-9.069h9.021c.686 0 1.251-.565 1.251-1.251v-.009a1.413 1.413 0 00-1.358-1.26z"
      />
    </svg>
  );
}
