import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './EventAttendeesBlock.module.css';

interface EventAttendeesData {
  eventTheme?: 'dark' | 'light' | 'grey';
  eventAttendeesTotal?: number;
  eventId?: string;
}

export default function EventAttendeesBlock({
  eventTheme = 'dark',
  eventAttendeesTotal = 0,
  eventId = '',
  ...props
}: EventAttendeesData) {
  const componentTheme =
    eventTheme === 'grey'
      ? styles.eventDetailsContainerGrey
      : eventTheme === 'light'
        ? styles.eventDetailsContainerLight
        : styles.eventDetailsContainerDark;
  return (
    <div className={`${componentTheme}`}>
      <h2>Event Attendees</h2>
      <table>
        <tbody>
          <tr>
            <td>
              <span>Total</span>
            </td>
            <td>{eventAttendeesTotal}</td>
          </tr>
        </tbody>
      </table>
      <hr></hr>
      <div className={`${styles.registerationContainer}`}>
        <div className={`${styles.registerationButtonContainer}`}>
          <NavLink
            to={`/admin_tools/event_attendees/${eventId}`}
            className={`${styles.registerButton}`}
          >
            View
          </NavLink>
        </div>
      </div>
    </div>
  );
}
