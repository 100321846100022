import React from 'react';

import styles from './BranchExecutiveTable.module.css';

export default function BranchExecutiveTable() {
  return (
    <div className={`${styles.tablePanel}`}>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td>
              <p>President</p>
            </td>
            <td>
              <p>
                Prof Jennifer Roeleveld <br />
                (Member of the Supervisory Board of IFA)
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Secretary-General</p>
            </td>
            <td>
              <p>Mr De Wet de Villiers</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Treasurer</p>
            </td>
            <td>
              <p>Mr Leon Coetzee</p>
            </td>
          </tr>
          <tr>
            <td>
              <p>Executive Board</p>
            </td>
            <td>
              <p>
                Ms Anne Bennett <br />
                Prof Dr Emil Brincker <br />
                Ms Lisa Brunton
                <br />
                Mr Peter Dachs <br />
                Prof Dr Johann Hattingh - (Member of the Permanent Scientific{' '}
                Committee of IFA) <br />
                Prof Des Kruger <br />
                Dr Albertus Marais - (Ad-hoc member of the Permanent Scientific{' '}
                Committee of IFA)
                <br />
                Mr Jerome Brink
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
