import React from 'react';

import styles from './ErrorText.module.css';
import ErrorIcon from '../svg/ErrorIcon';

interface Props {
  children: React.ReactNode;
  isVisible: boolean;
  className?: string;
  border?: boolean;
}

export default function ErrorText({
  children,
  isVisible,
  className = '',
  border = false,
}: Props) {
  if (isVisible) {
    const borderStyle = border ? styles.boxBorder : '';

    return (
      <div className={`${styles.errorText} ${borderStyle} ${className}`}>
        <ErrorIcon className={styles.errorIcon} />
        {children}
      </div>
    );
  }

  return null;
}
