import React from 'react';
import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import styles from './YINContactUs.module.css';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import PageBullet from 'src/components/IFAcomponents/PageBullet/PageBullet';
import { NavLink } from 'react-router-dom';
import urls from 'src/config/urls';
import SecondaryButtonLink from 'src/components/buttonLinks/SecondaryButtonLink';

interface Props {
  isSignedIn: boolean;
}

const YINContactUs = ({ isSignedIn }: Props) => {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <PageHeader pageHeader={'YIN Contact Us'} />

        <PageBody
          pageBody={
            <>
              <div>
                <span>
                  If you are interested in becoming a member of YIN South Africa
                  and/or participating in the South African YIN Committee,
                  please contact us on{' '}
                </span>
                <a
                  href="mailto:yin@ifasouthafrica.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  yin@ifasouthafrica.org
                </a>
                <span> or IFA-RSA’s Secretary General at </span>
                <a
                  href="mailto:secretary-general@ifasouthafrica.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  secretary-general@ifasouthafrica.org.
                </a>
              </div>
            </>
          }
        />

        <PageBody
          pageBody={
            'Feel free to also follow us on our social media pages to stay in touch. We post all the latest news relating to the organisation, and IFA in general, on these pages as well as communicate when we will be hosting our next event.'
          }
        />

        <ul>
          <li>
            <span>LinkedIn: </span>
            <a
              href="https://www.linkedin.com/company/young-ifa-network-yin-southafrica/?viewAsMember=true"
              target="_blank"
              rel="noopener noreferrer"
            >
              Young IFA Network (YIN) - South Africa: Overview | LinkedIn
            </a>
          </li>

          <li>
            <span>Twitter: </span>
            <a
              href="https://twitter.com/yin_ifa_SA"
              target="_blank"
              rel="noopener noreferrer"
            >
              YIN IFA South Africa (@yin_ifa_SA) / X (twitter.com)
            </a>
          </li>
        </ul>

        <PageBody
          pageBody={
            'Becoming a YIN member not only enables you greater access to the YIN South Africa network but also discounted fees on regional and international events including the upcoming IFA congress in Cape Town South Africa.'
          }
        />

        <PageBody pageBody={'We look forward to connecting with you!'} />

        <div className={styles.buttons}>
          <SecondaryButtonLink href={urls.about_ifa.yin}>
            What is YIN?
          </SecondaryButtonLink>

          <SecondaryButtonLink href={urls.about_ifa.yinCommittee}>
            YIN Committee
          </SecondaryButtonLink>
        </div>
      </section>

      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
};

export default YINContactUs;
