import React from 'react';
import apiResources from 'src/services/apiResources';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import NewsletterFolder from 'src/components/IFAcomponents/NewsletterFolder/NewsletterFolder';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import styles from './NewslettersPage.module.css';
import { Navigate } from 'react-router-dom';
import urls from 'src/config/urls';
import { auth } from 'src/config/firebase';

interface NewslettersPageProps {
  isAdmin: boolean;
}

// eslint-disable-next-line no-empty-pattern
export default function NewslettersPage({ isAdmin }: NewslettersPageProps) {
  const isSignedIn = auth?.currentUser !== null;
  if (!isSignedIn) return <Navigate to={urls.root} />;

  const { folder: newsletterFolder } = apiResources.useNewsLetters();

  apiResources.sortByNameDescending(newsletterFolder.folders);

  return (
    <>
      <header>
        <NavBar />
      </header>
      <section className={styles.newsLettersContainer}>
        <NewsletterFolder
          folder={newsletterFolder}
          indent={0}
          isAdmin={isAdmin}
        />
      </section>
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
