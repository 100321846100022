import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import urls from 'src/config/urls.js';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import ExportButton from 'src/components/IFAcomponents/ExportButton/ExportButton';
import MembersList from 'src/components/IFAcomponents/MembersList/MembersList';
import useFetchCollection from 'src/services/useFetchCollection';
import { IFAUser } from 'src/types/user';
import MessageBar from 'src/components/IFAcomponents/MessageBar/MessageBar';
import styles from './MembersListPage.module.css';

export default function MembersListPage() {
  const [searchString, setSearchString] = useState('');
  const {
    values: memberData,
    loading,
    error,
  } = useFetchCollection<IFAUser>('users');

  function handleSearch(e: { target: { value: string } }) {
    const searchValue = e.target.value;
    setSearchString(searchValue);
  }

  return (
    <>
      <header>
        <NavBar />
      </header>
      <section className={styles.backContainer}>
        <NavLink to={urls.admin_tools.members}>Members</NavLink>
        <span>&nbsp;|&nbsp;</span>
        <NavLink to={urls.admin_tools.companies}>Companies</NavLink>
      </section>
      <section className={`${styles.pageBlockContainer}`}>
        <div>
          <PageHeader pageHeader={'Members List'} />
        </div>
        <div className={`${styles.exportButtonsContainer}`}>
          <div className={`${styles.excelButton}`}>
            <ExportButton buttonName={'Export To Excel'} />
          </div>
          <div className={`${styles.xlsxButton}`}>
            <ExportButton buttonName={'Export As XLSX'} />
          </div>
          <div className={`${styles.searchInputContainer}`}>
            <label>Search</label>
            <input
              className={`${styles.searchInput}`}
              type="text"
              name="search"
              value={searchString}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className={`${styles.createNewContainer}`}>
          <span>Number of Members: {memberData.length}</span>
          <NavLink to={urls.admin_tools.memberCreate}>Create New</NavLink>
        </div>
        {loading && (
          <>
            <p>Loading</p>
          </>
        )}
        {error && (
          <>
            <MessageBar messageBarType="error" messageText={error.message} />
          </>
        )}
      </section>
      <section className={`${styles.pageBlockContainer}`}>
        <MembersList
          data={memberData.filter(
            (member) =>
              !searchString ||
              member.personalDetails?.name
                ?.toLocaleLowerCase()
                .includes(searchString.toLowerCase())
          )}
        />
      </section>
    </>
  );
}
