import { collection, onSnapshot } from 'firebase/firestore';
import React from 'react';
import { database } from 'src/config/firebase';

interface CollectionResult<T> {
  error: Error | null;
  loading: boolean;
  values: T[];
}

interface FirebaseDocument {
  id?: string;
}

export const useFetchCollection = <T extends FirebaseDocument>(
  collectionName: string
): CollectionResult<T> => {
  const [data, setData] = React.useState<CollectionResult<T>>({
    error: null,
    loading: true,
    values: [],
  });

  React.useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(database, collectionName),
      (snapshot) => {
        setData({
          error: null,
          loading: false,
          values: snapshot.docs.map(
            (doc) =>
              ({
                id: doc.id,
                ...doc.data(),
              }) as T
          ),
        });
      },
      (error) => {
        setData({
          error,
          loading: false,
          values: [],
        });
      }
    );
    return unsubscribe;
  }, [collectionName]);

  return data;
};

export default useFetchCollection;
