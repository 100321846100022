import React from 'react';

import styles from './IFAPresidentYINScientificAwardPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import { NavLink } from 'react-router-dom';

interface IFAPresidentYINScientificAwardPageProps {
  isSignedIn: boolean;
}

export default function IFAPresidentYINScientificAwardPage({
  isSignedIn,
}: IFAPresidentYINScientificAwardPageProps) {
  // });
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <PageHeader pageHeader={'IFA President YIN Scientific Award'} />
        <p>
          The IFA President YIN Scientific Award was launched in 2014. This
          Award shall be awarded for an article published in a qualified medium,
          furthering the understanding of international fiscal law or
          comparative tax law, providing practical solutions to problems arising
          in cross-border transactions or situations.
        </p>
        <p>
          <strong>Subject: </strong>Any topic furthering the understanding of
          international fiscal law or comparative tax law, providing practical
          solutions to problems arising in cross-border transactions or
          situations.
        </p>
        <p>
          <strong>Language: </strong>English
        </p>
        <p>
          <strong>Open to: </strong>Young IFA Network (YIN) members under 35
          years of age on the 31 of July of the year in which the Prize is
          awarded.
        </p>
        {/* <p>
          <strong>Conditions:</strong>
          <ul>
            <li>Submission of a presentation letter on the topic;</li>
            <li>Submission of a resume including the date of birth;</li>
            <li>
              Upon submission, candidates need additionally to prepare an
              article on the topic in English with a maximum of 10.000 words.
            </li>
          </ul>
        </p> */}
        <p>
          <strong>Prize: </strong>Euro 1.000, plus a memento, a certificate and
          an invitation to attend the IFA 2020 Congress in Cancun, exempted from
          the payment of the Congress registration fee, during which the Prize
          is awarded. Due to the Covid-19 situation the 2020 Cancun Congress had
          to be postponed to 2022. At this moment the IFA Board is working on a
          solution to give the 2020 winner of the IFA President YIN Scientific
          Award suitable exposure
        </p>
        <p>
          <strong>Submission deadline: </strong>1 April 2021, by e-mail.
        </p>
      </section>
      <section className={`${styles.pageBlockContainer}`}>
        <div className={`${styles.competitionRulesContainer}`}>
          <h2>Competition Rules</h2>
          <ul>
            <li>
              <NavLink to="#">
                ML Rules on the competition for the Mitchell B. Carroll Prize
                2020.pdf
              </NavLink>
            </li>
          </ul>
          <hr></hr>
        </div>
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <Footer isSignedIn={isSignedIn} />
    </>
  );
}
