import React from 'react';

import styles from './PublicPage.module.css';

import { classnames } from '../../../utils/universal_utils';

interface PublicPageProps {
  children: React.ReactNode;
  testId: string;
  className?: string;
  isLoadingPage?: boolean;
  title?: string;
  smallHeader?: boolean;
  headerContent?: React.ReactNode;
}

export default function PublicPage({
  children,
  className = '',
  isLoadingPage,
  title = '',
  smallHeader = false,
  headerContent,
  testId,
}: PublicPageProps) {
  const pageClasses = classnames({
    'border-box': true,
    [styles.root]: true,
    [styles['root--is-loading-page']]: isLoadingPage,
    [className]: !!className,
  });

  const headerClasses = classnames({
    [styles.header]: true,
    [styles.smallHeader]: smallHeader,
  });

  return (
    <div data-testid={testId} className={pageClasses}>
      <div className={styles.bg}></div>

      <header className={headerClasses}>
        {/* <div className={styles.logo}>
          <img
            src={MyAfirefiImage}
            alt="afirefi lotus bloom with the afirefi text beside it"
          />
        </div> */}

        {/* {title && <h1 className={styles.title}>{title}</h1>}*/}

        {headerContent && headerContent}
      </header>

      <main className={styles.body}>{children}</main>
    </div>
  );
}
