import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './AboutBlockElement.module.css';

interface AboutTitle {
  aboutTitle?: string;
  aboutLink?: string;
}

export default function AboutBlockElement({
  aboutTitle = '',
  aboutLink = '#',
  ...props
}: AboutTitle) {
  return (
    <NavLink to={aboutLink} className={styles.blockLink}>
      <div className={styles.blockcontainer}>
        <div className={styles.blocktitle}>
          <h1>{aboutTitle}</h1>
        </div>
        <div className={styles.blockspacer}></div>
        <div className={styles.btnprimaryicon}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="10"
            viewBox="0 0 27 10"
          >
            <polygon
              fill="##00FFFFFF"
              fillRule="evenodd"
              points="128.95 13.017 132.234 16.282 108 16.282 108 17.718 132.234 17.718 128.95 20.983 129.973 22 135 17 129.973 12"
              transform="translate(-108 -12)"
            ></polygon>
          </svg>
        </div>
      </div>
    </NavLink>
  );
}
