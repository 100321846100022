import React from 'react';
import { taxStatusDetails } from 'src/types/user';
import api from 'src/services/api';

import styles from './TaxStatusForm.module.css';

interface TaxStatusDetails {
  uid?: string;
  taxStatusDetails?: taxStatusDetails;
}

export default function TaxStatusForm({
  uid,
  taxStatusDetails,
  ...props
}: TaxStatusDetails) {
  /////////////////////////////////////////////Functions To Change Strings///////////////////////////////////////
  // Tax Status
  function selectTaxStatus(e: React.ChangeEvent<HTMLSelectElement>) {
    const taxStatusValue: string = e.target.value;
    if (uid && taxStatusDetails?.taxStatus !== taxStatusValue) {
      api.updateUser(uid, {
        taxStatusDetails: {
          ...taxStatusDetails,
          taxStatus: taxStatusValue,
        },
      });
    }
  }

  // Income Tax Number

  function handleIncomeTaxNumberChange(e: { target: { value: string } }) {
    const incomeTaxNumberValue: string = e.target.value;
    if (uid && taxStatusDetails?.incomeTaxNumber !== incomeTaxNumberValue) {
      api.updateUser(uid, {
        taxStatusDetails: {
          ...taxStatusDetails,
          incomeTaxNumber: incomeTaxNumberValue,
        },
      });
    }
  }
  //ID Number
  function handleIdNumberChange(e: { target: { value: string } }) {
    const idNumberValue: string = e.target.value;
    if (uid && taxStatusDetails?.idNumber !== idNumberValue) {
      api.updateUser(uid, {
        taxStatusDetails: {
          ...taxStatusDetails,
          idNumber: idNumberValue,
        },
      });
    }
  }
  // Residence Permit Number
  function handleResidencePermitNumberChange(e: { target: { value: string } }) {
    const residencePermitNumberValue: string = e.target.value;
    if (
      uid &&
      taxStatusDetails?.residencePermitNumber !== residencePermitNumberValue
    ) {
      api.updateUser(uid, {
        taxStatusDetails: {
          ...taxStatusDetails,
          residencePermitNumber: residencePermitNumberValue,
        },
      });
    }
  }
  // Passport Country
  function handlePassportCountryChange(e: { target: { value: string } }) {
    const passportCountryValue: string = e.target.value;
    if (uid && taxStatusDetails?.passportCountry !== passportCountryValue) {
      api.updateUser(uid, {
        taxStatusDetails: {
          ...taxStatusDetails,
          passportCountry: passportCountryValue,
        },
      });
    }
  }
  // Passport Number
  function handlePassportNumberChange(e: { target: { value: string } }) {
    const passportNumberValue: string = e.target.value;
    if (uid && taxStatusDetails?.passportNumber !== passportNumberValue) {
      api.updateUser(uid, {
        taxStatusDetails: {
          ...taxStatusDetails,
          passportNumber: passportNumberValue,
        },
      });
    }
  }
  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Tax Status</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>Tax Status</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <select
                  onChange={selectTaxStatus}
                  className={`${styles.addressTypeInputContainer}`}
                >
                  <option selected disabled>
                    Please select your Tax Status
                  </option>
                  <option value="Tax Resident">Tax Resident</option>
                  <option value="Not Tax Resident">
                    Not A Resident For Tax Purposes
                  </option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>
              Income Tax Number
            </td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="incomeTaxNumber"
                  value={taxStatusDetails?.incomeTaxNumber}
                  onChange={handleIncomeTaxNumberChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>ID Number</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="idNumber"
                  value={taxStatusDetails?.idNumber}
                  onChange={handleIdNumberChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Residence Permit Number</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="residencePermitNumber"
                  value={taxStatusDetails?.residencePermitNumber}
                  onChange={handleResidencePermitNumberChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Passport Country</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="passportCountry"
                  value={taxStatusDetails?.passportCountry}
                  onChange={handlePassportCountryChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Passport Number</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="passportNumber"
                  value={taxStatusDetails?.passportNumber}
                  onChange={handlePassportNumberChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
