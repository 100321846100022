import React from 'react';

export type DefaultState = {
  initialised: boolean;
  isSignedIn: boolean;
  currentUserId: string | undefined;
  currentUserEmail: string | undefined;
  currentUserDisplayName: string | undefined;
  isAdmin: boolean | undefined;
};

export const InitialState: DefaultState = {
  initialised: false,
  isSignedIn: false,
  currentUserId: undefined,
  currentUserEmail: undefined,
  currentUserDisplayName: undefined,
  isAdmin: undefined,
};

export const Context = React.createContext<DefaultState | undefined>(undefined);

export default Context;
