import React from 'react';
import { personalDetails } from 'src/types/user';
import api from 'src/services/api';

import styles from './PersonalDetailsForm.module.css';

interface PersonalDetails {
  isLocked?: boolean;
  uid?: string;
  personalDetails?: personalDetails;
}

export default function PersonalDetailsForm({
  uid,
  personalDetails,
  isLocked = false,
  ...props
}: PersonalDetails) {
  //console.log(`PersonalDetailsForm: ${uid}`);
  /////////////////////////////////////////////Functions To Change Strings///////////////////////////////////////
  // IFA Number
  function handleifaNumberChange(e: { target: { value: string } }) {
    const ifaNumberValue: string = e.target.value;
    if (uid && personalDetails?.ifaNumber !== ifaNumberValue) {
      api.updateUser(uid, {
        personalDetails: { ...personalDetails, ifaNumber: ifaNumberValue },
      });
    }
  }

  // Title
  function handleTitleChange(e: { target: { value: string } }) {
    const titleValue: string = e.target.value;
    if (uid && personalDetails?.title !== titleValue) {
      api.updateUser(uid, {
        personalDetails: { ...personalDetails, title: titleValue },
      });
    }
  }
  // Member Name
  function handleMemberNameChange(e: { target: { value: string } }) {
    const memberNameValue: string = e.target.value;
    if (uid && personalDetails?.name !== memberNameValue) {
      api.updateUser(uid, {
        personalDetails: { ...personalDetails, name: memberNameValue },
      });
    }
  }

  // Member Email
  // function handleMemberEmailChange(e: { target: { value: string } }) {
  //   const memberEmailValue: string = e.target.value;
  //   if (uid && personalDetails?.email !== memberEmailValue) {
  //     api.updateUser(uid, {
  //       personalDetails: { ...personalDetails, email: memberEmailValue },
  //     });
  //   }
  // }

  // Member Mobile
  function handleMemberMobileChange(e: { target: { value: string } }) {
    const memberMobileValue: string = e.target.value;
    if (uid && personalDetails?.mobile !== memberMobileValue) {
      api.updateUser(uid, {
        personalDetails: { ...personalDetails, mobile: memberMobileValue },
      });
    }
  }
  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Personal Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          {!isLocked && (
            <tr>
              <td className={`${styles.tableContainerName}`}>IFA Number</td>
              <td>
                <div className={`${styles.searchInputContainer}`}>
                  <input
                    className={`${styles.searchInput}`}
                    type="text"
                    name="ifaNumber"
                    value={personalDetails?.ifaNumber}
                    onChange={handleifaNumberChange}
                  />
                </div>
              </td>
            </tr>
          )}
          <tr>
            <td className={`${styles.tableContainerName}`}>Title</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="title"
                  value={personalDetails?.title}
                  onChange={handleTitleChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Name</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="memberName"
                  value={personalDetails?.name}
                  onChange={handleMemberNameChange}
                />
              </div>
            </td>
          </tr>
          {!isLocked && (
            <tr>
              <td className={`${styles.tableContainerName}`}>Email</td>
              <td>{personalDetails?.email}</td>
              {/* <td>
                <div className={`${styles.searchInputContainer}`}>
                  <input
                    className={`${styles.searchInput}`}
                    type="text"
                    name="memberEmail"
                    value={personalDetails?.email}
                    onChange={handleMemberEmailChange}
                  />
                </div>
              </td> */}
            </tr>
          )}
          <tr>
            <td className={`${styles.tableContainerName}`}>Mobile</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="memberMobile"
                  value={personalDetails?.mobile}
                  onChange={handleMemberMobileChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
