import React from 'react';

import styles from './CongressesPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import AboutBlockElement from 'src/components/IFAcomponents/AboutBlockElement/AboutBlockElement';

import congressesPageImage from './CongressesPageImage.jpg';

interface CongressesPageProps {
  isSignedIn: boolean;
}

export default function CongressesPage({ isSignedIn }: CongressesPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <div>
        <img src={congressesPageImage} alt="Congresses Page Diagram" />
      </div>
      <section className={`${styles.pageBlockContainer}`}>
        {/* <div className={`${styles.headercontainer}`}> */}
        <PageHeader pageHeader={'Congresses'} />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'An Annual Congress, hosted by one of IFAs Branches, provides an opportunity for an exchange of knowledge and experience with respect to fiscal law. These Congresses attract some 1400-2200 participants plus their accompanying persons. They afford scope for useful contacts with leading experts in the fiscal field, both as part of the official programme and in the course of private discussions.'
          }
        />
        <PageBody
          pageBody={
            'Two subjects are selected each year by the Permanent Scientific Committee. These subjects form the basis of exhaustive discussions, normally resulting in some sort of concluding statement or recommendation.'
          }
        />
        <PageBody
          pageBody={
            'The "Cahiers de Droit Fiscal International" contain IFA Branch Reports and the General Reports on these subjects.'
          }
        />
        <PageBody
          pageBody={
            'The Congress programme is supplemented by a choice of seminars, covering more technical subjects, whether international in character or focussed on the law of the jurisdiction where the Congress is being held.'
          }
        />
        {/* </div> */}
      </section>
      <section className={`${styles.aboutcontainer}`}>
        <div className={`${styles.aboutrow}`}>
          <AboutBlockElement
            aboutTitle={'IFA 2022 Berlin'}
            aboutLink={'/congress-berlin'}
          />
          <AboutBlockElement
            aboutTitle={'IFA 2023 Cancun'}
            aboutLink={'/congress-cancun'}
          />
          <AboutBlockElement
            aboutTitle={'IFA 2024 Cape Town'}
            aboutLink={'/congress-capetown'}
          />
          <AboutBlockElement
            aboutTitle={'IFA 2025 Lisbon'}
            aboutLink={'/congress-lisbon'}
          />
        </div>
        <div className={`${styles.aboutrow}`}>
          <AboutBlockElement
            aboutTitle={'IFA 2026 Melbourne'}
            aboutLink={'/congress-melbourne'}
          />
        </div>
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
