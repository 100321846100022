import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { database } from 'src/config/firebase';
import { IFACompany } from 'src/types/user';

export function getNewCompanyData(): IFACompany {
  return {
    companyDetails: { companyName: 'New Company', ifaNumber: '' },
    companyAddressDetails: {
      addressType: '',
      addressLine1: '',
      addressLine2: '',
      suburb: '',
      city: '',
      province: '',
      country: '',
    },
  };
}

export async function createNewCompany(): Promise<IFACompany> {
  const newData = getNewCompanyData();

  // Remove id property when saving
  delete newData.id;

  const document = await addDoc(collection(database, 'companies'), newData);
  return { id: document.id, ...newData };
}

export async function createNewUserCompany(
  newCompany: IFACompany
): Promise<IFACompany> {
  // Remove id property when saving
  delete newCompany.id;

  const document = await addDoc(collection(database, 'companies'), newCompany);
  return { id: document.id, ...newCompany };
}

export function updateCompany(id: string, data: Partial<IFACompany>) {
  // Remove id property when saving
  // const { id: _, ...restObject } = data;

  // Remove id property when saving
  delete data.id;

  return updateDoc(doc(database, 'companies', id), { ...data });
}

export function deleteCompany(id: string) {
  return deleteDoc(doc(database, 'companies', id));
}

export default {
  getNewCompanyData,
  createNewCompany,
  createNewUserCompany,
  updateCompany,
  deleteCompany,
};
