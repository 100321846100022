import React from 'react';

import styles from './MainTitle.module.css';

interface MainTitleProps {
  children: React.ReactNode;
  className?: string;
}

export default function MainTitle({
  children,
  className = '',
  ...props
}: MainTitleProps) {
  return (
    <h1 className={`${styles.mainTitle} ${className}`} {...props}>
      {children}
    </h1>
  );
}
