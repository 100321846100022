import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  query,
} from 'firebase/firestore';
import React from 'react';
import { database } from 'src/config/firebase';

interface CollectionResult<T> {
  error: Error | null;
  loading: boolean;
  values: T[];
}

interface FirebaseDocument {
  id?: string;
}

export const useFetchAttendees = <T extends FirebaseDocument>(
  eventId: string
): CollectionResult<T> => {
  const [data, setData] = React.useState<CollectionResult<T>>({
    error: null,
    loading: true,
    values: [],
  });

  React.useEffect(() => {
    const getFromSnapshot = async (snapShot: any) => {
      return await getDocs(snapShot);
    };

    const queryRef = query(collection(database, `events/${eventId}/attendees`));

    const eventAttendeesList = onSnapshot(
      queryRef,
      (snapShot) => {
        const values: any = [];
        snapShot.forEach((doc) => {
          values.push({ id: doc.id, ...doc.data() });
        });

        setData({
          error: null,
          loading: false,
          values: values,
        });
      },
      (error) => {
        setData({
          error,
          loading: false,
          values: [],
        });
      }
    );
    return eventAttendeesList;
  }, [eventId]);

  return data;
};

export default useFetchAttendees;
