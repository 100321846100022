import React from 'react';

import styles from '../buttons/PrimaryButton.module.css';

import BaseButtonLink from './BaseButtonLink';
import ChevronIcon from '../svg/ChevronIcon';
import { ButtonTheme } from '../../types/misc';

interface Props {
  href: string;
  children: React.ReactNode;
  theme?: ButtonTheme;
  className?: string;
  hasIcon?: boolean;
}
// TODO: review hasIcon vs hideIcon
export default function PrimaryButtonLink({
  href,
  children,
  className = '',
  hasIcon = true,
  theme = 'primary',
  ...props
}: Props) {
  return (
    <BaseButtonLink
      className={`${styles[theme]} ${styles.button} ${className}`}
      href={href}
      {...props}
    >
      {children}

      {hasIcon ? (
        <div className={styles.buttonIcon}>
          <ChevronIcon direction="right" />
        </div>
      ) : null}
    </BaseButtonLink>
  );
}
