import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';

import Navbar from 'src/components/IFAcomponents/NavBar/NavBar';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import URLS from '../../config/urls';
import PublicPage from '../../components/page/PublicPage/PublicPage';
import styles from './SetPasswordPage.module.css';
import PasswordInput from 'src/components/formControls/PasswordInput';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import {
  isValidPassword,
  isValidPasswordConfirmation,
} from 'src/utils/validation';
import MessageBar from 'src/components/IFAcomponents/MessageBar/MessageBar';
import PrimaryButtonLink from 'src/components/buttonLinks/PrimaryButtonLink';
import { auth } from 'src/config/firebase';

interface SetPasswordProps {
  isSignedIn: boolean;
}

export default function SetPassword({ isSignedIn }: SetPasswordProps) {
  const [password, setPassword] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [password1Error, setPassword1Error] = useState<string | null>();
  const [password2Error, setPassword2Error] = useState<string | null>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  if (!isSignedIn) {
    return <Navigate to={URLS.root} />;
  }

  const onSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    setIsUpdated(false);
    setIsSubmitting(true);

    // Set these seperately as PasswordInput appears to set them to undefined
    const p1Check = isValidPassword(password);
    const p2Check = isValidPasswordConfirmation(password, password2);
    setPassword1Error(p1Check);
    setPassword2Error(p2Check);

    if (p1Check || p1Check) {
      setIsSubmitting(false);
      return;
    }

    try {
      await changePassword();
      setIsUpdated(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  const changePassword = async () => {
    // console.log('hey I shouldnt be reaching here!!!');
    // setStatus('error! error!');
    const uid = auth.currentUser?.uid;
    if (!uid) return;

    try {
      const response = await fetch(URLS.api.auth.setPassword, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uid: uid,
          password: password,
        }),
      });

      if (!response.ok) {
        console.error(
          `response ${response.status} | ${response.type} | ${response.statusText}`
        );

        throw new Error('Network response was not ok');
      }
    } catch (error) {
      console.error('Verify fetch error: ', error);
    }
  };

  return (
    <PublicPage testId="sign-up-page" title="Sign up">
      <Navbar isSignedIn={isSignedIn} />
      <div className={styles.formContainer}>
        <div className={styles.afi_SignUp_wrapper}>
          <form className="afi-SignUpForm" onSubmit={onSubmit}>
            <input
              id="email"
              name="email"
              type="hidden"
              value={auth.currentUser?.email || ''}
            />

            <PasswordInput
              autoComplete="new-password"
              className="afi-SignUp-field"
              errorMessage={password1Error || undefined}
              disabled={isSubmitting}
              hasError={!!password1Error}
              name="password"
              onChange={(e) => {
                setPassword(e.currentTarget.value);
              }}
              placeholder="Create Your Password"
              value={password}
            >
              <p style={{ marginBottom: 0 }} className="fine-print">
                Mininum 8 characters
              </p>
              <p style={{ marginBottom: 0 }} className="fine-print">
                1 uppercase, 1 number
              </p>
            </PasswordInput>

            <PasswordInput
              className="afi-SignUp-field"
              errorMessage={password2Error || undefined}
              disabled={isSubmitting}
              hasError={!!password2Error}
              name="passwordConfirmed"
              onChange={(e) => {
                setPassword2(e.currentTarget.value);
              }}
              placeholder="Confirm Password"
              value={password2}
            />

            <div
              style={{
                margin: '-16px auto 24px auto',
                textAlign: 'left',
                maxWidth: 300,
              }}
            >
              <div
                style={{
                  marginTop: 8,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              ></div>
            </div>

            {!isUpdated && (
              <div className="afi-SignUp-terms">
                <PrimaryButton
                  className="afi-SignUp-btn"
                  disabled={isSubmitting}
                  onClick={onSubmit}
                >
                  {isSubmitting ? 'Submitting ...' : 'Create Password'}
                </PrimaryButton>
              </div>
            )}

            {isUpdated && (
              <>
                <MessageBar
                  messageBarType="success"
                  messageText="Your password has been successfully changed."
                  className={styles.messageBarSuccess}
                />

                <PrimaryButtonLink
                  href={URLS.root}
                  className={styles.backToHomepage_btn}
                >
                  Back to Homepage
                </PrimaryButtonLink>
              </>
            )}
          </form>
        </div>
      </div>
      <Footer isSignedIn={isSignedIn} />
    </PublicPage>
  );
}
