import React from 'react';
import { useNavigate } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { IFAUser } from 'src/types/user';

import styles from './MembersList.module.css';

type MembersListProps = {
  data: IFAUser[];
};

export default function MembersList(props: MembersListProps) {
  const memberData = props.data;
  const navigate = useNavigate();

  return (
    <div className={`${styles.membersListContainer}`}>
      <table className={`${styles.tableContainer} ${styles.isDesktop}`}>
        <tbody>
          <tr>
            <td>IFA Number</td>
            <td>Name</td>
            <td>Email</td>
            <td></td>
          </tr>
          {memberData &&
            memberData.map((member, index) => {
              return (
                <tr
                  key={`member-${index}`}
                  className={`${styles.tableRowContainer}`}
                  onClick={() => navigate(`/admin_tools/member/${member.id}`)}
                >
                  <td>{member.personalDetails?.ifaNumber}</td>
                  <td>{member.personalDetails?.name}</td>
                  <td>{member.personalDetails?.email}</td>
                  <td>
                    <div className={`${styles.actionButtons}`}>
                      <NavLink to={`/admin_tools/member/${member.id}`}>
                        Details
                      </NavLink>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <ul className={`${styles.isMobile} ${styles.list}`}>
        {memberData &&
          memberData.map((member, index) => {
            return (
              <li key={`member-${index}`} className={styles.listItem}>
                <div className={styles.dataRow}>
                  <span>IFA number:</span>
                  <span>{member.personalDetails?.ifaNumber}</span>
                </div>
                <div className={styles.dataRow}>
                  <span>Name:</span>
                  <span>{member.personalDetails?.name}</span>
                </div>
                <div className={styles.dataRow}>
                  <span>Email:</span>
                  <span>{member.personalDetails?.email}</span>
                </div>
                <div className={`${styles.actionButtons}`}>
                  <NavLink to={`/admin_tools/member/${member.id}`}>
                    Details
                  </NavLink>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
