import React from 'react';
import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import styles from './YINCommittee.module.css';
import YINCommiteeTable from 'src/components/IFAcomponents/YINCommiteeTable/YINCommiteeTable';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import { NavLink } from 'react-router-dom';
import urls from 'src/config/urls';
import SecondaryButtonLink from 'src/components/buttonLinks/SecondaryButtonLink';

interface Props {
  isSignedIn: boolean;
}

const YINCommittee = ({ isSignedIn }: Props) => {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <PageHeader pageHeader={'YIN Committee'} />

        <PageBody pageBody={'Our YIN Committee comprises of:'} />

        <YINCommiteeTable />

        <div className={styles.buttons}>
          <SecondaryButtonLink href={urls.about_ifa.yin}>
            What is YIN?
          </SecondaryButtonLink>

          <SecondaryButtonLink href={urls.getInTouch.yinContactUs}>
            YIN Contact Us
          </SecondaryButtonLink>
        </div>
      </section>

      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
};

export default YINCommittee;
