import React, { useState } from 'react';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PersonalDetails from 'src/components/IFAcomponents/PersonalDetails/PersonalDetails';
import PersonalDetailsForm from 'src/components/IFAcomponents/PersonalDetailsForm/PersonalDetailsForm';
import AddressDetails from 'src/components/IFAcomponents/AddressDetails/AddressDetails';
import AddressDetailsForm from 'src/components/IFAcomponents/AddressDetailsForm/AddressDetailsForm';
import TaxStatus from 'src/components/IFAcomponents/TaxStatus/TaxStatus';
import MembershipType from 'src/components/IFAcomponents/MembershipType/MembershipType';
// import MembershipTypeForm from 'src/components/IFAcomponents/MembershipTypeForm/MembershipTypeForm';
import TaxStatusForm from 'src/components/IFAcomponents/TaxStatusForm/TaxStatusForm';
// import api from 'src/services/api';
// import Loading from '../loading/Loading';
import { useNavigate } from 'react-router-dom';
import { IFAUser } from 'src/types/user';
import styles from './MyMembershipPage.module.css';

interface MyMembershipPageProps {
  isSignedIn: boolean;
  user?: IFAUser;
}

export default function MemberDetailsPage({
  isSignedIn,
  user,
}: MyMembershipPageProps) {
  const [edit, setEdit] = useState(false);

  const id = user?.id;

  const navigate = useNavigate();

  const handleEdit = () => setEdit(!edit);

  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={styles.pageBlockContainer}>
        <div className={styles.memberNameContainer}>
          <PageHeader pageHeader={user?.personalDetails?.name || ''} />
        </div>
        <div className={styles.buttonsContainer}>
          {!edit && (
            <>
              <button className={styles.buttonContainer} onClick={handleEdit}>
                Edit
              </button>
              <button
                className={styles.buttonContainer}
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </>
          )}
          {edit && (
            <button className={styles.buttonContainer} onClick={handleEdit}>
              Done
            </button>
          )}
        </div>

        <div className={styles.detailsContainer}>
          {!edit && (
            <PersonalDetails uid={id} personalDetails={user?.personalDetails} />
          )}
          {edit && (
            <PersonalDetailsForm
              uid={id}
              personalDetails={user?.personalDetails}
              isLocked={true}
            />
          )}
          {!edit && (
            <MembershipType
              uid={id}
              membershipTypeDetails={user?.membershipTypeDetails}
            />
          )}
          {/* {edit && (
            <MembershipType
              uid={id}
              membershipTypeDetails={user?.membershipTypeDetails}
            />
          )} */}
          {!edit && (
            <AddressDetails uid={id} addressDetails={user?.addressDetails} />
          )}
          {edit && (
            <AddressDetailsForm
              uid={id}
              addressDetails={user?.addressDetails}
            />
          )}
          {!edit && (
            <TaxStatus uid={id} taxStatusDetails={user?.taxStatusDetails} />
          )}
          {edit && (
            <TaxStatusForm uid={id} taxStatusDetails={user?.taxStatusDetails} />
          )}
        </div>
      </section>
    </>
  );
}
