import React from 'react';

import styles from './PageList.module.css';

interface ListData {
  pageListItem?: string;
}

type ListProps = {
  data: ListData[];
};

export default function PageList(props: ListProps) {
  const listData = props.data;
  return (
    <div className={`${styles.pagebodycontainer}`}>
      <ol type="i">
        {listData &&
          listData.map((d, index) => {
            return (
              <li key={index}>
                <p>{d.pageListItem}</p>
              </li>
            );
          })}
      </ol>
    </div>
  );
}
