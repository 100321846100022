import React from 'react';

import styles from './MessageBar.module.css';

interface MessageBarData {
  messageBarType?: 'error' | 'success' | 'warning' | 'info' | 'none';
  messageText?: string;
  className?: string;
}

export default function MessageBar({
  messageBarType = 'error',
  messageText = '',
  className = '',
  ...props
}: MessageBarData) {
  const messageBarTheme =
    messageBarType === 'error'
      ? styles.messageBarRed
      : messageBarType === 'success'
        ? styles.messageBarGreen
        : messageBarType === 'warning'
          ? styles.messageBarOrange
          : messageBarType === 'info'
            ? styles.messageBarGrey
            : messageBarType === 'none'
              ? styles.messageBarTransparent
              : styles.messageBarTest;
  return (
    <div className={`${className}`}>
      <div className={`${messageBarTheme}`}>
        <div className={`${styles.messageBarComponents}`}></div>
        {messageText}
      </div>
    </div>
  );
}
