import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithEmailLink as signInWithEmailLinkFirebase,
  signOut as signOutFirebase,
  updatePassword,
} from 'firebase/auth';
import { auth } from '../config/firebase';

const signUp = (user: any) =>
  createUserWithEmailAndPassword(auth, user.email, user.password);

const signIn = (user: any) =>
  signInWithEmailAndPassword(auth, user.email, user.password);

const signInWithEmailLink = (user: any, emailLink: string) =>
  signInWithEmailLinkFirebase(auth, user.email, emailLink);

const signOut = () => signOutFirebase(auth);

const setPassword = (password: string) => {
  const user = auth.currentUser;
  if (user) {
    updatePassword(user, password);
  }
};

export { signUp, signIn, signInWithEmailLink, signOut, setPassword, auth };
