import React, { useState } from 'react';
import styles from './SignInWithLink.module.css';
import { Link, useNavigate } from 'react-router-dom';

import Navbar from 'src/components/IFAcomponents/NavBar/NavBar';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import URLS from '../../config/urls';
import PublicPage from '../../components/page/PublicPage/PublicPage';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import { isValidEmail } from 'src/utils/validation';
import MessageBar from 'src/components/IFAcomponents/MessageBar/MessageBar';
import PrimaryButtonLink from 'src/components/buttonLinks/PrimaryButtonLink';
import { auth } from 'src/config/firebase';
import { useQuery } from 'src/utils/hooks';
import EmailInput from 'src/components/formControls/EmailInput';
import urls from '../../config/urls';
import { signInWithEmailLink } from 'firebase/auth';

interface Props {
  setCurrentUser: (user: object) => void;
}

export default function SignInWithLink({ setCurrentUser }: Props) {
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isComplete, setIsComplete] = useState<boolean>(false);
  // const [successMessage, setSuccessMessage] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const query = useQuery();
  const [email, setEmail] = useState(query.get('email') || '');

  if (!query.get('apiKey')) {
    navigate(urls.root);
  }

  const [errors, setErrors] = useState({
    email: '',
    password: '',
  });

  // useEffect(() => {
  //   if (email) {
  //     console.log('email');
  //   }
  // }, [email]);

  const onChange = (e: React.FormEvent<HTMLInputElement>): void => {
    if (e.currentTarget.name === 'email') {
      setEmail(e.currentTarget.value);
    }
    // setData({ ...data, [e.currentTarget.name]: e.currentTarget.value, });

    setErrors({
      ...errors,
      [e.currentTarget.name]: false,
    });
  };

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const emailErrors = isValidEmail(email);
    if (emailErrors) {
      setErrors({ ...errors, email: emailErrors });
      return;
    }

    if (email) {
      await doSignInWithEmailLink(email);
    }
  }

  async function doSignInWithEmailLink(email: string) {
    setIsSubmitting(true);

    signInWithEmailLink(auth, email, window.location.href)
      .then((result) => {
        // Clear email from storage.
        // window.localStorage.removeItem('emailForSignIn');
        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser

        // const isSignedIn = auth.currentUser !== null;
        // console.log('isSignedIn', isSignedIn);
        // console.log('auth.currentUser', auth.currentUser);
        // console.log('result.user', result.user);
        // console.log('credential', result.credential);
        setIsSubmitting(false);
        setIsComplete(true);

        // window.setTimeout(() => {
        //   history.push(URLS.auth.setPassword);
        // }, 2000);
      })
      .catch((error: Error) => {
        setIsSubmitting(false);
        console.log(error);
        // Some error occurred, you can inspect the code: error.code
        // Common errors could be invalid email and invalid or expired OTPs.
        setErrorMessage(`${error}`);
      });
  }

  return (
    <PublicPage testId="sign-in-page" title="Welcome Back!">
      <Navbar />
      <div className={styles.formContainer}>
        <div className={styles.afi_wrapper}>
          {/* <h1>Signing in with Email Link</h1> */}
          <br />
          <form className={styles.afi_form} onSubmit={handleSubmit}>
            <EmailInput
              className={styles.afi_field}
              errorMessage={errors.email}
              disabled={isSubmitting}
              hasError={!!errors.email}
              name="email"
              onChange={onChange}
              placeholder="Confirm Email"
              value={email}
            />

            {errorMessage && (
              <MessageBar
                messageBarType="error"
                messageText={errorMessage}
                className={styles.messageBarError}
              />
            )}

            {!isComplete && (
              <PrimaryButton
                className={styles.afi_btn}
                data-testid="signin-btn"
                disabled={isSubmitting || isComplete}
                type="submit"
              >
                {isSubmitting ? 'Logging in...' : 'Log In With Email Link'}
              </PrimaryButton>
            )}
          </form>

          {isComplete && (
            <>
              <MessageBar
                messageBarType="success"
                messageText="You are logged in"
                className={styles.messageBarSuccess}
              />

              {/* <PrimaryButtonLink
                href={URLS.root}
                className={styles.backToHomepage_btn}
              >
                Back to Homepage
              </PrimaryButtonLink> */}

              <p className="fine-print">
                <span className={styles.afi_password_remembered}>
                  To make things easier, now set your own password.
                </span>
              </p>

              <PrimaryButtonLink
                href={URLS.auth.setPassword}
                className={styles.backToHomepage_btn}
              >
                Choose Password
              </PrimaryButtonLink>
            </>
          )}

          {/* <Link
            className="afi-SignIn-forgotPassword large-text"
            href={URLS.auth.forgotPassword}
            to={URLS.auth.forgotPassword}
          >
            Forgot Password?
          </Link> */}

          {!isComplete && (
            <p className="fine-print">
              <span className={styles.afi_password_remembered}>
                Remember your password?
              </span>
              <Link to={URLS.auth.signIn}>Go back to login</Link>.
            </p>
          )}
        </div>
      </div>
      <Footer isSignedIn={false} />
    </PublicPage>
  );
}
