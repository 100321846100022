import React, { useState } from 'react';

import styles from './CalendarPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import CalendarList from 'src/components/IFAcomponents/CalendarList/CalendarList';
import EventBlock from 'src/components/IFAcomponents/EventBlock/EventBlock';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';

import { IFAEvent } from 'src/types/user';
import useFetchCollection from 'src/services/useFetchCollection';
import dateUtils from 'src/utils/dateUtils';
import congressCapeTownPicture from 'src/assets/img/CapeTownCongress/Cape-Town@500.300.jpeg';

interface CalendarPageProps {
  isSignedIn: boolean;
}

export default function CalendarPage({ isSignedIn }: CalendarPageProps) {
  const {
    values: events,
    loading,
    error,
  } = useFetchCollection<IFAEvent>('events');

  const showPastEvents = false;
  const [filter, setFilter] = useState<'all' | 'Congress' | 'Webinar'>('all');

  const eventsFiltered = events
    .filter((event) => {
      return showPastEvents || dateUtils.isEventInCurrentMonthOrFuture(event);
    })
    .filter((event) => filter === 'all' || event.type === filter)
    .sort((a, b) => {
      const b_date = dateUtils.getDateForEvent(b);
      const a_date = dateUtils.getDateForEvent(a);
      if (!a_date) return 1;
      if (!b_date) return -1;
      return a_date < b_date ? -1 : a_date > b_date ? 1 : 0;
    });

  function filterShowAll() {
    setFilter('all');
  }

  function filterToCongress() {
    setFilter('Congress');
  }

  function filterToWebinar() {
    setFilter('Webinar');
  }

  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <div className={`${styles.calendarHeaderContainer}`}>
          <h1>Calendar</h1>
          <div className={`${styles.calendarHeaderButtonsContainer}`}>
            <div className={`${styles.calendarHeaderButtonContainer}`}>
              <button
                className={`${styles.calendarHeaderButton}`}
                onClick={filterShowAll}
              >
                All Events
              </button>
            </div>
            <div className={`${styles.calendarHeaderButtonContainer}`}>
              <button
                className={`${styles.calendarHeaderButton}`}
                onClick={filterToWebinar}
              >
                Webinars
              </button>
            </div>
            <div className={`${styles.calendarHeaderButtonContainer}`}>
              <button
                className={`${styles.calendarHeaderButton}`}
                onClick={filterToCongress}
              >
                Congresses
              </button>
            </div>
          </div>
        </div>
        <EventBlock
          startDate={'27 October'}
          endDate={'31 October'}
          eventYear={'2024'}
          eventName={'IFA 2024 Cape Town Congress'}
          eventLocation={'Cape Town, South Africa'}
          moreInfo={'https://ifa2024capetown.com'}
          eventImage={congressCapeTownPicture}
          isLinkExternal={true}
        />
        <CalendarList data={eventsFiltered} />
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
