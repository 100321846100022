import React from 'react';

import styles from './EventSpeakerOne.module.css';

import { speaker } from 'src/types/user';
// import { storage } from 'src/config/firebase';

// import EventSpeakerOne from 'src/components/IFAcomponents/EventSpeakerOne/EventSpeakerOne.jpeg';

interface EventSpeakerOneData {
  eventTheme?: 'dark' | 'light' | 'grey';
  speaker?: speaker;
}

export default function EventSpeakerOne({
  eventTheme = 'light',
  speaker,
  ...props
}: EventSpeakerOneData) {
  const componentTheme =
    eventTheme === 'grey'
      ? styles.eventSpeakerContainerGrey
      : eventTheme === 'light'
        ? styles.eventSpeakerContainerLight
        : styles.eventSpeakerContainerDark;

  // const [image, setImage] = useState();

  // useEffect(() => {
  //   const doIt = async () => {
  //     if (!speaker?.image) return;
  //     const ref = storage.ref(speaker?.image);
  //     const downloadUrl = await ref.getDownloadURL();
  //     setImage(downloadUrl);
  //   };
  //   doIt();
  // }, [speaker?.image]);

  return (
    <div className={`${componentTheme}`}>
      <div className={`${styles.eventSpeakerDetailContainer}`}>
        {/* <div className={`${styles.eventSpeakerImageContainer}`}>
          <img src={EventSpeakerOne} alt="Event Speaker Diagram" />
        </div> */}
        {/* {image && (
          <div className={`${styles.eventSpeakerImageContainer}`}>
            <img src={image} alt="Event Speaker Diagram" />
          </div>
        )} */}
        <div className={`${styles.eventSpeakerInfoContainer}`}>
          <h3>{speaker?.name}</h3>
          <h4>{speaker?.occupation}</h4>
          <h5>{speaker?.topic}</h5>
        </div>
      </div>
      <hr></hr>
      <p>{speaker?.bio}</p>
    </div>
  );
}
