import React from 'react';

import styles from './SeminarsPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import PageBullet from 'src/components/IFAcomponents/PageBullet/PageBullet';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';

interface SeminarsPageProps {
  isSignedIn: boolean;
}

const bullData = [
  {
    pageBullet: '2019 - Prof. Allison Christians',
  },
  {
    pageBullet: '2018 - Prof. Jonathan Schwarz',
  },
  {
    pageBullet: '2017 - Prof. Dr Luís Schoueri',
  },
  {
    pageBullet: '2016 - Prof. Dr Frans Vanistendael',
  },
  {
    pageBullet: '2013 - Prof. Dr Richard Vann',
  },
  {
    pageBullet: '2012 - Prof. Dr Phillip Baker',
  },
  {
    pageBullet: '2011 - Prof. Jonathan Schwarz',
  },
  {
    pageBullet: '2010 - Prof. Dr Kees van Raad',
  },
];

export default function SeminarsPage({ isSignedIn }: SeminarsPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        {/* <div className={`${styles.headercontainer}`}> */}
        <PageHeader pageHeader={'Seminars'} />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'IFA-South Africa hosts regular (generally annually) full-day seminars, in both Cape Town and Johannesburg, presented by a leading international tax expert each year.  Recent years have enjoyed the following speakers:'
          }
        />
        <PageBullet data={bullData} />
        <PageBody
          pageBody={
            'Full Day Seminars are dependent on speaker availability. Discounted rates for attendance are available for registered members in good standing.'
          }
        />
        {/* </div> */}
      </section>
      <div>
        <CallToAction />
      </div>
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
