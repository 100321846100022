import React from 'react';

import styles from './AwardsPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import AboutBlockElement from 'src/components/IFAcomponents/AboutBlockElement/AboutBlockElement';
import awardsPicture from './AwardsImage.jpeg';

interface AwardsPageProps {
  isSignedIn: boolean;
}

export default function AwardsPage({ isSignedIn }: AwardsPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <div>
        <img src={awardsPicture} alt="Awards Page Diagram" />
      </div>
      <section className={`${styles.pageBlockContainer}`}>
        <PageHeader pageHeader={'Research & Awards'} />
        <PageBody
          pageBody={
            'IFA supports a number of research projects and awards that all vary in topic and focus. '
          }
        />
      </section>
      <section className={`${styles.aboutcontainer}`}>
        <div className={`${styles.aboutrow}`}>
          <AboutBlockElement
            aboutTitle={'Maurice Lauré Prize'}
            aboutLink={'/research_awards/maurice_lauré_prize'}
          />
          <AboutBlockElement
            aboutTitle={'Mitchell B. Carroll Prize'}
            aboutLink={'/research_awards/mitchell_b_carroll_prize'}
          />
          <AboutBlockElement
            aboutTitle={'IFA President YIN Scientific Award'}
            aboutLink={'/research_awards/ifa_president_yin_scientific_award'}
          />
          <AboutBlockElement
            aboutTitle={'Poster Programme'}
            aboutLink={'/research_awards/poster_programme'}
          />
        </div>
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
