import React from 'react';
import { companyDetails } from 'src/types/user';

import styles from './CompanyDetails.module.css';

interface CompanyDetails {
  uid?: string;
  companyDetails?: companyDetails;
}

export default function CompanyDetails({
  uid,
  companyDetails,
  ...props
}: CompanyDetails) {
  return (
    <div className={`${styles.detailsContainer}`}>
      <h2>Company Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td>IFA Number</td>
            <td>{companyDetails?.ifaNumber}</td>
          </tr>
          <tr>
            <td>Company Name</td>
            <td>{companyDetails?.companyName}</td>
          </tr>
          <tr>
            <td>VAT Number</td>
            <td>{companyDetails?.vatNumber}</td>
          </tr>
          {/* <tr>
            <td>Membership Type</td>
            <td>{membershipType}</td>
          </tr>
          <tr>
            <td>Address</td>
            <td>{companyAddress}</td>
          </tr> */}
        </tbody>
      </table>
    </div>
  );
}
