import React from 'react';

import styles from './PosterProgrammePage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';

interface PosterProgrammePageProps {
  isSignedIn: boolean;
}

export default function PosterProgrammePage({
  isSignedIn,
}: PosterProgrammePageProps) {
  // });
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <PageHeader pageHeader={'Poster Programme'} />
        <p>
          In 1993 IFA initiated a so-called "Poster Programme" to promote young
          students to study and discuss international taxation. This initiative
          aims at introducing young people to the IFA network. Therefore, IFA
          expects them to become acquainted with the organisation and procedures
          of the Association, so that they will be able to take an active part
          in IFA's work in the future.
        </p>
        <p>
          <strong>Subject: </strong>International fiscal law, comparative tax
          law, or local tax law having an important relation with fiscal law in
          foreign jurisdictions.
        </p>
        <p>
          <strong>Language: </strong>English, French and German. English
          preferred.
        </p>
        <p>
          <strong>Open to: </strong>A maximum of ten students graduating in
          international taxation who are writing a thesis on a theoretical or
          practical subject.
        </p>
        <p>
          <strong>Conditions:</strong>
          <ul>
            <li>Submission of a presentation letter on the topic;</li>
            <li>Submission of a resume including the date of birth;</li>
            <li>
              Upon submission, candidates need additionally to prepare an
              article on the topic in English with a maximum of 10.000 words.
            </li>
          </ul>
        </p>
        <p>
          <strong>Prize: </strong> Euro 5.000, plus a medal and an invitation to
          attend the IFA 2021 Congress in Berlin, exempted from the payment of
          the Congress registration fee, during which the Prize is awarded.
        </p>
        <p>
          <strong>Submission deadline: </strong>1 April 2021. Papers entered for
          the Prize for a given year should be submitted as a soft copy (PDF) by
          e-mail to a.gensecr@ifa.nl before the 1st of April.
        </p>
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <Footer isSignedIn={isSignedIn} />
    </>
  );
}
