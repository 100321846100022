import React from 'react';

import styles from './PageHeader.module.css';

interface HeaderData {
  pageHeader?: string;
}

export default function PageHeader({ pageHeader = '', ...props }: HeaderData) {
  return (
    <div className={`${styles.pageheadercontainer}`}>
      <h1>{pageHeader}</h1>
    </div>
  );
}
