import React from 'react';

import styles from './Event20240425.module.css';

interface Props {
  id?: string;
}

export default function Event20240425(props: Props) {
  const isVisible = (props.id || '').trim() === 'PW7iTaIyBP2rat2moEzq';
  if (!isVisible) return <></>;

  return (
    <div className={`${styles.root}`}>
      <h1>YOU ARE INVITED</h1>
      <p>
        To attend the IFA South Africa Online Webinar taking place on Thursday,
        25 April 2024, 12:00 – 13:30 (CAT).
      </p>
      <p>
        Topic: The concept of “Good faith in domestic and international tax law”
        as well as the landmark case of Coronation Investment Management SA v
        the Commissioner of SARS.
      </p>
      <p>
        Please use the following{' '}
        <a href="https://us02web.zoom.us/webinar/register/WN_8YqlK4j2RsiH58qGEkzGCA">
          link
        </a>{' '}
        should you wish to register for the Webinar. <br />
        For more details, please see the program attached.
      </p>
    </div>
  );
}
