import { isDate, format as formatDate } from 'date-fns';
import { CURRENCIES } from '../content';

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function sortArrayBy(
  array: Array<any>,
  sortBy: Array<{ prop: string; direction: number }>
) {
  // https://bithacker.dev/javascript-object-multi-property-sort
  // array should be an array type,
  // sortBy should be an array of objects of structure
  if (!sortBy) {
    return array;
  }

  const sorted = array.sort((a, b) => {
    let i = 0,
      result = 0;
    while (i < sortBy.length && result === 0) {
      result =
        sortBy[i].direction *
        (a[sortBy[i].prop]?.toString() < b[sortBy[i].prop]?.toString()
          ? -1
          : a[sortBy[i].prop]?.toString() > b[sortBy[i].prop]?.toString()
            ? 1
            : 0);
      i++;
    }
    return result;
  });
  return sorted;
}

export function sanitizeString(str: string) {
  const SCRIPT_REGEX = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
  while (SCRIPT_REGEX.test(str)) {
    str = str.replace(SCRIPT_REGEX, '');
  }
  return str;
}

export function classnames(classes: any) {
  const isArray = Array.isArray(classes);

  if (typeof classes === 'object') {
    if (isArray) {
      return classes.filter((c: any) => !!c).join(' ');
    }

    const _classNames = Object.keys(classes);

    return _classNames
      .map((className) => (classes[className] ? className : ''))
      .filter((c) => !!c)
      .join(' ');
  }

  throw Error(
    'The classNames function only accepts an object or array currently.'
  );
}

export const moneyWithCurrencyCode = (
  amount: number,
  currencyCode: string
) => `${new Intl.NumberFormat([], {
  style: 'currency',
  currency: currencyCode,
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
}).format(amount)}
${currencyCode}`;

export const money = (amount: number, currencyCode: string) => {
  if (currencyCode) {
    const currencySymbol = moneySymbol(currencyCode);

    //Polyfill for formatToParts (older browsers):
    if (!Intl.NumberFormat.prototype.formatToParts) {
      if (amount >= 0) {
        return `${currencySymbol}${Math.round(amount).toLocaleString('en')}`;
      } else {
        return `-${currencySymbol}${Math.abs(Math.round(amount)).toLocaleString(
          'en'
        )}`;
      }
    }

    return new Intl.NumberFormat([], {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .formatToParts(amount)
      .filter((part) => part.type !== 'literal')
      .reduce((result, part) => {
        if (part.type === 'currency') {
          return result + currencySymbol;
        }

        return result + part.value;
      }, '');
  }

  return `$${amount}`;
};

export function moneySymbol(currencyCode: string) {
  const currencySymbol = CURRENCIES.filter(
    (currency) => currency.code === currencyCode
  ).reduce((_symbol, currency) => currency.symbol, '$');

  return currencySymbol ? currencySymbol : '$';
}

export function negMoney(amount: number, currencyCode: string) {
  return amount ? money(-amount, currencyCode) : money(0, currencyCode);
}

const stringToNumber = (string: string) => Number(string);

const splitYearAndMonth = (yearMonth: string) =>
  yearMonth.split('_').map(stringToNumber);

const hasItemHistory = (itemHistory: object) =>
  itemHistory && Object.keys(itemHistory).length > 0;

const percentage = (currentValue: number, totalValue: number) =>
  (Number(currentValue) / Number(totalValue)) * 100;

const getPercentage = (currentValue: number, totalValue: number) =>
  Math.round(percentage(currentValue, totalValue));

export function group(data: any) {
  return {
    by: (objectKey: any) =>
      data.reduce((groupObject: any, item: any) => {
        (groupObject[item[objectKey]] =
          groupObject[item[objectKey]] || []).push(item);

        return groupObject;
      }, {}),
  };
}

const Number2Decimals = (number: any) => Number(Number(number).toFixed(2));

const getTotalAmount = (total: number, item: any) =>
  total + Number(item.amount);

const calculateTotal = (total: number, number: number) =>
  Number(total) + Number(number);

// TODO: to be replaced by similar function
function getYearMonthCombo(date: Date) {
  if (isDate(date)) {
    const dateYear = date.getFullYear();
    const dateMonth = date.getMonth() + 1;

    return `${dateYear}_${dateMonth}`;
  } else {
    console.error('Invalid getYearMonthCombo date');
    return '';
  }
}

function getTodaysDate() {
  const currentTimestamp = Date.now();

  return new Date(currentTimestamp);
}

function getFormatedMonthObject(date: Date) {
  if (isDate(date)) {
    return {
      month: formatDate(date, 'MMMM'),
      shortMonth: formatDate(date, 'MMM'),
      year: formatDate(date, 'yyyy'),
      yearMonth: getYearMonthCombo(date),
    };
  } else {
    console.error('Invalid date');
    return null;
  }
}

function getNextMonthDate(currentMonth: Date) {
  return new Date(currentMonth.setMonth(currentMonth.getMonth() + 1));
}

export function getDateForMonthsInPast(
  todaysDate: Date,
  numberOfMonthsInPast: number
) {
  const monthsInPastTimestamp = todaysDate.setMonth(
    todaysDate.getMonth() - numberOfMonthsInPast
  );

  return new Date(monthsInPastTimestamp);
}

export function getPreviousYearMonthCombos(
  todaysDate: Date,
  numberOfMonthsInPast: number
) {
  const monthsInPast = [];
  const invalidDate = !isDate(todaysDate);

  if (invalidDate) {
    return [];
  }

  const startDateForMonthsInPast = getDateForMonthsInPast(
    todaysDate,
    numberOfMonthsInPast
  );

  monthsInPast.push(getFormatedMonthObject(startDateForMonthsInPast));

  while (numberOfMonthsInPast > 0) {
    const nextMonth = getFormatedMonthObject(
      getNextMonthDate(startDateForMonthsInPast)
    );

    monthsInPast.push(nextMonth);

    numberOfMonthsInPast--;
  }

  return monthsInPast.reverse();
}

function getYearMonthCombosForPast12Months(todaysDate: Date) {
  return getPreviousYearMonthCombos(todaysDate, 11).map((month) =>
    month && month.yearMonth ? month.yearMonth : ''
  );
}

// function getLast12MonthsChartData(items: Array<any>, todaysDate: Date) {
//   const emptyResult = {
//     months: [],
//     totals: [],
//   };

//   const months = getPreviousYearMonthCombos(todaysDate, 11).reverse();
//   const startingMonthFor12MonthPeriod = months
//     .slice(0, 1)
//     .reduce((yearMonth, month) => {
//       if (month && month.yearMonth) {
//         return month.yearMonth;
//       }
//       return yearMonth;
//     }, '');

//   return months.reduce(
//     (monthsAndTotals: { months: Array<any>; totals: Array<any> }, month) => {
//       const yearMonth = month && month.yearMonth ? month.yearMonth : '';
//       const shortMonth = month && month.shortMonth ? month.shortMonth : '';
//       const totalForMonth = getMonthlyTotalForYearMonth(
//         items,
//         yearMonth,
//         startingMonthFor12MonthPeriod
//       );

//       return {
//         months: monthsAndTotals.months.concat(shortMonth),
//         totals: monthsAndTotals.totals.concat(totalForMonth),
//       };
//     },
//     emptyResult
//   );
// }

function getClosestSavedYearMonth(
  itemHistory: any,
  yearMonth: string,
  startYearMonthFor12MonthPeriod: string
) {
  const isYearMonthIn12MonthPeriod = (yearMonthInHistory: any) => {
    const [year, month] = splitYearAndMonth(yearMonth);
    const [historyYear, historyMonth] = splitYearAndMonth(yearMonthInHistory);
    const [startYear, startMonth] = splitYearAndMonth(
      startYearMonthFor12MonthPeriod
    );
    const isSameYear = historyYear === year;
    const isPreviousYear = historyYear < year;
    const isValidYear = historyYear >= startYear;
    const isValidMonthInSameYear = historyMonth <= month;
    const isValidMonthInPreviousYear = historyMonth > startMonth;

    if (isValidYear) {
      if (isSameYear && isValidMonthInSameYear) {
        return true;
      }

      if (isPreviousYear && isValidMonthInPreviousYear) {
        return true;
      }
    }

    return false;
  };

  if (hasItemHistory(itemHistory)) {
    const mostRecentYearMonthInHistory = Object.keys(itemHistory)
      .filter(isYearMonthIn12MonthPeriod)
      .sort(sortMostRecentToOldestYearMonths)
      .slice(0, 1);

    return mostRecentYearMonthInHistory[0] || null;
  }

  return null;
}

function sortMostRecentToOldestYearMonths(
  yearMonth: string,
  nextYearMonth: string
) {
  const [year, month] = splitYearAndMonth(yearMonth);
  const [nextItemYear, nextItemMonth] = splitYearAndMonth(nextYearMonth);

  const SORT_CURRENT_BEFORE = -1;
  const SORT_NEXT_BEFORE = 1;
  const isSameYear = year === nextItemYear;
  const isNextMonthMostRecent = month < nextItemMonth;
  const isCurrentMonthMostRecent = month > nextItemMonth;

  if ((isSameYear && isNextMonthMostRecent) || year < nextItemYear) {
    return SORT_NEXT_BEFORE;
  }

  if (nextItemYear < year || (isSameYear && isCurrentMonthMostRecent)) {
    return SORT_CURRENT_BEFORE;
  }

  return 0;
}

// export function getMonthlyTotalForYearMonth(
//   items: Array<Expense>,
//   yearMonth: string,
//   startingYearMonthFor12MonthPeriod: string
// ) {
//   return items.reduce(calculateTotalForTheMonth, 0);

//   function calculateTotalForTheMonth(total: number, item: Expense) {
//     const { history, frequency = 1 } = item;
//     const getTotal = (itemAmount: number) => {
//       const monthlyItemAmount = Math.round(itemAmount / frequency);

//       return total + monthlyItemAmount;
//     };

//     if (hasItemHistory(history)) {
//       const closestSavedYearMonth = getClosestSavedYearMonth(
//         history,
//         yearMonth,
//         startingYearMonthFor12MonthPeriod
//       );

//       if (closestSavedYearMonth) {
//         const itemAmount = Number(history[closestSavedYearMonth]);

//         return getTotal(itemAmount);
//       } else {
//         return total;
//       }
//     }

//     if (!hasItemHistory(history)) {
//       const itemAmount = Number(item.amount);

//       return getTotal(itemAmount);
//     }

//     return total;
//   }
// }

// function get12MonthsChartDataEveryThird(items: any) {
//   const isThirdItem = (item: any, index: number, arr: any) =>
//     index === 0 || index === arr.length - 1 || index % 3 === 0;
//   const last12MonthsItems = getLast12MonthsChartData(items, getTodaysDate());

//   return {
//     totals: last12MonthsItems.totals.filter(isThirdItem),
//     months: last12MonthsItems.months.filter(isThirdItem),
//   };
// }

function getQueryParams(queryString: string): { [key: string]: string } {
  const searchParams = new URLSearchParams(queryString);
  const parsedParams: { [key: string]: string } = {};

  for (const param of searchParams) {
    const [key, value] = param;
    parsedParams[key] = value;
  }

  return parsedParams;
}

function noop() {}

export function getCurrencyName(currencyCode: string) {
  return CURRENCIES.filter((currency) => currency.code === currencyCode).reduce(
    (_name, currency) => currency.name,
    ''
  );
}

export function getFutureYears(
  currentYear: number,
  numberOfYearsInFuture: number
) {
  if (numberOfYearsInFuture > 0) {
    return Array.from({ length: numberOfYearsInFuture }, (_curr, index) => {
      const nextYear = currentYear + 1;

      return nextYear + index;
    });
  }

  return [];
}

export function shortId() {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  const firstPart = (Math.random() * 46656) | 0;
  const secondPart = (Math.random() * 46656) | 0;
  const firstPartMore = ('000' + firstPart.toString(36)).slice(-3);
  const secondPartMore = ('000' + secondPart.toString(36)).slice(-3);

  return firstPartMore + secondPartMore;
}

export {
  getQueryParams,
  getTotalAmount,
  getPercentage,
  getYearMonthCombo,
  getYearMonthCombosForPast12Months,
  getTodaysDate,
  getNextMonthDate,
  calculateTotal,
  getFormatedMonthObject,
  getClosestSavedYearMonth,
  sortMostRecentToOldestYearMonths,
  noop,
  Number2Decimals,
  percentage,
};
