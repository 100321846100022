import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { ArrowToRight } from 'src/components/svg';

import styles from './Calendar.module.css';

import { IFAEvent } from 'src/types/user';

// interface CalendarData {
//   date?: string;
//   month?: string;
//   year?: string;
//   name?: string;
//   place?: string;
//   link?: string;
// }

// type CalendarProps = {
//   data: CalendarData[];
// };

type CalendarProps = {
  data: IFAEvent[];
};

export default function Calendar(props: CalendarProps) {
  const eventData = props.data;
  const navigate = useNavigate();
  return (
    <div className={styles.calendarPanel}>
      <h2>Calendar</h2>
      <table>
        <tbody>
          {eventData &&
            eventData.map((event, index) => {
              return (
                <tr
                  key={`cal-${index}`}
                  className={styles.calendarRow}
                  onClick={() => {
                    if (event.isExternal) {
                      window.open(event.link);
                    } else {
                      navigate(`/events/event/${event.id}`);
                    }
                  }}
                >
                  <td>
                    <div className={styles.calendarBlock}>
                      <span className={styles.calendarDate}>{event.date}</span>
                      <span className={styles.calendarMonth}>
                        {' '}
                        {event.month} <br />
                        {event.year}{' '}
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className={styles.calendarDetail}>
                      <span className={styles.calendarTitle}>{event.name}</span>
                      <br />
                      <span className={styles.calendarPlace}>
                        {event.place}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className={styles.btnprimary}>
        <NavLink to="/calendar" className={styles.btnprimarytext}>
          View Calendar
          <span className={styles.btnprimaryicon}>
            <ArrowToRight />
          </span>
        </NavLink>
      </div>
    </div>
  );
}
