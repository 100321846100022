import React from 'react';
import BaseInput, { InputProps } from './BaseInput';

interface EmailInputProps extends InputProps {
  type?: any;
}

const EmailInput = ({
  children,
  type,
  ...props
}: EmailInputProps): JSX.Element => (
  <BaseInput type="email" {...props}>
    {children}
  </BaseInput>
);

export default EmailInput;
