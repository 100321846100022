import urls from 'src/config/urls';

export const verifyRecaptcha = async (token: string) => {
  try {
    const response = await fetch(`${urls.api.recaptcha.verify}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: token }),
    });
    if (response.ok) {
      const result = await response.json();
      return result;
    } else if (!response.ok) {
      console.error(
        `response ${response.status} | ${response.type} | ${response.statusText}`
      );

      throw new Error(
        'Failed to verify token for recaptcha. Check your internet connection and try again.'
      );
    }
  } catch (error) {
    console.error('VerifyRecaptcha error: ', error);
  }
};
