import React from 'react';
import BaseInput, { InputProps } from './BaseInput';

interface PasswordInputProps extends InputProps {
  type?: any;
}

const PasswordInput = ({
  children,
  type,
  ...props
}: PasswordInputProps): JSX.Element => (
  <BaseInput type="password" {...props}>
    {children}
  </BaseInput>
);

export default PasswordInput;
