import React from 'react';

import styles from './EventMaterials.module.css';

import { downloads } from 'src/types/user';

import apiResources from 'src/services/apiResources';

import EventDocument from 'src/components/IFAcomponents/EventDocument/EventDocument';
// import DocumentPicture from './Document.png';

interface EventMaterialsData {
  eventTheme?: 'dark' | 'light' | 'grey';
  downloads: downloads;
}

export default function EventMaterials({
  eventTheme = 'dark',
  downloads,
  ...props
}: EventMaterialsData) {
  const { folder: downloadsFolder } = apiResources.useFiles(
    downloads.downloadsPath
  );

  const componentTheme =
    eventTheme === 'grey'
      ? styles.eventDetailsContainerGrey
      : eventTheme === 'light'
        ? styles.eventDetailsContainerLight
        : styles.eventDetailsContainerDark;
  return (
    <div className={`${componentTheme}`}>
      <h2>Event Materials</h2>
      <div className={styles.materialsContainer}>
        {downloads?.videoPath && (
          <div className={styles.videoContainer} title="Event Video">
            <iframe
              src={downloads?.videoPath}
              width="100%"
              height="300"
              title="Event Video"
            ></iframe>
          </div>
        )}
        {downloadsFolder && (
          <div className={styles.seminarFilesContainer}>
            {downloadsFolder?.files &&
              downloadsFolder?.files.map((download, index) => {
                return (
                  <EventDocument
                    key={`document-${index}`}
                    eventFile={download}
                  />
                );
              })}
          </div>
        )}
      </div>

      <hr></hr>
    </div>
  );
}
