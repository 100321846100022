import React, { useState } from 'react';

import styles from './StyleGuide.module.css';

import Figure from '../../components/ui/Figure';
import ProgressBar from '../../components/ui/ProgressBar';
import TabButtons from '../../components/ui/TabButtons';

function ContentComponents() {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div>
      <section>
        <div className={styles.component}>
          <h2>Tab Buttons</h2>
          <p>
            {/* <pre className={styles.inlineCode}>
              {'<'}TabButtons activeTab=0 switchTabs=() => (); tabs=[&#123;
              label: 'Tab 1' &#125;, &#123; label: 'Tab 2' &#125;] {'/>'}
            </pre> */}
          </p>
          <div className={styles.componentContainer}>
            <TabButtons
              activeTab={activeTab}
              switchTabs={(n) => setActiveTab(n)}
              tabs={[{ label: 'Tab 1' }, { label: 'Tab 2' }]}
            />
          </div>
        </div>

        <div className={styles.component}>
          <h2>Progress Bar</h2>
          <p>
            <span className={styles.inlineCode}>
              &lt;ProgressBar percentage={40} /&gt;
            </span>
          </p>
          <div className={styles.componentContainer}>
            <ProgressBar percentage={40} />
          </div>
        </div>

        <div className={styles.component}>
          <h2>Figure</h2>
          <p>
            The &lt;Figure /&gt; is used to prominently display a{' '}
            <span className={styles.inlineCode}>value</span>, optionally with a{' '}
            <span className={styles.inlineCode}>caption</span>.
          </p>
          <p>
            It is one of many themed components in the app, thus requires a{' '}
            <span className={styles.inlineCode}>theme</span> prop.
          </p>
          <div className={styles.componentContainer}>
            <Figure caption="Figure caption" theme="plan" value="$1,000,000" />
          </div>
        </div>
      </section>
    </div>
  );
}

export default ContentComponents;
