import React from 'react';
import { Context, DefaultState, InitialState } from './context';

interface ContextProviderProps {
  children?: React.ReactNode;
}

const ContextProvider = ({ children }: ContextProviderProps) => {
  const [state] = React.useState<DefaultState>(InitialState);

  return <Context.Provider value={state}>{children}</Context.Provider>;
};

export const useStateContext = (): DefaultState => {
  const context = React.useContext(Context);
  if (context === undefined) {
    throw new Error('useStateContext must be used within a ContextProvider');
  }
  return context;
};

export default ContextProvider;
