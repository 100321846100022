import React from 'react';
import { IconProps } from '../../types/misc';

export default function ArrowToRight({ className = '', ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27px"
      height="10px"
      viewBox="0 0 27 10"
      className={className}
      {...props}
    >
      <polygon
        // fill="#031D8A"
        fill="currentColor"
        fillRule="evenodd"
        points="128.95 13.017 132.234 16.282 108 16.282 108 17.718 132.234 17.718 128.95 20.983 129.973 22 135 17 129.973 12"
        transform="translate(-108 -12)"
      ></polygon>
    </svg>
  );
}
