import React from 'react';

import styles from './SeminarFile.module.css';

import { FirebaseFile } from 'src/services/apiResources';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import apiResources from 'src/services/apiResources';

import DocumentPicture from './Document.png';

interface SeminarFileData {
  seminarFile: FirebaseFile;
  fileIndentLevel?: number;
}

export default function SeminarFile({
  seminarFile,
  fileIndentLevel = 0,
  ...props
}: SeminarFileData) {
  const fileIndentSpace =
    fileIndentLevel === 0
      ? styles.imageContainerZero
      : fileIndentLevel === 1
        ? styles.imageContainerOne
        : fileIndentLevel === 2
          ? styles.imageContainerTwo
          : fileIndentLevel === 3
            ? styles.imageContainerThree
            : styles.imageContainerFour;

  return (
    <div className={styles.seminarFileContainer}>
      <span>
        <img
          src={DocumentPicture}
          className={`${fileIndentSpace}`}
          alt="Document Icon"
        />
      </span>
      <p>{seminarFile?.name}</p>
      <div className={`${styles.downloadContainer}`}>
        <PrimaryButton
          className={`${styles.downloadButtonContainer}`}
          onClick={() => {
            apiResources.download(seminarFile.fullPath);
          }}
        >
          Download
        </PrimaryButton>
      </div>
    </div>
  );
}
