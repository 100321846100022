import React from 'react';
import { IconProps } from '../../types/misc';

export default function InfoIcon({ className = '', ...props }: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 28.974 29.6"
      className={className}
      {...props}
    >
      <defs />
      <path
        className="a"
        d="M21.255 1.686a14.5 14.5 0 00-6.75-1.687 14.5 14.5 0 00-14.506 14.5 14.5 14.5 0 004.113 10.122l-.606 4.31a.572.572 0 00.468.66.57.57 0 00.413-.088l3.293-2.184a14.5 14.5 0 0019.6-6.029 14.5 14.5 0 00-6.025-19.604zm2.757 22.2a13.361 13.361 0 01-9.507 3.979 13.368 13.368 0 01-6.568-1.715.572.572 0 00-.572 0l-2.578 1.675.5-3.321a.572.572 0 00-.166-.486 13.36 13.36 0 01-.132-18.893 13.36 13.36 0 0118.893-.132 13.36 13.36 0 01.13 18.897z"
      />
      <path
        className="a"
        d="M17.781 19.637h-.475v-8.449a.572.572 0 00-.572-.572H11.8a.572.572 0 00-.572.572v2.058a.572.572 0 00.572.572h.48v5.82h-.48a.572.572 0 00-.572.571v2.058a.572.572 0 00.572.572h5.98a.572.572 0 00.572-.572v-2.058a.572.572 0 00-.571-.572zm-5.408 2.058v-.915h.48a.572.572 0 00.572-.572v-6.963a.572.572 0 00-.572-.571h-.48v-.915h3.785v8.449a.572.572 0 00.572.572h.475l.006.915zM14.506 5.031a2.4 2.4 0 00-2.4 2.4 2.4 2.4 0 002.4 2.4 2.4 2.4 0 002.4-2.4 2.4 2.4 0 00-2.4-2.4zm0 3.653a1.252 1.252 0 01-1.252-1.252A1.252 1.252 0 0114.5 6.175h.006v.005a1.252 1.252 0 011.252 1.252 1.252 1.252 0 01-1.252 1.252z"
      />
    </svg>
  );
}
