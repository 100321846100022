import React from 'react';

import styles from './EventSpeakerTwo.module.css';

import EventSpeakerTwo from 'src/components/IFAcomponents/EventSpeakerTwo/EventSpeakerTwo.jpeg';

interface EventSpeakerTwoData {
  eventTheme?: 'dark' | 'light' | 'grey';
  speakerName?: string;
  speakerTitle?: string;
  speakerTopic?: string;
  speakerBio?: string;
}

export default function EventSpeaker({
  eventTheme = 'light',
  speakerName = '',
  speakerTitle = '',
  speakerTopic = '',
  speakerBio = '',
  ...props
}: EventSpeakerTwoData) {
  const componentTheme =
    eventTheme === 'grey'
      ? styles.eventSpeakerContainerGrey
      : eventTheme === 'light'
        ? styles.eventSpeakerContainerLight
        : styles.eventSpeakerContainerDark;
  return (
    <div className={`${componentTheme}`}>
      <div className={`${styles.eventSpeakerDetailContainer}`}>
        {/* <div className={`${styles.eventSpeakerImageContainer}`}> */}
        <img src={EventSpeakerTwo} alt="Event Speaker Diagram" />
        {/* </div> */}
        <div className={`${styles.eventSpeakerInfoContainer}`}>
          <h3>{speakerName}</h3>
          <h4>{speakerTitle}</h4>
          <h5>{speakerTopic}</h5>
          <p>{speakerBio}</p>
        </div>
      </div>
    </div>
  );
}
