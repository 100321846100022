import React from 'react';

import styles from './CompanySelectorList.module.css';

import { IFACompany } from 'src/types/user';
import { membershipTypeDetails } from 'src/types/user';
import api from 'src/services/api';

type CompanySelectorListProps = {
  uid: string;
  membershipTypeDetails?: membershipTypeDetails;
  data: IFACompany[];
};

export default function CompanySelectorList(props: CompanySelectorListProps) {
  const companyData = props.data;
  const uid = props.uid;
  const membershipTypeDetails = props.membershipTypeDetails;

  // const companyValue: string = companyData.companyDetails?.companyName || ''

  // if (uid && membershipTypeDetails?.company !== company.companyDetails?.companyName || '') {
  //     api.updateUser(uid, {
  //         membershipTypeDetails: {
  //             ...membershipTypeDetails,
  //             company: companyValue,
  //         },
  //     });
  // }

  return (
    <div>
      {companyData &&
        companyData.map((company, index) => {
          return (
            <div
              key={`company-${index}`}
              className={styles.companySelectorButton}
              onClick={() => {
                if (uid && membershipTypeDetails?.companyId !== company?.id) {
                  api.updateUser(uid, {
                    membershipTypeDetails: {
                      ...membershipTypeDetails,
                      company: company?.companyDetails.companyName,
                      companyId: company?.id,
                    },
                  });
                }
              }}
            >
              <span className={styles.companySelectorButtonText}>
                {company.companyDetails?.companyName}
              </span>
            </div>
          );
        })}
    </div>
  );
}
