import React from 'react';

import styles from './CongressBerlinPage.module.css';

import congressBerlinPagePicture from './CongressBerlinImage.jpeg';

// import congressBerlinPageLogo from './CongressBerlinLogo.jpeg';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import EventDetails from 'src/components/IFAcomponents/EventDetails/EventDetails';
import EventDetailsBlock from 'src/components/IFAcomponents/EventDetailsBlock/EventDetailsBlock';
import ScientificProgramme from 'src/components/IFAcomponents/scientificProgramme/ScientificProgramme';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';

interface CongressBerlinPageProps {
  isSignedIn: boolean;
}

export default function CongressBerlinPage({
  isSignedIn,
}: CongressBerlinPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <div>
        <img
          src={congressBerlinPagePicture}
          alt="Congress Berlin Page Diagram"
        />
      </div>
      <section className={`${styles.pageBlockContainer}`}>
        <div className={`${styles.containerColumnOne}`}>
          <EventDetails
            congress={'IFA 2022 Berlin'}
            // congressDetails={
            //   'With great pleasure we welcome you to the 74th Congress of IFA in Berlin , Germany in the fall of 2022. More information on the scientific programme can be found below. The programme will provide participants an excellent forum to exchange and debate, meet and interact with friends, colleagues, sponsors and exhibitors. More information on the full programme and the dates of the Congress will follow soon!'
            // }
            locationDetails={
              'The International Fiscal Association (South Africa) invites you to the first of a two-part webminar series.'
            }
          />
        </div>
        <div className={`${styles.containerColumnTwo}`}>
          {/* <img src={congressBerlinPageLogo} alt="Congress Berlin Page Logo" />
          <EventDetailsBlock
            congress={'IFA 2023 Cancun'}
            congressDates={'September 10-14 2023'}
            congressLocation={'Cancun, Mexico'}
          /> */}
          <EventDetailsBlock
            congress={'IFA 2022 Berlin'}
            congressDates={'Unconfirmed'}
            congressLocation={'Berlin, Germany'}
          />
        </div>
      </section>
      <section className={`${styles.scientificBlockContainer}`}>
        <ScientificProgramme
          mainSubjectOne={
            'Group approach and separate entity appraoch in domestic and international tax law'
          }
          mainSubjectOneReporters={
            'Johanna Hey (Germany) and Arne Schnitger (Germany)'
          }
          mainSubjectTwo={
            'Big data and tax - dometic and international taxation of data driven business'
          }
          mainSubjectTwoReporters={'Gary Sprague (USA)'}
        />
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
