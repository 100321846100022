import React, { useState } from 'react';

import { useParams } from 'react-router-dom';

import styles from './CreateNewAttendeePage.module.css';

import ifaPicture from '../../components/IFAcomponents/NavBar/logo-ifasa.svg';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';

import apiAttendees from 'src/services/apiAttendees';

import URLS from 'src/config/urls';

interface CreateNewAttendeePageProps {
  isSignedIn: boolean;
}

export default function CreateNewAttendeePage({
  isSignedIn,
  ...props
}: CreateNewAttendeePageProps) {
  const { id } = useParams<{ id: string }>();

  // Setting Up Input States

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [number, setNumber] = useState('');
  const [ifaNumber] = useState('');
  const [isMember, setIsMember] = useState<boolean>();

  // Updating Input States

  function handleNameChange(e: { target: { value: string } }) {
    const nameValue: string = e.target.value;
    setName(nameValue);
    //console.log(name);
  }

  function handleEmailChange(e: { target: { value: string } }) {
    const emailValue: string = e.target.value;
    setEmail(emailValue);
    //console.log(email);
  }

  function handleNumberChange(e: { target: { value: string } }) {
    const numberValue: string = e.target.value;
    setNumber(numberValue);
    //console.log(number);
  }

  function selectMembershipStatus(e: React.ChangeEvent<HTMLSelectElement>) {
    const membershipStatusValue: string = e.target.value;
    if (membershipStatusValue === 'Member') {
      setIsMember(true);
    } else {
      setIsMember(false);
    }
  }

  // Submitting Registration form

  async function submitRegistrationForm(
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();

    if (id) {
      try {
        // Creating New Attendee Object
        const newAttendee = apiAttendees.getNewAttendeeData();

        // Assigning Values To New Attendee Object
        newAttendee.name = name;
        newAttendee.email = email;
        newAttendee.ifaNumber = ifaNumber;
        newAttendee.number = number;
        newAttendee.isMember = isMember;

        // Creating The New Attendee In The Database
        /* eslint-disable  @typescript-eslint/no-unused-vars */
        const newSubmission = await apiAttendees.createNewAttendee(
          id,
          newAttendee
        );

        // Sending Registration Email To Attendee
        await fetch(URLS.api.registrationEmail, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            to: newAttendee.email,
            name: newAttendee.name,
          }),
        });

        // Sending Registration Alert To Admin
        await fetch(URLS.api.eventRegistrationAlert, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: newAttendee.email,
            displayName: newAttendee.name,
          }),
        });

        alert();
      } catch (error) {
        console.error(error);
      }
    }
  }

  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section>
        <div className={styles.registrationContainer}>
          <form
            className={styles.registrationForm}
            onSubmit={submitRegistrationForm}
          >
            <PageHeader pageHeader={'Registration'} />
            <div>
              <img
                className={styles.ifaLogoImage}
                src={ifaPicture}
                alt="IFA Logo"
              ></img>
            </div>
            <label>Full Name</label>
            <input
              className={`${styles.searchInput}`}
              type="text"
              name="name"
              value={name}
              onChange={handleNameChange}
            />
            <label>Email</label>
            <input
              className={`${styles.searchInput}`}
              type="text"
              name="email"
              value={email}
              onChange={handleEmailChange}
            />
            <label>Phone Number</label>
            <input
              className={`${styles.searchInput}`}
              type="text"
              name="number"
              value={number}
              onChange={handleNumberChange}
            />
            <label>Member</label>
            <div className={`${styles.searchInput}`}>
              <select
                onChange={selectMembershipStatus}
                className={`${styles.addressTypeInputContainer}`}
              >
                <option selected disabled>
                  Please select their membership status
                </option>
                <option value="Member">Member</option>
                <option value="Non Member">Non Member</option>
              </select>
            </div>

            <button type="submit" className={`${styles.submitButton}`}>
              Submit
            </button>
          </form>
        </div>
      </section>
    </>
  );
}
