import React from 'react';
import { classnames } from '../../utils/universal_utils';

import styles from './ProgressBar.module.css';

interface Props {
  percentage: number;
  colorClass?: string;
  className?: string;
  direction?: string;
  height?: 'short' | 'tall';
  styleClass?: string;
  label?: string;
}

export default function ProgressBar({
  percentage,
  className = '',
  colorClass = '',
  direction = 'ltr',
  height = 'tall',
  styleClass = '',
  label = '',
  ...props
}: Props) {
  const barClassNames = classnames([styles.bar, className]);
  const indicatorClassNames = classnames([
    styles.progressIndicator,
    styles[height],
    styleClass,
    colorClass,
    direction === 'rtl' && 'is-rtl',
  ]);
  return (
    <div>
      <div
        className={barClassNames}
        {...props}
        role="progressbar"
        aria-valuenow={percentage}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-valuetext={`${percentage}%`}
      >
        <div
          className={indicatorClassNames}
          style={{ width: `${percentage}%` }}
        />
      </div>
      {label && <p className={styles.label}>{label}</p>}
    </div>
  );
}
