import React from 'react';
import { companyDetails } from 'src/types/user';
import apiCompany from 'src/services/apiCompany';

import styles from './CompanyDetailsForm.module.css';

interface CompanyDetailsFormProps {
  uid?: string;
  companyDetails?: companyDetails;
}

export default function CompanyDetailsForm({
  uid,
  companyDetails,
  ...props
}: CompanyDetailsFormProps) {
  console.log(`CompanyDetailsForm: ${uid}`);
  /////////////////////////////////////////////Functions To Change Strings///////////////////////////////////////
  // IFA Number
  function handleifaNumberChange(e: { target: { value: string } }) {
    const value: string = e.target.value;
    if (uid && companyDetails?.ifaNumber !== value) {
      apiCompany.updateCompany(uid, {
        companyDetails: { ...companyDetails, ifaNumber: e.target.value },
      });
    }
  }

  // Company Name
  function handleNameChange(e: { target: { value: string } }) {
    const value: string = e.target.value;
    if (uid && companyDetails?.companyName !== value) {
      apiCompany.updateCompany(uid, {
        companyDetails: { ...companyDetails, companyName: value },
      });
    }
  }

  // VAT Number
  function handleVATNumberChange(e: { target: { value: string } }) {
    const value: string = e.target.value;
    if (uid && companyDetails?.vatNumber !== value) {
      apiCompany.updateCompany(uid, {
        companyDetails: { ...companyDetails, vatNumber: value },
      });
    }
  }

  return (
    <div className={`${styles.detailsContainer}`}>
      <h2>Company Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>IFA Number</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="ifaNumber"
                  value={companyDetails?.ifaNumber}
                  onChange={handleifaNumberChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Company Name</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="companyName"
                  value={companyDetails?.companyName}
                  onChange={handleNameChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>VAT Number</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="vatNumber"
                  value={companyDetails?.vatNumber}
                  onChange={handleVATNumberChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
