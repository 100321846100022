import React from 'react';

import styles from './PageSubheader.module.css';

interface SubheaderData {
  pageSubheader?: string;
}

export default function PageSubheader({
  pageSubheader = '',
  ...props
}: SubheaderData) {
  return (
    <div className={`${styles.pagesubheadercontainer}`}>
      <h1>{pageSubheader}</h1>
    </div>
  );
}
