import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import styles from './AuthStyles.module.css';
import PublicPage from 'src/components/page/PublicPage/PublicPage';
import PasswordInput from 'src/components/formControls/PasswordInput';
import ErrorText from 'src/components/form/ErrorText';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import api from 'src/services/api';
import URLS from 'src/config/urls';
import { useStateObject } from 'src/utils/hooks';
import {
  isValidPassword,
  isValidPasswordConfirmation,
  hasErrors,
} from 'src/utils/validation';
import { auth } from 'src/config/firebase';
import {
  confirmPasswordReset,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from 'firebase/auth';

type PasswordResetStatus =
  | 'idle'
  | 'valid-reset-code'
  | 'invalid-reset-code'
  | 'saving-new-password'
  | 'password-reset-succesfully'
  | 'could-not-save-password';

export function ResetPasswordPage({ actionCode }: { actionCode: string }) {
  const navigate = useNavigate();
  const [status, setStatus] = React.useState<PasswordResetStatus>('idle');
  const [state, setState] = useStateObject({
    verifiedEmail: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useStateObject({
    newPassword: '',
    confirmPassword: '',
  });

  React.useEffect(() => {
    async function verifyFirebasePasswordResetCode(actionCode: string) {
      try {
        const verifiedEmail = await verifyPasswordResetCode(auth, actionCode);

        setStatus('valid-reset-code');
        setState({ ...state, verifiedEmail: verifiedEmail });
      } catch (error) {
        console.error('invalid reset code', error);
        setStatus('invalid-reset-code');
      }
    }

    verifyFirebasePasswordResetCode(actionCode);
    // eslint-disable-next-line
  }, [actionCode]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;

    setState({
      [name]: value,
    });

    setErrors({
      [name]: false,
    });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const errors = {
      newPassword: isValidPassword(state.newPassword),
      confirmPassword: isValidPasswordConfirmation(
        state.confirmPassword,
        state.newPassword
      ),
    };

    if (hasErrors(errors)) {
      setErrors({ ...errors });
      return;
    }

    try {
      await confirmPasswordReset(auth, actionCode, state.newPassword);

      setStatus('password-reset-succesfully');
      signInWithEmailAndPassword(auth, state.verifiedEmail, state.newPassword)
        .then(() => {
          setTimeout(() => {
            navigate(URLS.root);
          }, 3000);
        })
        .catch((error) => {
          console.error('signInWithEmailAndPassword: ', error);
        });
    } catch (error) {
      console.error(error);

      setStatus('could-not-save-password');
    }
  };

  return (
    <PublicPage testId="reset-password-page">
      <article className={styles.main}>
        {status === 'invalid-reset-code' ? (
          <div>
            <h1>Try resetting your password again</h1>

            <p>
              Your request to reset your password has expired or the link has
              already been used
            </p>
          </div>
        ) : (
          <>
            <div>
              <h1>Reset your password</h1>
              <p>for</p>
              <p>{state.verifiedEmail}</p>
              <br />
            </div>

            <form onSubmit={onSubmit} className={styles.resetPasswordForm}>
              <PasswordInput
                autoComplete="new-password"
                errorMessage={errors.newPassword}
                disabled={status === 'saving-new-password'}
                hasError={errors.newPassword}
                name="newPassword"
                onChange={onChange}
                placeholder="Enter your new Password"
                value={state.newPassword}
              >
                <p className="fine-print">
                  Mininum 8 characters, 1 uppercase, 1 number
                </p>
              </PasswordInput>

              <PasswordInput
                errorMessage={errors.confirmPassword}
                disabled={status === 'saving-new-password'}
                hasError={errors.confirmPassword}
                name="confirmPassword"
                onChange={onChange}
                placeholder="Confirm Password"
                value={state.confirmPassword}
              />

              <ErrorText isVisible={status === 'could-not-save-password'}>
                We could not reset your password. Try resetting your password
                again.
              </ErrorText>

              {status === 'password-reset-succesfully' && (
                <p>
                  Your password was reset. We will redirect you to the AfireFi
                  app.
                </p>
              )}

              <PrimaryButton
                disabled={status === 'saving-new-password'}
                type="submit"
              >
                {status === 'saving-new-password' ? 'Saving...' : 'Submit'}
              </PrimaryButton>
            </form>
          </>
        )}
      </article>
    </PublicPage>
  );
}
