import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import MessageBar from 'src/components/IFAcomponents/MessageBar/MessageBar';
import styles from './CreateNewUserPage.module.css';
import URLS from 'src/config/urls';
import api from 'src/services/api';

interface CreateNewUserPageProps {
  isSignedIn: boolean;
}

export default function CreateNewUserPage({
  isSignedIn,
}: CreateNewUserPageProps) {
  const navigate = useNavigate();
  const [fullNameString, setFullNameString] = useState('');
  const [emailString, setEmailString] = useState('');
  // const [passwordString, setPasswordString] = useState('');
  const [isCreating, setIsCreating] = useState<boolean>(false);
  const [infoText, setInfoText] = useState<string>();
  const [errorText, setErrorText] = useState<string>();

  function handleFullNameStringChange(e: { target: { value: string } }) {
    const fullNameValue: string = e.target.value;
    setFullNameString(fullNameValue);
  }

  function handleEmailStringChange(e: { target: { value: string } }) {
    const emailValue: string = e.target.value;
    setEmailString(emailValue);
  }

  // function handlePasswordStringChange(e: { target: { value: string } }) {
  //   const passwordValue: string = e.target.value;
  //   setPasswordString(passwordValue);
  // }

  async function handleCreateButtonClick() {
    setIsCreating(true);
    setInfoText('');
    setErrorText('');

    if (emailString.length < 3) {
      setErrorText('Please enter a valid Email address');
      return;
    }

    // if (passwordString.length < 6) {
    //   setErrorText('Please enter a password at least 5 characters long');
    //   return;
    // }

    try {
      const response = await fetch(URLS.api.admin.userCreate, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: emailString,
          fullName: fullNameString,
          // password: passwordString,
        }),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      // setInfoText(`User ${emailString} created`);
      const uid = await response.text();
      console.log(`User created: ${uid}`);
      setInfoText('Created');

      // No longer needed because this is set in functions
      // However it does work a bit faster if we set this here also.
      const newUser = api.getNewUserData();
      newUser.personalDetails = {
        ...newUser.personalDetails,
        email: emailString,
        name: fullNameString,
      };
      await api.adminCreateNewUser(uid, newUser);
      navigate(`${URLS.admin_tools.member}/${uid}`);
    } catch (error) {
      if (error instanceof Error) {
        setErrorText(`Error creating new user: ${error.message}`);
      } else {
        setErrorText(`Error creating new user: ${error}`);
      }
    } finally {
      setIsCreating(false);
    }
  }

  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={styles.backContainer}>
        <NavLink to={URLS.admin_tools.members}>Back to Members</NavLink>
      </section>
      <section className={`${styles.pageBlockContainer}`}>
        <div className={`${styles.newUserForm}`}>
          <table className={`${styles.tableContainer}`}>
            <tbody>
              <tr>
                <td>
                  <div className={`${styles.formTitleContainer}`}>
                    <h2>New IFA Member</h2>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={`${styles.searchInputContainer}`}>
                    <label>Full name</label>
                    <input
                      className={`${styles.searchInput}`}
                      type="text"
                      name="fullName"
                      value={fullNameString}
                      onChange={handleFullNameStringChange}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className={`${styles.searchInputContainer}`}>
                    <label>Email</label>
                    <input
                      className={`${styles.searchInput}`}
                      type="text"
                      name="search"
                      value={emailString}
                      onChange={handleEmailStringChange}
                    />
                  </div>
                </td>
              </tr>
              {/* <tr>
                <td>
                  <div className={`${styles.searchInputContainer}`}>
                    <label>Password</label>
                    <input
                      className={`${styles.searchInput}`}
                      type="password"
                      name="search"
                      value={passwordString}
                      onChange={handlePasswordStringChange}
                    />
                  </div>
                </td>
              </tr> */}
              {infoText && (
                <tr>
                  <td>
                    <MessageBar
                      messageBarType="success"
                      messageText={infoText}
                    />
                  </td>
                </tr>
              )}
              {errorText && (
                <tr>
                  <td>
                    <MessageBar
                      messageBarType="error"
                      messageText={errorText}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <div className={`${styles.buttonsContainer}`}>
                    <button
                      className={`${styles.buttonContainer}`}
                      onClick={handleCreateButtonClick}
                      disabled={isCreating}
                    >
                      Create User
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
