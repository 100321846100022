import React from 'react';

import styles from './MembershipPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageSubheader from 'src/components/IFAcomponents/PageSubheader/PageSubheader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import Footer from 'src/components/IFAcomponents/Footer/Footer';

import memebershipPageImage from './MembershipPageImage.png';
import PageBullet from 'src/components/IFAcomponents/PageBullet/PageBullet';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';

interface MembershipPageProps {
  isSignedIn: boolean;
}

const bullData = [
  {
    pageBullet:
      'Member of both the International Association and the South African Branch on the same fee.',
  },
  {
    pageBullet:
      'Member of IFAs world-wide membership providing opportunities for networking globally.',
  },
  {
    pageBullet: 'Eligible to attend the Annual IFA Congress at member rate.',
  },
  {
    pageBullet:
      'Two Cahiers, published once a year, containing the preparatory material for the subjects to be discussed at the following Congress.',
  },
  {
    pageBullet:
      'Discounted registration fee for the Annual Seminar presented by the South African Branch of IFA featuring an international tax expert.',
  },
  {
    pageBullet:
      'Online access to Seminar Materials of all IFA South Africa Branch Seminars (Delegates at the Seminar receive a complimentary printed copy).',
  },
];

export default function MembershipPage({ isSignedIn }: MembershipPageProps) {
  // console.log('Run update status function');
  // console.log(uid);
  // api.updateUserStatus(uid, 'new-user-unsubscribed').catch((error) => {
  //   // TODO: display error to user
  //   console.error('updateUserStatus ERROR:', error);
  // });
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <div>
        <img src={memebershipPageImage} alt="Membership Page Diagram" />
      </div>
      <section className={`${styles.pageBlockContainer}`}>
        {/* <div className={`${styles.headercontainer}`}> */}
        <PageHeader pageHeader={'Membership'} />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'The International Fiscal Association (IFA) is the only non-governmental and non-sectoral international organization dealing with fiscal matters. It comprises taxpayers, their advisers, government officials, members of the judiciary and university professors and is therefore a unique forum for discussing international fiscal questions. '
          }
        />
        {/* </div> */}
        {/* <div className={`${styles.subheadercontainer}`}> */}
        <PageSubheader
          pageSubheader={
            'International Fiscal Association: Should you be a member ?'
          }
        />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'The International Fiscal Association brings together some 12,500 practitioners, tax officials and academics from over 116 member jurisdications, involved in and concerned with taxation of international transactions and the study of comparative tax systems. Based in Rotterdam, IFA, since its inception in the 1930s has provided, in conjunction with the activities of each IFA branch, a blend of comprehensive research of international taxation and the development of programs and initiatives of current practical import.'
          }
        />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'The South African Branch of IFA  participates both directly and indirectly in the activities of IFA, culminating in the IFA Annual Congress. The South African Branch also conducts seminars on international taxation topics and developments.'
          }
        />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'The Annual Congress of IFA, held between August and October, features the study of two major topics (see Annual IFA Congress menu for a list of upcoming topics) as well as seminars on other topics. Each of the two major topics entail the preparation of "IFA Branch Reports", a "General Report" (synthesizing and analyzing the trends and developments seen in the IFA Branch Reports), and a full-day discussion, normally resulting in some sort of concluding statement or recommendation. All reports are then bound and published. Members of the South African Branch have been very active in this regard and there is a South African member on the select Permanent Scientific committee that has responsibility for IFAs programs.'
          }
        />
        {/* </div> */}
        {/* <div className={`${styles.bodycontainer}`}> */}
        <PageBody
          pageBody={
            'South Africans who have an interest or involvement in international taxation may join the South African Branch of IFA (and thus become members of IFA ) for an annual fee. Members receive the two annual Reports and timely information on forthcoming activities of both IFA Central and the South African Branch.'
          }
        />
        {/* </div> */}
        {/* <div className={`${styles.subheadercontainer}`}> */}
        <PageSubheader pageSubheader={'Benefits of Membership'} />
        <PageBullet data={bullData} />
        {/* </div> */}
        {/* <div className={`${styles.subheadercontainer}`}> */}
        <PageSubheader pageSubheader={'Types of Members'} />
        <PageBody pageBody={'Regular Individual Member'} />
        <PageBody
          pageBody={
            'Young Individual Member (under 30 years of age on January 1 of the membership year)'
          }
        />
        <PageBody
          pageBody={
            'Corporate Member (limited to one membership per corporate entity)'
          }
        />
        {/* </div> */}
        {/* <div className={`${styles.subheadercontainer}`}> */}
        <PageSubheader pageSubheader={'Corporate Membership'} />
        <PageBody
          pageBody={
            'Corporate membership governs all members that are not natural persons.  In accordance with the rules of Central IFA, corporate membership is restricted to one membership per corporate entity. In addition, corporate membership only provides membership benefits to two natural person nominees of the corporate member.  Additional individual memberships may be taken by those persons employed by a corporate member and linked to their employer. '
          }
        />
        {/* </div> */}
        {/* <div className={`${styles.subheadercontainer}`}> */}
        {/* </div> */}
        {/* <div className={`${styles.subheadercontainer}`}> */}
        <PageSubheader pageSubheader={'2024 Annual Membership Fees'} />
        <PageBody
          pageBody={
            <>
              <u>Regular Individual Member</u> - <b>R2,450</b> (comprising both
              the amount payable to Central IFA of EUR 70 for worldwide
              membership and the balance to the branch for its activities)
            </>
          }
        />
        <PageBody
          pageBody={
            <>
              <u>Young Individual Member</u> (under 30 years of age on January 1
              of the membership year) - <b>R1,225</b> (comprising both the
              amount payable to Central IFA of EUR 35 for worldwide membership
              and the balance to the branch for its activities)
            </>
          }
        />
        <PageBody
          pageBody={
            <>
              <u>Corporate Member</u> (generally limited to one membership per
              corporate entity) - <b>R6,600</b> (comprising both the amount
              payable to Central IFA of EUR 160 for worldwide membership and the
              balance to the branch for its activities)
            </>
          }
        />
        {/* </div> */}
        {/* <div className={`${styles.subheadercontainer}`}> */}
        <PageSubheader pageSubheader={'Membership Terms'} />
        <PageBody
          pageBody={
            'Membership follows a calendar year (i.e. 1 January to 31 December).  Membership is automatically renewed unless cancelled by 31 January (in writing) of the membership year.  Failure to cancel timeously renders the member liable for the annual dues.'
          }
        />
        {/* </div> */}
      </section>
      <div>
        {!isSignedIn && (
          <div>
            <CallToAction />
          </div>
        )}
      </div>
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
