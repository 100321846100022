import firebase, { database, storage } from '../config/firebase';
import { IFACompany, IFAUser } from 'src/types/user';
import { ref } from 'firebase/storage';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentSnapshot,
  FirestoreError,
  getDoc,
  onSnapshot,
  QuerySnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';

export type DatabaseCollection =
  | 'members'
  | 'users'
  | 'companies'
  | 'events'
  | 'resources';

export type DatabaseDocument = IFAUser | IFACompany;

export const storageRef = (path: string) => ref(storage, path);
export const userDoc = (uid: string) => doc(database, 'users', uid);
export const userSubCollection = (
  uid: string,
  collectionName: DatabaseCollection
) => collection(userDoc(uid), collectionName);

export function addDocToCollection(
  uid: string,
  data: DatabaseDocument,
  collectionName: DatabaseCollection
) {
  /**
   * Remove docId so it is not saved in the database document.
   * The docId is passed into the app when we get data from firestore but it is not saved in the document itself
   **/
  const { id: _, ...dataWithoutId } = data;

  return addDoc(userSubCollection(uid, collectionName), {
    ...dataWithoutId,
    created: serverTimestamp(),
  }).then((doc) => doc.id);
}

export function updateDocInCollection(
  uid: string,
  docId: string,
  collectionName: DatabaseCollection,
  data: Partial<DatabaseDocument>
) {
  /**
   * Remove docId so it is not saved in the database document.
   * The docId is passed into the app when we get data from firestore but it is not saved in the document itself
   **/

  const { id: _, ...dataWithoutId } = data;

  const subDocRef = doc(database, 'users', uid, collectionName, docId);

  return updateDoc(subDocRef, {
    ...dataWithoutId,
    updated: serverTimestamp(),
  }).then(() => docId);
}

export function deleteDocInCollection(
  uid: string,
  docId: string,
  collectionName: DatabaseCollection
) {
  const subDocRef = doc(database, 'users', uid, collectionName, docId);
  return deleteDoc(subDocRef);
}

export function getAllDocsInCollection(snapshot: QuerySnapshot) {
  return snapshot.docs.map((document: DocumentSnapshot) => ({
    ...document.data(),
    docId: document.id,
  }));
}

export function getCollectionDocuments(
  uid: string,
  collectionName: DatabaseCollection,
  getData: (data: any) => void
) {
  return onSnapshot(
    collection(userDoc(uid), collectionName),
    (collectionSnapshot) => {
      const docsWithId = collectionSnapshot.docs.map((document) => ({
        ...document.data(),
        docId: document.id,
      }));

      // Call Callback:
      getData({ [collectionName]: docsWithId });
    },
    (error: Error) => {
      const fe = error as FirestoreError;
      if (fe && fe.code === 'permission-denied') {
        // user has logged out - connection closed gracefully
      } else {
        throw error;
      }
    }
  );
}

export function getRealtimeUserData(
  uid: string,
  getData: (data: object) => void
) {
  // console.log('getRealtimeUserData', uid);
  return onSnapshot(
    userDoc(uid),
    (userSnapshot) =>
      getData({
        ...userSnapshot.data(),
      }),
    (error: Error) => {
      const fe = error as FirestoreError;
      if (fe && fe.code === 'permission-denied') {
        // user has logged out - connection closed gracefully
      } else {
        throw error;
      }
    }
  );
}

export function getUserData(uid: string, getData: (data: object) => void) {
  console.log('getUserData', uid);
  return getDoc(userDoc(uid))
    .then((doc) => {
      getData({
        ...doc.data(),
      });
    })
    .catch((error) => {
      console.log('Error getting document:', error);
    });
}
