import React from 'react';

import styles from './EventInfo.module.css';

interface EventInfoData {
  eventTheme?: 'dark' | 'light';
  eventInfo?: string;
}

export default function EventInfo({
  eventTheme = 'light',
  eventInfo = '',
  ...props
}: EventInfoData) {
  const componentTheme =
    eventTheme === 'light'
      ? styles.pageheaderContainerLight
      : styles.pageheaderContainerDark;
  return (
    <div className={`${componentTheme}`}>
      <span>{eventInfo}</span>
    </div>
  );
}
