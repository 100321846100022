import React from 'react';

interface Props {
  className?: string;
  onClick?: () => void;
}

export default function TrashCanIcon({ className, ...props }: Props) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22.483 23"
      {...props}
    >
      <path
        d="M4.855 23a.9.9 0 01-.9-.822L2.486 5.448H.789A.788.788 0 010 4.659v-.592a.788.788 0 01.789-.788h6.078V1.5A1.5 1.5 0 018.368 0h5.885a1.5 1.5 0 011.5 1.5v1.779h5.939a.789.789 0 01.789.788v.592a.789.789 0 01-.789.788h-1.587l-1.472 16.732a.9.9 0 01-.9.822zm.825-1.8h11.231L18.3 5.448h-14zm8.274-17.92V1.8H8.669v1.479zM7.747 18.347l-.893-9.822a.575.575 0 01.537-.616l.59-.049a.584.584 0 01.641.517l.893 9.823a.576.576 0 01-.537.617l-.589.049a.488.488 0 01-.051 0 .583.583 0 01-.591-.519zm6.444.513l-.591-.06a.575.575 0 01-.527-.625l1.073-9.807a.586.586 0 01.65-.507l.589.059a.575.575 0 01.526.626l-1.072 9.807a.585.585 0 01-.588.509c-.017.002-.038 0-.06-.002zm-3.739-.889V8.308a.887.887 0 111.775 0v9.663a.887.887 0 11-1.775 0z"
        fill="#cc2a3c"
        opacity=".498"
      />
    </svg>
  );
}
