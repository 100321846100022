import React from 'react';

import styles from './ConstitutionPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageSubheader from 'src/components/IFAcomponents/PageSubheader/PageSubheader';
import PageBody from 'src/components/IFAcomponents/PageBody/PageBody';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import PageList from 'src/components/IFAcomponents/PageList/PageList';

interface ConstitutionPageProps {
  isSignedIn: boolean;
}

const section3partAData = [
  {
    pageListItem: 'the study of;',
  },
  {
    pageListItem: 'research into;',
  },
  {
    pageListItem: 'the development of,',
  },
];

export default function ConstitutionPage({
  isSignedIn,
}: ConstitutionPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <PageHeader
          pageHeader={
            'International Fiscal Association South Africa Constitution'
          }
        />
        <PageBody
          pageBody={
            'As amended through 27 June 1971 and re-written and approved in annual general meeting on 9 December 2015. As amended in the annual general meeting on 29 May 2020'
          }
        />
        <PageBody pageBody={'PREAMBLE'} />
        <PageBody
          pageBody={
            'Whereas a voluntary not for profit association of members was formed in 1970 and styled as the “South African Fiscal Association”.'
          }
        />
        <PageBody
          pageBody={
            'And whereas the Association has acted in accordance with a written Constitution over time and has expanded its activities to achieve its aims, it has now become incumbent to re-write but not substantially derogate from all past Constitutions to provide for matters that will ensure the Association continues to efficiently achieve its aims.'
          }
        />
        <PageBody
          pageBody={
            'Now therefore it is resolved that the Constitution of the South African Fiscal Association is replaced as follows:'
          }
        />
        <PageSubheader pageSubheader={'1. Definitions'} />
        <PageBody
          pageBody={
            '1.1.  “Association” means the International Fiscal Association South Africa as detailed in Article 2.'
          }
        />
        <PageBody
          pageBody={
            '1.1.  “Association” means the International Fiscal Association South Africa as detailed in Article 2.'
          }
        />
        <PageBody
          pageBody={
            '1.1.  “Association” means the International Fiscal Association South Africa as detailed in Article 2.'
          }
        />
        <PageBody
          pageBody={
            '1.2.  “Executive Committee” means the body of persons charged with the management of the Association as referred to in Article 8.'
          }
        />
        <PageBody
          pageBody={
            '1.3.  “Honorary Member” means as Honorary Member of the Association as referred to in Article 6.'
          }
        />
        <PageBody
          pageBody={
            '1.4.  “IFA” means the International Fiscal Association with its official seat in Rotterdam, the Netherlands and “Central IFA” bears the same meaning. '
          }
        />
        <PageBody
          pageBody={
            '1.5. “in writing” refers to communication by registered letter or by email, provided the identity of the sender can be sufficiently established.'
          }
        />
        <PageBody
          pageBody={
            '1.6. “paid-up” shall refer to members in Article 6.1(a) and 6.1(b) whose membership subscriptions are not in arrears. Honorary members are considered “paid-up” members at all times. '
          }
        />
        <PageSubheader pageSubheader={'2. Name of the Association'} />
        <PageBody
          pageBody={
            '2.1. The Association, formerly known as the “South African Fiscal Association”, is to be known as the “International Fiscal Association South Africa” (the “Association”) as from 1 August 2014.'
          }
        />
        <PageBody
          pageBody={
            '2.2. The shortened name is ”IFA South Africa” with an abbreviation as “IFA-SA”.'
          }
        />
        <PageSubheader pageSubheader={'3. Objects'} />
        <PageBody pageBody={'3.1. The objects of the Association are:'} />
        <PageBody pageBody={'(a)   to promote'} />
        <PageList data={section3partAData} />
        <PageBody
          pageBody={
            'the South African tax system as well as international and comparative tax law and related subjects;'
          }
        />
        <PageBody
          pageBody={
            '(b)   to act as the South African National branch of the International Fiscal Association (“IFA”).'
          }
        />
        <PageBody
          pageBody={
            '3.2. To achieve those objects the Association may do anything a natural person of full legal capacity could do.'
          }
        />
        <PageBody
          pageBody={
            '3.3. Without in any way detracting from the generality of (2) the Association may:'
          }
        />
      </section>
      <PageSubheader pageSubheader={'To be continued'} />
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
