import React from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { IFAUser, User } from 'src/types/user';
import urls from '../config/urls.js';
import URLS from '../config/urls.js';
import { User as FirebaseUser } from 'firebase/auth';

// General
import MainPage from 'src/pages/main/MainPage';
import Loading from 'src/pages/loading/Loading';
import Styleguide from '../pages/styleguide/Styleguide';

// About IFA Pages
import AboutPage from 'src/pages/about_ifa/AboutPage';
import ReadMorePage from 'src/pages/read_more/ReadMorePage';
import HistoryOfIFAPage from 'src/pages/historyOfIFA/HistoryOfIFAPage';
import CooperationPage from 'src/pages/cooperation/CooperationPage';
import MembershipPage from 'src/pages/membership/MembershipPage';
import YINPage from 'src/pages/yin/YINPage';
import ConstitutionPage from 'src/pages/ifa_sa_constitution/ConstitutionPage';
import BranchExecutivesPage from 'src/pages/branch_executives/BranchExecutivesPage';
import YINCommittee from 'src/pages/yin/YINCommittee';

// Congress Pages
import CongressesPage from 'src/pages/congresses/CongressesPage';
import CongressBerlinPage from 'src/pages/congressDetail/CongressBerlin/CongressBerlinPage';
import CongressCancunPage from 'src/pages/congressDetail/CongressCancun/CongressCancunPage';
import CongressCapeTownPage from 'src/pages/congressDetail/CongressCapeTown/CongressCapeTownPage';
import CongressLisbonPage from 'src/pages/congressDetail/CongressLisbon/CongressLisbonPage';
import CongressMelbournePage from 'src/pages/congressDetail/CongressMelbourne/CongressMelbournePage';

// Research and Awards Pages
import AwardsPage from 'src/pages/research_awards/AwardsPage';
import MauriceLauréPrizePage from 'src/pages/prizes/MauriceLauréPrize/MauriceLauréPrizePage';
import MitchellBCarrollPage from 'src/pages/prizes/MitchellBCarrollPrize/MitchellBCarrollPrizePage';
import IFAPresidentYINScientificAwardPage from 'src/pages/prizes/IFAPresidentYINScientificAwardPage/IFAPresidentYINScientificAwardPage';
import PosterProgrammePage from 'src/pages/prizes/PosterProgrammePage/PosterProgrammePage';
import IFAResearchAssociatePage from 'src/pages/prizes/IFAResearchAssociatePage/IFAResearchAssociatePage';
import DavidRTillinghastResearchPage from 'src/pages/prizes/DavidRTillinghastResearchPage/DavidRTillinghastResearchPage';

// Calendar and Event Pages
import CalendarPage from 'src/pages/calendar/CalendarPage';
import EventsPage from 'src/pages/events/EventsPage';
import EventRegistrationNonMemberPage from 'src/pages/eventRegistration/EventRegistrationNonMemberPage';
import EventRegistrationMemberPage from 'src/pages/eventRegistration/EventRegistrationMemberPage';
import SeminarsPage from 'src/pages/seminars/SeminarsPage';
import IfaSaEventPageOne from 'src/pages/IfaSaEventPageOne/IfaSaEventPageOne';
import IfaSaEventPageTwo from 'src/pages/IfaSaEventPageTwo/IfaSaEventPageTwo';
import IfaSaEventPageThree from 'src/pages/IfaSaEventPageThree/IfaSaEventPageThree';

// Member Resources Pages
import SeminarMaterialsPage from 'src/pages/seminarMaterials/SeminarMaterials';
import NewslettersPage from 'src/pages/newslettersPageTest/NewslettersPage';
import MyMembershipPage from 'src/pages/myMembership/MyMembershipPage';

// Auth Pages
// import SignUpPage from 'src/pages/signUpPage/SignUpPage';
import SignInWithLink from 'src/pages/signInWithLink/SignInWithLink';
import SignUpRequestPage from 'src/pages/signUpRequest/SignUpRequestPage';
import AfterSignUpRequest from 'src/pages/afterSignUpRequest/AfterSignUpRequest';
// import SignUpRequestPageTwo from 'src/pages/signUpRequest/SignUpRequestPageTwo';
import SignIn from 'src/pages/signIn/SignIn';
import ForgotPassword from 'src/pages/forgotPassword/ForgotPassword';
import SetPassword from 'src/pages/setPasswordPage/SetPasswordPage';
import SignOut from 'src/pages/signOut/SignOut';
import AuthActions from 'src/pages/auth/AuthActions';
// import EmailNotVerifiedPage from 'src/pages/auth/EmailNotVerifiedPage';

// Admin
import MembersListPage from 'src/pages/membersList/MembersListPage';
import MemberDetailsPage from 'src/pages/memberDetails/MemberDetailsPage';
import CompaniesListPage from 'src/pages/companiesList/CompaniesListPage';
import CompanyDetailsPage from 'src/pages/companyDetails/CompanyDetailsPage';
import CreateNewUserPage from 'src/pages/createNewUser/CreateNewUserPage';
import EventAttendeesPage from 'src/pages/eventAttendees/EventAttendeesPage';
import CreateNewAttendeesPage from 'src/pages/createNewAttendee/CreateNewAttendeePage';
import AttendeeDetailsPage from 'src/pages/attendeeDetails/AttendeeDetailsPage';

//Get in touch pages
import ContactUs from 'src/pages/contact/ContactUs';
import AfterContactUs from 'src/pages/contact/AfterContactUs';
import YINContactUs from 'src/pages/yin/YINContactUs';

interface AppRoutesProps {
  isSignedIn: boolean;
  isLoading: boolean;
  authUser?: FirebaseUser;
  member?: IFAUser;
  user?: User;
  setCurrentUser: (userData: Partial<User>) => void;
}

export default function GeneralRoutes({
  isSignedIn,
  isLoading,
  member,
  user,
  setCurrentUser,
}: AppRoutesProps) {
  const isAdmin = member?.role === 'tech-admin';
  const navigate = useNavigate();
  return (
    <Routes>
      {/* //////////////////////////////////////// Main Page ////////////////////////////////////////////// */}
      <Route
        path={urls.root}
        element={<MainPage isSignedIn={isSignedIn} user={member} />}
      />

      <Route
        path={urls.main.root}
        element={<MainPage isSignedIn={isSignedIn} user={member} />}
      />

      {/* //////////////////////////////////////// General ////////////////////////////////////////////// */}
      <Route path="/styleguide" element={<Styleguide />} />

      {/* //////////////////////////////////////// About Pages ////////////////////////////////////////////// */}
      <Route
        path={urls.about_ifa.root}
        element={<AboutPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={urls.main.read_more}
        element={<ReadMorePage isSignedIn={isSignedIn} />}
      />
      <Route
        path={urls.about_ifa.historyofifa}
        element={<HistoryOfIFAPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={urls.about_ifa.cooperation}
        element={<CooperationPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={urls.about_ifa.membership}
        element={<MembershipPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={urls.about_ifa.yin}
        element={<YINPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={urls.about_ifa.yinCommittee}
        element={<YINCommittee isSignedIn={isSignedIn} />}
      />
      <Route
        path={urls.about_ifa.ifa_sa_constitution}
        element={<ConstitutionPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={urls.about_ifa.branch_executives}
        element={<BranchExecutivesPage isSignedIn={isSignedIn} />}
      />

      {/* //////////////////////////////////////// Congress Pages ////////////////////////////////////////////// */}
      <Route
        path={URLS.congresses.root}
        element={<CongressesPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.congresses.congressBerlin}
        element={<CongressBerlinPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.congresses.congressCancun}
        element={<CongressCancunPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.congresses.congressCapeTown}
        element={<CongressCapeTownPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.congresses.congressLisbon}
        element={<CongressLisbonPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.congresses.congressMelbourne}
        element={<CongressMelbournePage isSignedIn={isSignedIn} />}
      />

      {/* //////////////////////////////////////// Research and Awards Pages ////////////////////////////////////////////// */}
      <Route
        path={URLS.research_awards.root}
        element={<AwardsPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.research_awards.maurice_lauré_prize}
        element={<MauriceLauréPrizePage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.research_awards.mitchell_b_carroll_prize}
        element={<MitchellBCarrollPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.research_awards.ifa_president_yin_scientific_award}
        element={<IFAPresidentYINScientificAwardPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.research_awards.poster_programme}
        element={<PosterProgrammePage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.research_awards.ifa_research_associate}
        element={<IFAResearchAssociatePage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.research_awards.drt_research_programme}
        element={<DavidRTillinghastResearchPage isSignedIn={isSignedIn} />}
      />

      {/* //////////////////////////////////////// Calendar and Event Pages ////////////////////////////////////////////// */}
      <Route
        path={URLS.calendar.root}
        element={<CalendarPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.events.root}
        element={<EventsPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={`${urls.events.registration_non_member}/:id`}
        element={<EventRegistrationNonMemberPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={`${urls.events.registration_member}/:id`}
        element={
          <EventRegistrationMemberPage isSignedIn={isSignedIn} user={member} />
        }
      />
      <Route
        path={URLS.events.seminars}
        element={<SeminarsPage isSignedIn={isSignedIn} />}
      />
      <Route
        path={`${urls.events.event}/:id`}
        element={
          <IfaSaEventPageOne isSignedIn={isSignedIn} isAdmin={isAdmin} />
        }
      />
      <Route
        path={URLS.events.ifa_sa_event_two}
        element={<IfaSaEventPageTwo isSignedIn={isSignedIn} />}
      />
      <Route
        path={URLS.events.ifa_sa_event_three}
        element={<IfaSaEventPageThree isSignedIn={isSignedIn} />}
      />

      {/* //////////////////////////////////////// Member Resources ////////////////////////////////////////////// */}

      <Route
        path={urls.member_resources.seminar_materials}
        element={<SeminarMaterialsPage isAdmin={isAdmin} />}
      />

      <Route
        path={urls.member_resources.newsletters}
        element={<NewslettersPage isAdmin={isAdmin} />}
      />

      <Route
        path={urls.my_membership.root}
        element={<MyMembershipPage isSignedIn={isSignedIn} user={member} />}
      />

      {/* //////////////////////////////////////// Get in touch pages ////////////////////////////////////////////// */}

      <Route
        path={urls.getInTouch.contact}
        element={<ContactUs isSignedIn={isSignedIn} />}
      />

      <Route
        path={urls.getInTouch.afterContactUs}
        element={<AfterContactUs isSignedIn={isSignedIn} />}
      />

      <Route
        path={urls.getInTouch.yinContactUs}
        element={<YINContactUs isSignedIn={isSignedIn} />}
      />

      {/* //////////////////////////////////////// Auth Pages ////////////////////////////////////////////// */}
      <Route
        path={URLS.auth.signIn}
        element={
          <SignIn isSignedIn={isSignedIn} setCurrentUser={setCurrentUser} />
        }
      />
      <Route
        path={URLS.auth.signInWithLink}
        element={<SignInWithLink setCurrentUser={setCurrentUser} />}
      />
      <Route
        path={URLS.auth.signUpRequestPage}
        element={<SignUpRequestPage isSignedIn={isSignedIn} />}
      />

      <Route
        path={URLS.auth.afterSignUpRequest}
        element={<AfterSignUpRequest isSignedIn={isSignedIn} />}
      />
      {/* <Route
        exact
        path={URLS.auth.signUpRequestPage2}
        component={SignUpRequestPageTwo}
      /> */}
      {/* <Route
        exact
        path={URLS.auth.signUp}
        render={(props) => (
          <SignUpPage
            isSignedIn={isSignedIn}
            setCurrentUser={setCurrentUser}
            {...props}
          />
        )}
      /> */}
      <Route path={URLS.auth.signOut} element={<SignOut />} />
      <Route path={URLS.auth.forgotPassword} element={<ForgotPassword />} />
      <Route path={URLS.authActions} element={<AuthActions user={user} />} />
      <Route
        path={URLS.auth.setPassword}
        element={<SetPassword isSignedIn={isSignedIn} />}
      />

      {/* //////////////////////////////////////// Administration Tools ////////////////////////////////////////////// */}
      {isAdmin && (
        <>
          <Route
            path={urls.admin_tools.members}
            element={<MembersListPage />}
          />

          <Route
            path={`${urls.admin_tools.member}/:id`}
            element={<MemberDetailsPage isSignedIn={isSignedIn} />}
          />

          <Route
            path={urls.admin_tools.companies}
            element={<CompaniesListPage isSignedIn={isSignedIn} />}
          />

          <Route
            path={`${urls.admin_tools.company}/:id`}
            element={<CompanyDetailsPage isSignedIn={isSignedIn} />}
          />

          <Route
            path={urls.admin_tools.memberCreate}
            element={<CreateNewUserPage isSignedIn={isSignedIn} />}
          />

          <Route
            path={`${urls.admin_tools.event_attendees}/:eventId`}
            element={<EventAttendeesPage />}
          />

          <Route
            path={`${urls.admin_tools.event_attendees_new}/:id`}
            element={<CreateNewAttendeesPage isSignedIn={isSignedIn} />}
          />

          <Route
            path={`${urls.admin_tools.attendee}/:eventId/:id`}
            element={<AttendeeDetailsPage isSignedIn={isSignedIn} />}
          />
        </>
      )}

      {/* //////////////////////////////////////// Catch-all Route ////////////////////////////////////////////// */}
      <Route path="*" element={<Navigate to={URLS.root} />} />
    </Routes>
  );
}
