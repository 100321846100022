import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './EventBlock.module.css';

// import eventPicture from './eventImage.jpg';

interface EventData {
  startDate?: string;
  endDate?: string;
  eventYear?: string;
  eventName?: string;
  eventLocation?: string;
  eventImage: string;
  moreInfo?: string;
  isLinkExternal?: boolean;
}

export default function EventBlock({
  startDate = '',
  endDate = '',
  eventYear = '',
  eventName = '',
  eventLocation = '',
  eventImage = '',
  moreInfo,
  isLinkExternal = false,
  ...props
}: EventData) {
  return (
    <div className={styles.eventBlockContainer}>
      <div className={styles.eventBlockImage}>
        <img src={eventImage} alt="Event Diagram" width="500" height="300" />
      </div>
      <div className={styles.eventBlockDetails}>
        <span className={styles.eventBlockDetailsDate}>
          <span>From </span>
          <span>{startDate}</span>
          <span> to </span>
          <span>
            {endDate} {eventYear}
          </span>
        </span>
        <h1>{eventName}</h1>
        <span className={styles.eventBlockDetailsDate}>{eventLocation}</span>
        {moreInfo &&
          (isLinkExternal ? (
            <a
              href={moreInfo}
              className={styles.eventBlockDetailButton}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              More Information
            </a>
          ) : (
            <NavLink to={moreInfo} className={styles.eventBlockDetailButton}>
              More Information
            </NavLink>
          ))}
      </div>
    </div>
  );
}
