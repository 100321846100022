import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import { IFACompany, IFAUser } from 'src/types/user';
import urls from 'src/config/urls';
import useFetchDoc from 'src/services/useFetchDoc';
import useFetchCollection from 'src/services/useFetchCollection';
import apiCompany from 'src/services/apiCompany';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import CompanyDetails from 'src/components/IFAcomponents/CompanyDetails/CompanyDetails';
import CompanyDetailsForm from 'src/components/IFAcomponents/CompanyDetailsForm/CompanyDetailsForm';
import CompanyAddressDetails from 'src/components/IFAcomponents/CompanyAddressDetails/CompanyAddressDetails';
import CompanyAddressDetailsForm from 'src/components/IFAcomponents/CompanyAddressDetailsForm/CompanyAddressDetailsForm';
import MembersList from 'src/components/IFAcomponents/MembersList/MembersList';
import PrimaryButton from 'src/components/buttons/PrimaryButton';

import styles from './CompanyDetailsPage.module.css';
import MessageBar from 'src/components/IFAcomponents/MessageBar/MessageBar';

interface CompaniesListPageProps {
  isSignedIn: boolean;
}

export default function CompaniesDetailsPage({
  isSignedIn,
}: CompaniesListPageProps) {
  const { id } = useParams<{ id: string }>();
  const [edit, setEdit] = useState(false);
  const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
  const navigate = useNavigate();

  const handleEdit = () => setEdit(!edit);

  const {
    value: company,
    loading,
    error,
  } = useFetchDoc<IFACompany>('companies', id ? id : '');

  const { values: memberData } = useFetchCollection<IFAUser>('users');

  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      {loading && <p>Loading</p>}
      {error && (
        <p>
          {error.name}: {error.message}
        </p>
      )}
      <section className={`${styles.pageBlockContainer}`}>
        <div className={styles.memberNameContainer}>
          <PageHeader pageHeader={company?.companyDetails?.companyName} />
        </div>
        <div className={styles.buttonsContainer}>
          {!edit && !loading && (
            <>
              <button className={styles.buttonContainer} onClick={handleEdit}>
                Edit
              </button>
              <button
                className={styles.buttonContainer}
                onClick={() => navigate(-1)}
              >
                Back
              </button>
            </>
          )}
          {edit && (
            <button className={styles.buttonContainer} onClick={handleEdit}>
              Done
            </button>
          )}
        </div>

        <div className={styles.detailsContainer}>
          {!edit && (
            <CompanyDetails uid={id} companyDetails={company?.companyDetails} />
          )}
          {!edit && (
            <CompanyAddressDetails
              uid={id}
              companyAddressDetails={company?.companyAddressDetails}
            />
          )}
          {edit && (
            <CompanyDetailsForm
              uid={id}
              companyDetails={company?.companyDetails}
            />
          )}
          {edit && (
            <CompanyAddressDetailsForm
              uid={id}
              companyAddressDetails={company?.companyAddressDetails}
            />
          )}
        </div>
      </section>
      <section className={styles.pageBlockContainer}>
        <h2>Members</h2>
        <MembersList
          data={memberData.filter(
            (member) => member.membershipTypeDetails?.companyId === id
          )}
        />
      </section>
      <section className={styles.pageBlockContainer}>
        {!isConfirmingDelete && !loading && (
          <PrimaryButton onClick={() => setIsConfirmingDelete(true)}>
            Delete
          </PrimaryButton>
        )}
        {isConfirmingDelete && (
          <>
            <MessageBar
              messageBarType="error"
              messageText="Are you sure you want to delete? This action cannot be reversed."
            />
            <br />
            <PrimaryButton onClick={() => setIsConfirmingDelete(false)}>
              Cancel
            </PrimaryButton>
            &nbsp;
            <PrimaryButton
              onClick={async () => {
                setIsConfirmingDelete(false);
                if (id) {
                  await apiCompany.deleteCompany(id);
                }

                navigate(`${urls.admin_tools.companies}`);
              }}
            >
              Confirm Delete
            </PrimaryButton>
          </>
        )}
      </section>
    </>
  );
}
