import React from 'react';

import styles from './Figure.module.css';
import { Theme } from '../../types/misc';

import { CircleInfoIcon } from 'src/components/svg';

import { classnames } from '../../utils/universal_utils';

export interface FigureProps {
  caption: string;
  value: string;
  testId: string;
  className?: string;
  hideCaption?: boolean;
  theme?: Theme;
  captionTipIconAction?: () => void;
}

export default function Figure({
  caption,
  className,
  hideCaption = false,
  theme,
  value,
  testId,
  captionTipIconAction,
  ...props
}: FigureProps) {
  const classNames = classnames([
    styles.root,
    className,
    theme && styles[theme],
  ]);
  const captionClassName = classnames({
    'visually-hidden': hideCaption,
  });

  return (
    <figure className={classNames} {...props}>
      <strong style={{ whiteSpace: 'nowrap' }} data-testid={testId}>
        {value}
      </strong>
      <figcaption
        data-testid={`${testId}-caption`}
        className={captionClassName}
      >
        <span>{caption}</span>{' '}
        {captionTipIconAction && (
          <button
            className={styles.infoButton}
            onClick={captionTipIconAction}
            style={{ marginLeft: 4 }}
          >
            <CircleInfoIcon className={styles.infoIcon} />
          </button>
        )}
      </figcaption>
    </figure>
  );
}
