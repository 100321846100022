import React, { forwardRef } from 'react';
import BaseInput, { InputProps } from './BaseInput';

interface TextInputProps extends InputProps {
  type?: 'email' | 'password' | 'text';
  props?: JSX.IntrinsicAttributes;
}

type Ref = HTMLInputElement;

export default forwardRef<Ref, TextInputProps>(
  ({ children, type = 'text', ...props }, ref) => {
    return (
      <BaseInput ref={ref} type={type} {...props}>
        {children}
      </BaseInput>
    );
  }
);
