import React from 'react';

import styles from './List.module.css';

interface Props {
  children: React.ReactNode;
  className?: string;
  props?: JSX.IntrinsicAttributes & Props;
}

export default function List({ children, className = '', ...props }: Props) {
  return (
    <ul {...props} className={`${styles.list} ${className}`}>
      {children}
    </ul>
  );
}
