import React from 'react';

import styles from './AfterContactUs.module.css';

import { NavLink } from 'react-router-dom';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import SignUpImage from 'src/assets/img/signup.svg';

interface AfterContactUsRequestProps {
  isSignedIn: boolean;
}

export default function AfterContactUs({
  isSignedIn,
}: AfterContactUsRequestProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={styles.bodyContainer}>
        <div className={styles.imageContainer}>
          <img src={SignUpImage} alt="Sign Up Diagram" />
        </div>
        <PageHeader pageHeader={'Contact Us Request Received'} />
        <p className={styles.text}>
          Thank you for requesting to contact us. We will try and respond as
          soon as possible.
        </p>
        <NavLink className={`${styles.actionButton}`} to={`/root`}>
          Back To Home
        </NavLink>
      </section>
    </>
  );
}
