import React from 'react';

interface Props {
  className?: string;
}

export default function PencilIcon({ className = '', ...props }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.861"
      height="20.861"
      viewBox="0 0 20.861 20.861"
      className={className}
      {...props}
    >
      <path
        fill="currentColor"
        d="M2.608 14.994v3.26h3.26l9.614-9.614-3.26-3.26zm15.394-8.875a.866.866 0 0 0 0-1.226L15.968 2.86a.866.866 0 0 0-1.226 0l-1.59 1.59 3.256 3.258 1.591-1.591z"
      />
      <path fill="none" d="M0 0h20.861v20.861H0z" />
    </svg>
  );
}
