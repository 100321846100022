import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

let FIREBASE_CONFIG = {};

const betaConfig = {
  apiKey: 'AIzaSyAHPLnYI96PwrH8iuq68DFo--GKvninsHI',
  authDomain: 'beta-ifasa-app.firebaseapp.com',
  databaseURL:
    'https://beta-ifasa-app-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'beta-ifasa-app',
  storageBucket: 'beta-ifasa-app.appspot.com',
  messagingSenderId: '968897764556',
  appId: '1:968897764556:web:02cfbeb8d6d5eb9386f669',
  measurementId: 'G-H6TNRNSKRV',
};

const liveConfig = {
  apiKey: 'AIzaSyBcfZhYT4pEBQevp3_jawNhfPPdVMYGroY',
  authDomain: 'ifasa-app.firebaseapp.com',
  projectId: 'ifasa-app',
  storageBucket: 'ifasa-app.appspot.com',
  messagingSenderId: '862161427333',
  appId: '1:862161427333:web:e9c0a7454b2d1dcdedc2ec',
  measurementId: 'G-VJV4LC6HTJ',
};

if (
  process.env.REACT_APP_ENV === 'beta' ||
  process.env.REACT_APP_ENV === 'dev'
) {
  FIREBASE_CONFIG = betaConfig;
}

if (process.env.REACT_APP_ENV === 'live') {
  FIREBASE_CONFIG = liveConfig;
}

if (!process.env.REACT_APP_ENV) {
  FIREBASE_CONFIG = betaConfig;
}

const firebase = initializeApp(FIREBASE_CONFIG);

const database = getFirestore(firebase);

if (process.env.NODE_ENV !== 'test') {
  enableIndexedDbPersistence(database)
    .then(function () {
      console.info('Firestore offline persistence enabled');
    })
    .catch(function (error) {
      if (error.code === 'failed-precondition') {
        console.warn(
          'WARNING: Multiple tabs open, persistence can only be enabled in one tab at a a time.'
        );
      } else if (error.code === 'unimplemented') {
        console.warn(
          'WARNING: The current browser does not support all of the features required to enable persistence'
        );
      } else {
        console.warn('WARNING: Unknown error: ', error);
      }
    });
}
const auth = getAuth(firebase);
const storage = getStorage(firebase);
const functions = getFunctions(firebase);

export default firebase;

export { auth, database, storage, functions };
