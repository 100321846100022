import { functions } from 'src/config/firebase';
import { signIn, signOut, signUp, auth } from './apiAuth';
import {
  userDoc,
  storageRef,
  getRealtimeUserData,
  getUserData,
} from './apiUtils';
import { User, IFAUser } from 'src/types/user';
import URLS from 'src/config/urls';
import { httpsCallable } from 'firebase/functions';
import { deleteDoc, setDoc, updateDoc } from 'firebase/firestore';

export function getNewUserData(): Partial<IFAUser> {
  return {
    personalDetails: {},
    membershipTypeDetails: {},
    addressDetails: {},
    taxStatusDetails: {},
    notes: [],
    // status: 'new-user-unverified',
  };
}

async function adminCreateNewUser(uid: string, user: Partial<IFAUser>) {
  try {
    if (!user) {
      user = getNewUserData();
    }
    await setDoc(userDoc(uid), user);
    return user;
  } catch (error) {
    console.error('CreateNewUser error: ', error);
    return null;
  }
}

async function adminDeleteUser(uid: string) {
  try {
    await deleteDoc(userDoc(uid));
  } catch (error) {
    console.error('Delete User error: ', error);
    return null;
  }
}

async function adminSendInviteEmail(
  uid: string,
  email: string,
  fullName: string
) {
  const url = URLS.api.admin.sendSignInWithEmailLinkWelcome;

  await fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      uid: uid,
      email: email,
      name: fullName,
    }),
  });
}

async function createNewUser(user: User, { username }: { username: string }) {
  const { id: uid } = user;

  const newUserData = {
    personalDetails: {},
    membershipTypeDetails: {},
    addressDetails: {},
    taxStatusDetails: {},
    notes: [],
    status: 'new-user-unverified',
  };

  try {
    await setDoc(userDoc(uid), newUserData);

    return newUserData;
  } catch (error) {
    console.error('CreateNewUser error: ', error);
    return null;
  }
}

function user(uid: string) {
  return {
    getInRealtime: {
      userData1: (getData: (data: any) => void) =>
        getRealtimeUserData(uid, getData),
      userData2: (getData: (data: any) => void) => getUserData(uid, getData),
    },
  };
}

function updateUser(uid: string, data: Partial<IFAUser>) {
  return updateDoc(userDoc(uid), { ...data });
}

const cloudFunctions = {
  subscribeUser: httpsCallable(functions, 'subscribeUser'),
};

export default {
  user,
  getNewUserData,
  adminCreateNewUser,
  adminDeleteUser,
  adminSendInviteEmail,
  createNewUser,
  storageRef,
  signIn,
  signOut,
  signUp,
  auth,
  auth_: { signIn, signOut, signUp, auth },
  cloudFunctions,
  functions,
  updateUser,
};
