import React from 'react';
import { NavLink } from 'react-router-dom';
import { ArrowToRight } from 'src/components/svg';

import styles from './CalendarBlock.module.css';
import calendarPicture from './ifa-illustration-1970-ifa.jpg';

export default function CalendarBlock() {
  return (
    <div className={styles.calendarEvent}>
      <div className={styles.calendarEventImage}>
        <img src={calendarPicture} alt="Calender Diagram" />
      </div>
      <div className={styles.calendarEventBody}>
        <span className={styles.calendarEventBodySubtitle}>Featured Event</span>
        <h2>General Council & General Assembly meeting IFA</h2>
        <span className={styles.calendarEventBodySubtitle}>Online</span>
        <div className={styles.btnprimary}>
          <NavLink to="#" className={styles.btnprimarytext}>
            View Event
            <span className={styles.btnprimaryicon}>
              <ArrowToRight />
            </span>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
