import React from 'react';

import styles from './EventDetails.module.css';

interface EventDetailsData {
  congress?: string;
  congressDetails?: string;
  locationDetails?: string;
}

export default function EventDetails({
  congress = '',
  congressDetails = '',
  locationDetails = '',
  ...props
}: EventDetailsData) {
  return (
    <div className={`${styles.eventDetailsContainer}`}>
      <h1>{congress}</h1>
      <p>{congressDetails}</p>
      <p>{locationDetails}</p>
    </div>
  );
}
