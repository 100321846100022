import React from 'react';

import styles from './BaseButton.module.css';
import { classnames } from '../../utils/universal_utils';

interface Props {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  disabled?: boolean;
}

export default function BaseButton({
  children,
  type = 'button',
  disabled = false,
  className = '',
  ...props
}: Props) {
  const classNames = classnames([styles.button, className]);
  return (
    <button disabled={disabled} className={classNames} type={type} {...props}>
      {children}
    </button>
  );
}
