import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IFAEvent } from 'src/types/user';

import styles from './CalendarList.module.css';

// interface CalendarListData {
//   date?: string;
//   month?: string;
//   year?: string;
//   title?: string;
//   place?: string;
//   link?: string;
//   type?: string;
// }

// type CalendarListProps = {
//   data: CalendarListData[];
// };

type CalendarListProps = {
  data: IFAEvent[];
};

export default function CalendarList(props: CalendarListProps) {
  const eventData = props.data;
  const navigate = useNavigate();

  return (
    <div className={styles.calendarPanel}>
      {eventData &&
        eventData.map((event, index) => {
          return (
            <div
              key={`event-${index}`}
              className={styles.calendarRow}
              onClick={() => {
                if (event.isExternal) {
                  window.open(event.link);
                } else {
                  navigate(`/events/event/${event.id}`);
                }
              }}
            >
              {/* <NavLink to={event.link ?? '#'} className={styles.calendarLink}> */}
              <div className={styles.calendarBlock}>
                <span className={styles.calendarDate}>{event.date}</span>
                <span className={styles.calendarMonth}>
                  {' '}
                  {event.month} <br />
                  {event.year}{' '}
                </span>
              </div>

              <div>
                <div className={styles.calendarTitle}>{event.name}</div>
                <span className={styles.calendarPlace}>{event.place}</span>
              </div>

              <div className={styles.calendarDetailType}>
                <span className={styles.calendarDetailTypeText}>
                  {event.type}
                </span>
              </div>
              {/* </NavLink> */}
            </div>
          );
        })}
    </div>
  );
}
