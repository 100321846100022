import React from 'react';
import { IFAUser } from 'src/types/user';
import { IFAEvent } from 'src/types/user';
import useFetchCollection from 'src/services/useFetchCollection';
import dateUtils from 'src/utils/dateUtils';
import styles from './MainPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import BlockElement from 'src/components/IFAcomponents/BlockElements/BlockElement';
import Calendar from 'src/components/IFAcomponents/Calendar/Calendar';
import CalendarBlock from 'src/components/IFAcomponents/CalendarBlock/CalendarBlock';
import ImageCarousel from 'src/components/IFAcomponents/ImageCarousel/ImageCarousel';

import ifaPicture from './ifa2015.jpg';
import logoPosition1 from './ifa-master-logo-cape-town-2024.jpg';
import logoPosition2 from './ifa-conference-2025.png';

interface MainPageProps {
  isSignedIn: boolean;
  user?: IFAUser;
}

export default function MainPage({ isSignedIn }: MainPageProps) {
  /* eslint-disable  @typescript-eslint/no-unused-vars */
  const {
    values: eventsData,
    loading,
    error,
  } = useFetchCollection<IFAEvent>('events');

  const showPastEvents = false;
  const eventsFiltered = eventsData
    .filter((event) => {
      return showPastEvents || dateUtils.isEventInCurrentMonthOrFuture(event);
    })
    .sort((a, b) => {
      const b_date = dateUtils.getDateForEvent(b);
      const a_date = dateUtils.getDateForEvent(a);
      if (!a_date) return 1;
      if (!b_date) return -1;
      return a_date < b_date ? -1 : a_date > b_date ? 1 : 0;
    });

  return (
    <>
      <header>
        <NavBar />
      </header>
      <div>
        <ImageCarousel />
      </div>
      <section className={`${styles.pageBlockContainer}`}>
        <div className={`${styles.blockElementPanel}`}>
          <BlockElement
            smallTitle={'IFA 2024 Cape Town Congress'}
            bigTitle={'Registration now open!'}
            blockInfo={
              'With great pleasure we welcome you to the 76th Congress of the International Fiscal Association in Cape Town, South Africa from 27 to 31 October 2024. We will put together the latest scientific topics to provide participants an excellent forum to exchange and debate, meet and interact with friends, colleagues, sponsors and exhibitors.'
            }
            blockLink={'https://www.ifa.nl/congresses/ifa-2024-cape-town'}
          />
          <BlockElement
            smallTitle={'Upcoming Webinars'}
            bigTitle={
              'Watch this space for upcoming webinars that are currently in the works'
            }
            blockInfo={
              'IFA South Africa hosts regular webinars to keep you updated on all the latest financial matters. Become a member today for access to top professional insights.'
            }
            blockLink={'/calendar'}
          />
          <BlockElement
            smallTitle={'Social media'}
            bigTitle={'Follow IFA on social media'}
            blockInfo={
              'Stay up to date on all matters IFA and follow uson social media via LinkedIn or Twitter'
            }
            blockLink={
              'https://www.linkedin.com/company/international-fiscal-association/'
            }
          />
        </div>
      </section>
      <section>
        <div className={`${styles.calendarContainer}`}>
          <div className={`${styles.calendarListContainer}`}>
            <Calendar data={eventsFiltered} />
          </div>
          <div className={`${styles.calendarBlockContainer}`}>
            <CalendarBlock />
          </div>
        </div>
      </section>
      <section className={`${styles.pageBlockContainer}`}>
        <div className={`${styles.aboutContainerBlock}`}>
          <BlockElement
            smallTitle={''}
            bigTitle={'About IFA'}
            blockInfo={
              'IFA is a neutral, independent, non-lobby organisation and as such the only global network of its kind. As a non-profit organisation, IFA provides a global platform where representatives of all professions and interests can meet and discuss international tax issues at the highest level. IFAs flagship event is its annual congress, attended by approximately 2.000 delegates every year.'
            }
            blockLinkText={'More about IFA'}
            blockLink={'/about_ifa'}
          />

          <div className={styles.imageContainer}>
            <img src={ifaPicture} alt="Conference Diagram"></img>
          </div>
        </div>
      </section>
      <div className={`${styles.yearlyLogos}`}>
        <img src={logoPosition1} alt="Conference Diagram"></img>
        <img src={logoPosition2} alt="Conference Diagram"></img>
      </div>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
