import React from 'react';
import { storage } from 'src/config/firebase';
import fileDownload from 'js-file-download';
import {
  deleteObject,
  getDownloadURL,
  listAll,
  ref,
  StorageReference,
  uploadBytes,
} from 'firebase/storage';

export interface FirebaseFile {
  fullPath: string;
  name: string;
  size: number;
}

export interface FirebaseFolder {
  fullPath: string;
  name: string;
  folders: FirebaseFolder[];
  files: FirebaseFile[];
}

interface FilesResult {
  folder: FirebaseFolder;
  loading: boolean;
  error: Error | null;
}

export function useNewsLetters(): FilesResult {
  return useFiles('Newsletters');
}

export function useSeminars(): FilesResult {
  return useFiles('Seminars');
}

export const useFiles = (fullPath: string): FilesResult => {
  const [data, setData] = React.useState<FilesResult>({
    folder: { fullPath, name: fullPath, folders: [], files: [] },
    loading: true,
    error: null,
  });

  React.useEffect(() => {
    const processPrefix = async (prefix: StorageReference) => {
      const folders: FirebaseFolder[] = [];
      const files: FirebaseFile[] = [];

      const children = await listAll(prefix);

      children.items.forEach((itemRef) => {
        //console.log('file callback:', itemRef.fullPath);
        const file = {
          fullPath: itemRef.fullPath,
          name: itemRef.name,
          size: 0,
        };
        files.push(file);
      });

      children.prefixes.forEach((childPrefix: StorageReference) => {
        //console.log('childPrefix callback: ', childPrefix.name);
        folders.push({
          fullPath: childPrefix.fullPath,
          name: childPrefix.name,
          folders: [],
          files: [],
        });
        processPrefix(childPrefix);
      });

      const updatedState = updateState(data.folder, prefix, folders, files);
      if (updatedState) {
        //console.log('setData', prefix.fullPath);
        setData({
          folder: updatedState,
          loading: false,
          error: null,
        });
      }
    };

    const fn = async () => {
      const listRef = ref(storage, fullPath);
      await processPrefix(listRef);
    };
    fn();
  }, [data.folder, fullPath]);

  return data;
};

function updateState(
  state: FirebaseFolder,
  prefix: StorageReference,
  folders: FirebaseFolder[],
  files: FirebaseFile[]
): FirebaseFolder | null {
  if (state.fullPath === prefix.fullPath) {
    state.folders = folders;
    state.files = files;
    return state;
  }

  //Search in folders - look for prefix fullPath. If found - update its files/folders
  for (const childFolder of state.folders) {
    if (childFolder.fullPath === prefix.fullPath) {
      childFolder.folders = folders;
      childFolder.files = files;
      return state;
    }
  }

  //Search in subfolders of each folders - look for prefix fullPath. If found - update its files/folders
  for (const childFolder_ of state.folders) {
    if (prefix.fullPath.startsWith(childFolder_.fullPath)) {
      updateState(childFolder_, prefix, folders, files);
    }
  }

  return state;
}

export async function createFolder(relativePath: string) {
  // Not supported via the api - can only put files
  // One suggestion is to create a file ignored.txt in the folder and exclude it from results
}

export async function download(fullPath: string) {
  const storageRef = ref(storage, fullPath);

  getDownloadURL(storageRef)
    .then(async (url) => {
      const response = await fetch(url);
      if (response.ok) {
        const body = await response.arrayBuffer();
        fileDownload(body, storageRef.name);
      }
    })
    .catch((error) => {
      console.warn('error', error);
    });
}

export async function deleteFile(fullPath: string) {
  const storageRef = ref(storage, fullPath);

  deleteObject(storageRef)
    .then(() => {
      console.log(`file ${fullPath} deleted successfully!`);
    })
    .catch((error) => {
      console.log('error', error); // Uh-oh, an error occurred!
    });
}

export async function uploadDatUrl(fullPath: string, dataUrl: string) {
  const blob = convertDataUrlToBlob(dataUrl);
  const storageRef = ref(storage, fullPath);
  await uploadBytes(storageRef, blob);

  //   const message4 = 'data:text/plain;base64,5b6p5Y+344GX44G+44GX44Gf77yB44GK44KB44Gn44Go44GG77yB';
  //   await ref.putString(blob, 'data_url');
}

const convertDataUrlToBlob = (dataUrl: string): Blob => {
  const arr = dataUrl.split(',');
  const mimeMatches = arr[0].match(/:(.*?);/);
  const mime = mimeMatches ? mimeMatches[1] : '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[Number(n)] = bstr.charCodeAt(n);
  }

  return new Blob([u8arr], { type: mime });
};

export function readAsDataURL(
  file: File
): Promise<string | null | ArrayBuffer> {
  // Converts a input file into a dataUrl;
  // const file = e.target.files[0];
  // const dataUrl = await readAsDataURL(file);

  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

export function sortByNameDescending(folders: FirebaseFolder[]): void {
  folders.sort((a, b) => {
    if (a.name < b.name) {
      return 1;
    }
    if (a.name > b.name) {
      return -1;
    }

    return 0;
  });
}

// test('can list files in resources folder', async () => {});
// test('can create a folder', () => {});
// test('can rename a folder', () => {});
// test('can create a file', () => {});
// test('can rename a file', () => {});
// test('can delete a file', () => {});
// test('can delete a folder', () => {});

export default {
  useNewsLetters,
  useSeminars,
  useFiles,
  createFolder,
  uploadDatUrl,
  download,
  deleteFile,
  readAsDataURL,
  sortByNameDescending,
};
