import React from 'react';

import styles from './EventsPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import PageSubheader from 'src/components/IFAcomponents/PageSubheader/PageSubheader';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';

interface EventsPageProps {
  isSignedIn: boolean;
}

export default function EventsPage({ isSignedIn }: EventsPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <PageHeader pageHeader={'Events'} />
        <PageSubheader pageSubheader={'Seminar Series'} />
        <p>
          IFA-South Africa hosts a seminar series each year. This seminar series
          (presented in Cape Town and Johannesburg) are short (one and a half
          hour) presentations by leading speakers on international tax issues of
          practical relevance. All views expressed in all seminars are the
          presenter's personal views and do not reflect any official or
          unofficial position of any organisation or person by whom they are
          employed or are purported to represent unless clearly indicated in the
          seminar materials to the contrary. These seminars are offered at no
          charge to members in good standing.
        </p>
        <p>
          <strong>Usual Venues:</strong>
          <ul>
            <li>
              <strong>Cape Town</strong>
              <p>
                Webber Wentzel: 15th Floor, Convention Tower, Heerengracht,
                Foreshore, Cape Town, 8001
              </p>
            </li>
            <li>
              <strong>Johannesburg</strong>
              <p>Webber Wentzel: 90 Rivonia Road, Sandton, Johannesburg 2196</p>
            </li>
          </ul>
        </p>
      </section>
      <section className={`${styles.pageBlockContainer}`}>
        <PageSubheader pageSubheader={'Full and Half Day Seminars '} />
        <p>
          IFA-South Africa hosts regular (generally annually) full-day and
          half-day seminars, in both Cape Town and Johannesburg, presented by a
          leading international tax expert each year. Full Day Seminars are
          dependent on speaker availability. Discounted rates for attendance are
          available for registered members in good standing.
        </p>
      </section>

      {!isSignedIn && <CallToAction />}

      <Footer isSignedIn={isSignedIn} />
    </>
  );
}
