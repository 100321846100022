import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import ExportButton from 'src/components/IFAcomponents/ExportButton/ExportButton';
import EventAttendeesList from 'src/components/IFAcomponents/EventAttendeesList/EventAttendeesList';

import useFetchAttendees from 'src/services/useFetchAttendees';
import { attendee } from 'src/types/user';

import styles from './EventAttendeesPage.module.css';

export default function EventAttendeesPage() {
  const [searchString, setSearchString] = useState('');

  const { eventId } = useParams<{ eventId: string }>();

  const {
    values: attendeeData,
    loading,
    error,
  } = useFetchAttendees<attendee>(eventId ? eventId : '');

  //console.log(attendeeData);

  function handleSearch(e: { target: { value: string } }) {
    const searchValue = e.target.value;
    setSearchString(searchValue);
  }

  return (
    <>
      <header>
        <NavBar />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <div>
          <PageHeader pageHeader={'Attendees List'} />
        </div>
        <div className={`${styles.exportButtonsContainer}`}>
          <div className={`${styles.excelButton}`}>
            <ExportButton buttonName={'Export To Excel'} />
          </div>
          <div className={`${styles.xlsxButton}`}>
            <ExportButton buttonName={'Export As XLSX'} />
          </div>
          <div className={`${styles.searchInputContainer}`}>
            <label>Search</label>
            <input
              className={`${styles.searchInput}`}
              type="text"
              name="search"
              value={searchString}
              onChange={handleSearch}
            />
          </div>
        </div>
        <div className={`${styles.createNewContainer}`}>
          <span>Number of Attendees: {attendeeData.length}</span>
          <NavLink to={`/admin_tools/attendee_new/${eventId}`}>
            Create New
          </NavLink>
        </div>
      </section>
      <section className={`${styles.pageBlockContainer}`}>
        <EventAttendeesList
          eventId={eventId ? eventId : ''}
          data={attendeeData.filter(
            (attendee) =>
              !searchString ||
              attendee?.name
                ?.toLocaleLowerCase()
                .includes(searchString.toLowerCase())
          )}
        />
      </section>
    </>
  );
}
