import React, { useState } from 'react';

import Checkbox from '../../components/formControls/Checkbox';
import TextInput from '../../components/formControls/TextInput';
import Dropdown from '../../components/formControls/Dropdown';
import { noop } from '../../utils/universal_utils';
import EmailInput from '../../components/formControls/EmailInput';
import PasswordInput from '../../components/formControls/PasswordInput';

const FormComponents = () => {
  // State
  const [selectedValue, setSelectedValue] = useState('');
  const [checkboxState, setCheckbox1state] = useState(false);
  const [emailAddress, onEmailInputChange] = useState('');
  const [password, onPasswordInputChange] = useState('');
  const [someText, onSomeTextInputChange] = useState('');

  const onDropdownChange = (selection) => {
    setSelectedValue(selection.value);
  };
  const options = [
    {
      value: 'option1',
      name: 'Option 1',
    },
    {
      value: 'option2',
      name: 'Option 2',
    },
    {
      value: 'option3',
      name: 'Option 3',
    },
  ];

  return (
    <div>
      <ul className="afi-sg-demo-list">
        <li className="afi-sg-demo-list-item">
          <EmailInput
            errorMessage="Invalid email"
            hasError={false}
            name="email-input"
            onChange={onEmailInputChange}
            placeholder="Email Input"
            value={emailAddress}
            maxLength="true"
          />
        </li>
        <li className="afi-sg-demo-list-item">
          <PasswordInput
            errorMessage="Invalid password"
            hasError={false}
            name="password-input"
            onChange={onPasswordInputChange}
            placeholder="Password Input"
            value={password}
            maxLength={20}
          />
        </li>
        <li className="afi-sg-demo-list-item">
          <TextInput
            label="Label text"
            errorMessage="Invalid text"
            hasError={false}
            name="text-input-too"
            onChange={onSomeTextInputChange}
            placeholder="Text Input with Label"
            value={someText}
            maxLength={20}
          />
        </li>
        <li className="afi-sg-demo-list-item">
          <Checkbox
            checked={checkboxState}
            id="checkbox-example"
            label="Checkbox Example"
            onChange={setCheckbox1state}
          />
        </li>
        <li className="afi-sg-demo-list-item">
          <TextInput
            hasError={false}
            name="text-input"
            onChange={noop}
            placeholder="Text Input"
            value={''}
          />
        </li>
        <li className="afi-sg-demo-list-item">
          <Dropdown
            hasError={false}
            label="List Button"
            name=""
            onChange={onDropdownChange}
            options={options}
            placeholder="Select Option"
            value={selectedValue}
          />
        </li>
      </ul>
    </div>
  );
};

export default FormComponents;
