import React from 'react';
import { NavLink as a } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import styles from './ImageCarousel.module.css';
import imageOne from './Pic1.jpg';
import imageTwo from './Pic2.jpg';
import imageThree from './Pic3.jpg';
import imageFour from './Pic4.jpg';
import ArrowToRight from 'src/components/svg/ArrowToRight';

export default function ImageCarousel() {
  return (
    <Carousel
      autoPlay
      interval={3500}
      transitionTime={1000}
      infiniteLoop
      showThumbs={false}
      showStatus={false}
      showArrows={false}
    >
      <div className={styles.imageCarouselContainer}>
        <img src={imageOne} alt="Carousel Diagram One" />
        <div className={styles.imageDetails}>
          <div className={styles.imageDetailDates}>27 - 31 October 2024</div>
          <div className={styles.imageDetailLocation}>
            IFA Congress Cape Town South Africa
          </div>
          <a
            href="https://www.ifa.nl/congresses/ifa-2024-cape-town"
            className={styles.readMoreLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.readMoreButton}>
              <div className={styles.readMoreText}>Read More</div>
              <div className={styles.readMoreButtonIcon}>
                <ArrowToRight />
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className={styles.imageCarouselContainer}>
        <img src={imageTwo} alt="Carousel Diagram Two" />
        <div className={styles.imageDetails}>
          <div className={styles.imageDetailDates}>5 - 9 October 2025</div>
          <div className={styles.imageDetailLocation}>
            IFA Congress Lisbon Portugal
          </div>
          <a
            href="https://www.ifa.nl/congresses/ifa-2025-lisbon"
            className={styles.readMoreLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.readMoreButton}>
              <div className={styles.readMoreText}>Read More</div>
              <div className={styles.readMoreButtonIcon}>
                <ArrowToRight />
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className={styles.imageCarouselContainer}>
        <img src={imageThree} alt="Carousel Diagram Three" />
        <div className={styles.imageDetails}>
          <div className={styles.imageDetailDates}>18 - 22 October 2026</div>
          <div className={styles.imageDetailLocation}>
            IFA Congress Melbourne Australia
          </div>
          <a
            href="https://www.ifa.nl/congresses/ifa-2026-melbourne"
            className={styles.readMoreLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.readMoreButton}>
              <div className={styles.readMoreText}>Read More</div>
              <div className={styles.readMoreButtonIcon}>
                <ArrowToRight />
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className={styles.imageCarouselContainer}>
        <img src={imageFour} alt="Carousel Diagram Four" />
        <div className={styles.imageDetails}>
          <div className={styles.imageDetailDates}>19 - 23 September 2027</div>
          <div className={styles.imageDetailLocation}>
            IFA 2027 Stockholm Sweden
          </div>
          <a
            href="https://www.ifa.nl/congresses/ifa-2027-stockholm"
            className={styles.readMoreLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className={styles.readMoreButton}>
              <div className={styles.readMoreText}>Read More</div>
              <div className={styles.readMoreButtonIcon}>
                <ArrowToRight />
              </div>
            </div>
          </a>
        </div>
      </div>
    </Carousel>
  );
}
