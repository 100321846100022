import React from 'react';
import PropTypes from 'prop-types';
import { classnames } from '../../utils/universal_utils';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const Page = ({ className = '', children }) => {
  const allClassNames = classnames([className]);

  return <div className={allClassNames}>{children}</div>;
};

Page.propTypes = propTypes;

export default Page;
