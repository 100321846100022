import React from 'react';

import styles from './ChevronIcon.module.css';
import { IconProps } from '../../types/misc';

export default function ChevronIcon({
  className = '',
  direction = 'right',
  ...props
}: IconProps & { direction: 'right' | 'left' | 'down' | 'up' }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
      clipRule="evenodd"
      viewBox="0 0 8 14"
      className={`${styles[direction]} ${className}`}
      {...props}
    >
      <path fill="none" stroke="currentColor" d="M.5.5l6.1 6.1-6.1 6.1" />
    </svg>
  );
}
