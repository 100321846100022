import React from 'react';
import { addressDetails } from 'src/types/user';
import api from 'src/services/api';

import styles from './AddressDetailsForm.module.css';

interface AddressDetails {
  uid?: string;
  addressDetails?: addressDetails;
}

export default function AddressDetailsForm({
  uid,
  addressDetails,
  ...props
}: AddressDetails) {
  /////////////////////////////////////////////Functions To Change Strings///////////////////////////////////////
  // Address Type
  function selectAddressType(e: React.ChangeEvent<HTMLSelectElement>) {
    const addressTypeValue: string = e.target.value;
    if (uid && addressDetails?.addressType !== addressTypeValue) {
      api.updateUser(uid, {
        addressDetails: {
          ...addressDetails,
          addressType: addressTypeValue,
        },
      });
    }
  }

  // Address Line 1
  function handleAddressLine1Change(e: { target: { value: string } }) {
    const addressLine1Value: string = e.target.value;
    if (uid && addressDetails?.addressLine1 !== addressLine1Value) {
      api.updateUser(uid, {
        addressDetails: { ...addressDetails, addressLine1: addressLine1Value },
      });
    }
  }

  // Address Line 2
  function handleAddressLine2Change(e: { target: { value: string } }) {
    const addressLine2Value: string = e.target.value;
    if (uid && addressDetails?.addressLine2 !== addressLine2Value) {
      api.updateUser(uid, {
        addressDetails: { ...addressDetails, addressLine2: addressLine2Value },
      });
    }
  }
  // Suburb
  function handleSuburbChange(e: { target: { value: string } }) {
    const suburbValue: string = e.target.value;
    if (uid && addressDetails?.suburb !== suburbValue) {
      api.updateUser(uid, {
        addressDetails: { ...addressDetails, suburb: suburbValue },
      });
    }
  }

  // City
  function handleCityChange(e: { target: { value: string } }) {
    const cityValue: string = e.target.value;
    if (uid && addressDetails?.city !== cityValue) {
      api.updateUser(uid, {
        addressDetails: { ...addressDetails, city: cityValue },
      });
    }
  }

  // Province
  function handleProvinceChange(e: { target: { value: string } }) {
    const provinceValue: string = e.target.value;
    if (uid && addressDetails?.province !== provinceValue) {
      api.updateUser(uid, {
        addressDetails: { ...addressDetails, province: provinceValue },
      });
    }
  }

  // Country
  function handleCountryChange(e: { target: { value: string } }) {
    const countryValue: string = e.target.value;
    if (uid && addressDetails?.country !== countryValue) {
      api.updateUser(uid, {
        addressDetails: { ...addressDetails, country: countryValue },
      });
    }
  }

  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Address Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>Address Type</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <select
                  onChange={selectAddressType}
                  className={`${styles.addressTypeInputContainer}`}
                >
                  <option selected disabled>
                    Please select your address type
                  </option>
                  <option value="Postal">Postal</option>
                  <option value="Physical">Physical</option>
                </select>
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Address Line 1</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="addressLine1"
                  value={addressDetails?.addressLine1}
                  onChange={handleAddressLine1Change}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Address Line 2</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="addressLine2"
                  value={addressDetails?.addressLine2}
                  onChange={handleAddressLine2Change}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Suburb</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="suburb"
                  value={addressDetails?.suburb}
                  onChange={handleSuburbChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>City</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="city"
                  value={addressDetails?.city}
                  onChange={handleCityChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Province</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="province"
                  value={addressDetails?.province}
                  onChange={handleProvinceChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td>Country</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="country"
                  value={addressDetails?.country}
                  onChange={handleCountryChange}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
