import React from 'react';
import { NavLink } from 'react-router-dom';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import styles from './MauriceLauréPrizePage.module.css';

interface MembershipPageProps {
  isSignedIn: boolean;
}

export default function MauriceLauréPrizePage({
  isSignedIn,
}: MembershipPageProps) {
  // });
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={styles.pageBlockContainer}>
        <PageHeader pageHeader={'Maurice Lauré Prize'} />
        <p>
          IFA has instituted a Prize in order to encourage scientific work on
          international indirect taxation. The Prize is named in honour of
          Maurice Lauré, who was instrumental in the first implementation of the
          Value Added Tax system, now applied in more than 160 jurisdictions.
        </p>
        <p>
          <strong>Subject: </strong>International indirect fiscal law,
          comparative indirect tax law, or local indirect tax law having an
          important relation with fiscal law in foreign jurisdictions.
        </p>
        <p>
          <strong>Language: </strong>English, French and German. English
          preferred.
        </p>
        <p>
          <strong>Open to: </strong>participants under 40 years of age on the 31
          of July of the year in which the Prize is awarded, whether they are
          members of the Association or not.
        </p>
        <p>
          <strong>Conditions:</strong>
          <ul>
            <li>Submission of a presentation letter on the topic;</li>
            <li>Submission of a resume including the date of birth;</li>
            <li>
              Upon submission, candidates need additionally to prepare an
              article on the topic in English with a maximum of 10.000 words.
            </li>
          </ul>
        </p>
        <p>
          <strong>Prize: </strong> Euro 5.000, plus a medal and an invitation to
          attend the IFA 2021 Congress in Berlin, exempted from the payment of
          the Congress registration fee, during which the Prize is awarded.
        </p>
        <p>
          <strong>Submission deadline: </strong>1 April 2021. Papers entered for
          the Prize for a given year should be submitted as a soft copy (PDF) by
          e-mail to a.gensecr@ifa.nl before the 1st of April.
        </p>
      </section>
      <section className={styles.pageBlockContainer}>
        <div className={styles.competitionRulesContainer}>
          <h2>Competition Rules</h2>
          <ul>
            <li>
              <NavLink to="#">
                ML Rules on the competition for the Mitchell B. Carroll Prize
                2020.pdf
              </NavLink>
            </li>
          </ul>
          <hr></hr>
        </div>
      </section>
      <section className={styles.pageBlockContainer}>
        <div className={styles.juryBlockContainer}>
          <h2>Jury of the Mitchell B. Carroll Prize</h2>

          <p>
            The Jury consists of five members, who are appointed by the
            Executive Committee from amongst the IFA membership upon
            recommendation by the Permanent Scientific Committee.
          </p>
          <h3>Chair</h3>
          <p>Jean-Claude Bouchard (France)</p>
          <h3>Members</h3>
          <p>Markus Achatz (Austria)</p>
          <p>Andrea Parolini (Italy)</p>
          <p>Satya Poddar (India)</p>
          <p>Philip Robinson (Switzerland)</p>
        </div>
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <Footer isSignedIn={isSignedIn} />
    </>
  );
}
