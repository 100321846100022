import React from 'react';

import styles from './AttendeeDetails.module.css';

import { attendee } from 'src/types/user';

interface AttendeeDetailsProps {
  uid?: string;
  attendee?: attendee;
}

export default function AttendeeDetails({
  uid,
  attendee,
  ...props
}: AttendeeDetailsProps) {
  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Attendee Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>Full Name</td>
            <td>{attendee?.name}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Email</td>
            <td>{attendee?.email}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Phone Number</td>
            <td>{attendee?.number}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Member</td>
            <td>
              {attendee?.isMember && 'Yes'}
              {!attendee?.isMember && 'No'}
            </td>
          </tr>
          {!attendee?.isMember && (
            <tr>
              <td className={`${styles.tableContainerName}`}>
                Inviter (IFA Number)
              </td>
              <td>{attendee?.inviter}</td>
            </tr>
          )}
          {/* <tr>
                        <td className={`${styles.tableContainerName}`}>
                            Residence Permit Number
                        </td>
                        <td>{attendee?.residencePermitNumber}</td>
                    </tr>
                    <tr>
                        <td className={`${styles.tableContainerName}`}>Passport Country</td>
                        <td>{attendee?.passportCountry}</td>
                    </tr>
                    <tr>
                        <td className={`${styles.tableContainerName}`}>Passport Number</td>
                        <td>{attendee?.passportNumber}</td>
                    </tr> */}
        </tbody>
      </table>
    </div>
  );
}
