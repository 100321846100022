import React from 'react';

import styles from './StyleGuide.module.css';

import MenuLink from '../../components/buttonLinks/MenuLink';
import SecondaryButtonLink from '../../components/buttonLinks/SecondaryButtonLink';
import List from '../../components/ui/List';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import PrimaryButtonLink from '../../components/buttonLinks/PrimaryButtonLink';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import TertiaryButtonLink from '../../components/buttonLinks/TertiaryButtonLink';

export default function Buttons() {
  return (
    <React.Fragment>
      <section>
        <h2>Default Primary button</h2>

        <List className={styles.inlineList}>
          <li>
            <PrimaryButton disabled={false}>Primary Button</PrimaryButton>
          </li>
          <li>
            <PrimaryButton disabled={true}>
              Primary Button Disabled
            </PrimaryButton>
          </li>
        </List>
      </section>

      <section>
        <h2>Themed Primary buttons</h2>

        <List className={styles.inlineList}>
          <li>
            <PrimaryButton theme="plan" disabled={false}>
              Plan Button
            </PrimaryButton>
          </li>
          <li>
            <PrimaryButton theme="expenditure" disabled={false}>
              Expenditure Button
            </PrimaryButton>
          </li>
          <li>
            <PrimaryButton theme="maintain" disabled={false}>
              Maintain Button
            </PrimaryButton>
          </li>
          <li>
            <PrimaryButton theme="manage" disabled={false}>
              Manage Button
            </PrimaryButton>
          </li>
          <li>
            <PrimaryButton theme="motivate" disabled={false}>
              Motivate Button
            </PrimaryButton>
          </li>
          <li>
            <PrimaryButton theme="danger" disabled={false}>
              Danger Button
            </PrimaryButton>
          </li>
        </List>
      </section>

      <section>
        <h2>Default Primary Link Button</h2>

        <List className={styles.inlineList}>
          <li>
            <PrimaryButtonLink href="#">Primary Button Link</PrimaryButtonLink>
          </li>
          <li>
            <PrimaryButtonLink disabled={true} href="#">
              Primary Button Link Disabled
            </PrimaryButtonLink>
          </li>
        </List>
      </section>

      <section>
        <h2>Themed Primary Link Buttons</h2>

        <List className={styles.inlineList}>
          <li>
            <PrimaryButtonLink href="#" theme="plan" disabled={false}>
              Plan Button
            </PrimaryButtonLink>
          </li>
          <li>
            <PrimaryButtonLink href="#" theme="expenditure" disabled={false}>
              Expenditure Button
            </PrimaryButtonLink>
          </li>
          <li>
            <PrimaryButtonLink href="#" theme="maintain" disabled={false}>
              Maintain Button
            </PrimaryButtonLink>
          </li>
          <li>
            <PrimaryButtonLink href="#" theme="manage" disabled={false}>
              Manage Button
            </PrimaryButtonLink>
          </li>
          <li>
            <PrimaryButtonLink href="#" theme="motivate" disabled={false}>
              Motivate Button
            </PrimaryButtonLink>
          </li>
          <li>
            <PrimaryButtonLink href="#" theme="danger" disabled={false}>
              Danger Button
            </PrimaryButtonLink>
          </li>
        </List>
      </section>

      <section>
        <h2>Default Secondary Buttons</h2>

        <List className={styles.inlineList}>
          <li>
            <SecondaryButton>Secondary Button</SecondaryButton>
          </li>
          <li>
            <SecondaryButton disabled={true}>
              Secondary Button Disabled
            </SecondaryButton>
          </li>
        </List>
      </section>

      <section>
        <h2>Themed Secondary Buttons</h2>

        <List className={styles.inlineList}>
          <li>
            <SecondaryButton theme="primary">Primary Button</SecondaryButton>
          </li>
          <li>
            <SecondaryButton theme="plan">Plan Button</SecondaryButton>
          </li>
          <li>
            <SecondaryButton theme="motivate">Motivate Button</SecondaryButton>
          </li>
          <li>
            <SecondaryButton theme="manage">Manage Button</SecondaryButton>
          </li>
          <li>
            <SecondaryButton theme="maintain">Maintain Button</SecondaryButton>
          </li>
          <li>
            <SecondaryButton theme="expenditure">
              Expenditure Button
            </SecondaryButton>
          </li>
          <li>
            <SecondaryButton theme="danger">Danger Button</SecondaryButton>
          </li>
        </List>
      </section>

      <section>
        <h3>Secondary Buttons with Chevron</h3>

        <List className={styles.inlineList}>
          <li>
            <SecondaryButton displayIcon={true} theme="primary">
              Primary Button
            </SecondaryButton>
          </li>
          <li>
            <SecondaryButton displayIcon={true} theme="plan">
              Plan Button
            </SecondaryButton>
          </li>
          <li>
            <SecondaryButton displayIcon={true} theme="motivate">
              Motivate Button
            </SecondaryButton>
          </li>
          <li>
            <SecondaryButton displayIcon={true} theme="manage">
              Manage Button
            </SecondaryButton>
          </li>
          <li>
            <SecondaryButton displayIcon={true} theme="maintain">
              Maintain Button
            </SecondaryButton>
          </li>
          <li>
            <SecondaryButton displayIcon={true} theme="expenditure">
              Expenditure Button
            </SecondaryButton>
          </li>
          <li>
            <SecondaryButton displayIcon={true} theme="danger">
              Danger Button
            </SecondaryButton>
          </li>
        </List>
      </section>

      <section>
        <h2>Default Secondary Link Buttons</h2>

        <List className={styles.inlineList}>
          <li>
            <SecondaryButtonLink href="#">
              Secondary Button Link
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" disabled={true}>
              Secondary Button Link disabled
            </SecondaryButtonLink>
          </li>
        </List>
      </section>

      <section>
        <h2>Themed Secondary Link Buttons</h2>

        <List className={styles.inlineList}>
          <li>
            <SecondaryButtonLink href="#" theme="primary">
              Primary Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" theme="plan">
              Plan Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" theme="motivate">
              Motivate Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" theme="manage">
              Manage Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" theme="maintain">
              Maintain Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" theme="expenditure">
              Expenditure Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" theme="danger">
              Danger Button
            </SecondaryButtonLink>
          </li>
        </List>
      </section>

      <section>
        <h3>Secondary Link Buttons with Chevron</h3>

        <List className={styles.inlineList}>
          <li>
            <SecondaryButtonLink href="#" displayIcon={true} theme="primary">
              Primary Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" displayIcon={true} theme="plan">
              Plan Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" displayIcon={true} theme="motivate">
              Motivate Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" displayIcon={true} theme="manage">
              Manage Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" displayIcon={true} theme="maintain">
              Maintain Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink
              href="#"
              displayIcon={true}
              theme="expenditure"
            >
              Expenditure Button
            </SecondaryButtonLink>
          </li>
          <li>
            <SecondaryButtonLink href="#" displayIcon={true} theme="danger">
              Danger Button
            </SecondaryButtonLink>
          </li>
        </List>
      </section>

      <section>
        <h2>Default Tertiary Link Buttons</h2>

        <List className={styles.inlineList}>
          <li>
            <TertiaryButtonLink href="#">
              Tertiary Button Link
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" disabled={true}>
              Tertiary Button Link disabled
            </TertiaryButtonLink>
          </li>
        </List>
      </section>

      <section>
        <h2>Themed Tertiary Link Buttons</h2>

        <List className={styles.inlineList}>
          <li>
            <TertiaryButtonLink href="#" theme="primary">
              Primary Button
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" theme="plan">
              Plan Button
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" theme="motivate">
              Motivate Button
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" theme="manage">
              Manage Button
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" theme="maintain">
              Maintain Button
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" theme="expenditure">
              Expenditure Button
            </TertiaryButtonLink>
          </li>
        </List>
      </section>

      <section>
        <h2>Themed Tertiary Link Buttons with Chevron</h2>

        <List className={styles.inlineList}>
          <li>
            <TertiaryButtonLink href="#" displayIcon={true} theme="primary">
              Primary Button
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" displayIcon={true} theme="plan">
              Plan Button
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" displayIcon={true} theme="motivate">
              Motivate Button
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" displayIcon={true} theme="manage">
              Manage Button
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" displayIcon={true} theme="maintain">
              Maintain Button
            </TertiaryButtonLink>
          </li>
          <li>
            <TertiaryButtonLink href="#" displayIcon={true} theme="expenditure">
              Expenditure Button
            </TertiaryButtonLink>
          </li>
        </List>
      </section>

      <section>
        <h2>Menu Links</h2>

        <ul className={styles.contentList}>
          <li className={styles.contentListItem}>
            <MenuLink href="#" type="internal">
              Menu Link (internal)
            </MenuLink>
          </li>
          <li className={styles.contentListItem}>
            <MenuLink href="#" type="external">
              Menu Link (external)
            </MenuLink>
          </li>
        </ul>
      </section>
    </React.Fragment>
  );
}
