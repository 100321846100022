import React from 'react';

import styles from './DavidRTillinghastResearchPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';

interface DavidRTillinghastResearchPageProps {
  isSignedIn: boolean;
}

export default function DavidRTillinghastResearchPage({
  isSignedIn,
}: DavidRTillinghastResearchPageProps) {
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${styles.pageBlockContainer}`}>
        <PageHeader pageHeader={'David R. Tillinghast Research programme'} />
        <div className={`${styles.competitionRulesContainer}`}>
          <h2>Call for applications for organising a DRT Conference - 2023</h2>
        </div>
        <p>
          The Permanent Scientific Committee of the International Fiscal
          Association invites not-for-profit academic institutions around the
          world to apply for funding for an academic or scientific conference on
          matters of international or comparative taxation. The conference
          should take place in the fall of 2023. The available funds consist of
          up to € 5.000 for preparatory work and € 20.000 for the conference
          itself. IFA Branches and affiliated institutions are not eligible to
          apply.
        </p>
        <p>
          Applicants should submit to Central IFA a detailed proposal which
          provides sufficient information as to the organisers, the topic, the
          program and the envisaged speakers. The application should include an
          explanatory letter which highlights the practical and theoretical
          relevance of the topic, the current state of research and the progress
          to be expected from this conference. Proposed speakers should include
          tax experts from different professional backgrounds and jurisdictions.
          Institutions from developing and emerging countries are specifically
          encouraged to apply.
        </p>
        <p>
          The conference will be held on a pro bono basis (no honoraria paid to
          organisers or speakers; entrance fees paid by participants should
          cover costs only). Additional funding might be provided by the
          organising institution, the local IFA Branch or sponsorship by third
          parties. Applications must be made by 31 December 2021 and submitted
          by email to Central IFA: cr.gensecr@ifa.nl .
        </p>
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <Footer isSignedIn={isSignedIn} />
    </>
  );
}
