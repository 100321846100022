import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import urls from 'src/config/urls';
import { useParams } from 'react-router-dom';
import useFetchAttendeeDoc from 'src/services/useFetchAttendeeDoc';
import { attendee } from 'src/types/user';

import styles from './AttendeeDetailsPage.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import PageHeader from 'src/components/IFAcomponents/PageHeader/PageHeader';
import AttendeeDetails from 'src/components/IFAcomponents/AttendeeDetails/AttendeeDetails';
import AttendeeDetailsForm from 'src/components/IFAcomponents/AttendeeDetailsForm/AttendeeDetailsForm';

interface AttendeeDetailsPageProps {
  isSignedIn: boolean;
}

export default function AttendeeDetailsPage({
  isSignedIn,
}: AttendeeDetailsPageProps) {
  const { eventId, id } = useParams<{ eventId: string; id: string }>();

  const [edit, setEdit] = useState(false);

  const handleEdit = () => setEdit(!edit);

  const {
    value: attendeeData,
    loading,
    error,
  } = useFetchAttendeeDoc<attendee>(eventId ? eventId : '', id ? id : '');

  return (
    <>
      <NavBar isSignedIn={isSignedIn} />
      <section className={styles.backContainer}>
        <NavLink to={urls.admin_tools.members}>Back to Attendees</NavLink>
      </section>
      <section className={styles.pageBlockContainer}>
        <div className={styles.memberNameContainer}>
          <PageHeader pageHeader={attendeeData?.name} />
        </div>
        <div className={styles.buttonsContainer}>
          {!edit && (
            <>
              <button className={styles.buttonContainer} onClick={handleEdit}>
                Edit
              </button>
              <button
                className={styles.buttonContainer}
                // onClick={() => history.goBack()}
              >
                Back
              </button>
            </>
          )}
          {edit && (
            <button className={styles.buttonContainer} onClick={handleEdit}>
              Done
            </button>
          )}
        </div>
        {!edit && (
          <div className={styles.detailsContainer}>
            <AttendeeDetails uid={id} attendee={attendeeData!} />
          </div>
        )}
        {edit && (
          <div className={styles.detailsContainer}>
            <AttendeeDetailsForm
              eventId={eventId ? eventId : ''}
              uid={id}
              attendee={attendeeData!}
            />
          </div>
        )}
      </section>
    </>
  );
}
