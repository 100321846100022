import React from 'react';

import styles from './PageBullet.module.css';

interface BulletData {
  pageBullet?: string;
}

type BulletProps = {
  data: BulletData[];
};

export default function PageBullet(props: BulletProps) {
  const bullData = props.data;
  return (
    <div className={`${styles.pagebodycontainer}`}>
      <ul>
        {bullData &&
          bullData.map((d, index) => {
            return (
              <li key={index}>
                <p>{d.pageBullet}</p>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
