import React from 'react';

import styles from './FooterLogo.module.css';
import footerlogo from './logo-full-gray.svg';

export default function FooterLogo() {
  return (
    <div className={`${styles.footerlogocontainer}`}>
      <img src={footerlogo} alt="Footer Diagram"></img>
    </div>
  );
}
