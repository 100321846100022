import { collection, doc, onSnapshot } from 'firebase/firestore';
import React from 'react';
import { database } from 'src/config/firebase';

interface DocumentResult<T> {
  error: Error | null;
  loading: boolean;
  value: T | null;
}

interface FirebaseDocument {
  id?: string;
}

export const useFetchAttendeeDoc = <T extends FirebaseDocument>(
  eventId: string,
  id: string
): DocumentResult<T> => {
  const [data, setData] = React.useState<DocumentResult<T>>({
    error: null,
    loading: true,
    value: null,
  });

  React.useEffect(() => {
    const eventAttendee = onSnapshot(
      doc(database, `events/${eventId}/attendees`, id),
      (snapshot) => {
        setData({
          error: null,
          loading: false,
          value: snapshot.data() as T,
        });
      },
      (error) => {
        setData({
          error,
          loading: false,
          value: null,
        });
      }
    );
    return eventAttendee;
  }, [eventId, id]);

  return data;
};

export default useFetchAttendeeDoc;
