import React from 'react';
import { taxStatusDetails } from 'src/types/user';

import styles from './TaxStatus.module.css';

interface TaxStatusDetails {
  uid?: string;
  taxStatusDetails?: taxStatusDetails;
}

export default function TaxStatus({
  uid,
  taxStatusDetails,
  ...props
}: TaxStatusDetails) {
  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Tax Status</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>Tax Status</td>
            <td>{taxStatusDetails?.taxStatus}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Tax Number</td>
            <td>{taxStatusDetails?.incomeTaxNumber}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>ID Number</td>
            <td>{taxStatusDetails?.idNumber}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>
              Residence Permit Number
            </td>
            <td>{taxStatusDetails?.residencePermitNumber}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Passport Country</td>
            <td>{taxStatusDetails?.passportCountry}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Passport Number</td>
            <td>{taxStatusDetails?.passportNumber}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
