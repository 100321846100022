import React from 'react';

import styles from './IfaSaEventPageThree.module.css';

import NavBar from 'src/components/IFAcomponents/NavBar/NavBar';
import EventTitle from 'src/components/IFAcomponents/EventTitle/EventTitle';
import EventInfo from 'src/components/IFAcomponents/EventInfo/EventInfo';
import EventSpeakerTwo from 'src/components/IFAcomponents/EventSpeakerTwo/EventSpeakerTwo';
import EventDetailsBlockTest from 'src/components/IFAcomponents/EventDetailsBlockTest/EventDetailsBlockTest';
import EventPayments from 'src/components/IFAcomponents/EventPayments/EventPayments';
import CallToAction from 'src/components/IFAcomponents/CallToAction/CallToAction';
import Footer from 'src/components/IFAcomponents/Footer/Footer';

interface IfaSaEventPageThreeProps {
  pageTheme?: 'dark' | 'light';
  isSignedIn: boolean;
}

export default function IfaSaEventPageThree({
  pageTheme = 'dark',
  isSignedIn,
}: IfaSaEventPageThreeProps) {
  const titleTheme =
    pageTheme === 'light'
      ? styles.eventTitleContainerLight
      : styles.eventTitleContainerDark;
  const pageBlockTheme =
    pageTheme === 'light'
      ? styles.pageBlockContainerLight
      : styles.pageBlockContainerDark;
  return (
    <>
      <header>
        <NavBar isSignedIn={isSignedIn} />
      </header>
      <section className={`${titleTheme}`}>
        <EventTitle
          eventTheme={'dark'}
          eventTitle={
            'South Africas General Anti-avoidance Rule: Policy And Design Reviewed'
          }
        />
        <EventInfo
          eventTheme={'dark'}
          eventInfo={
            'The International Fiscal Association (South Africa) invites you to the first of a two-part webminar series.'
          }
        />
      </section>
      <section className={`${pageBlockTheme}`}>
        <div className={`${styles.eventSpeakersContainer}`}>
          <EventSpeakerTwo
            eventTheme={'light'}
            speakerName={'Dr Ed Liptak'}
            speakerTitle={'Tax Practitioner & Author'}
            speakerTopic={'Recent Developments In Tax Avoidance'}
            speakerBio={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            }
          />
          <EventSpeakerTwo
            eventTheme={'light'}
            speakerName={'Dr Ed Liptak'}
            speakerTitle={'Tax Practitioner & Author'}
            speakerTopic={'Recent Developments In Tax Avoidance'}
            speakerBio={
              'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'
            }
          />
        </div>
        <div className={`${styles.eventDetailsContainer}`}>
          <EventDetailsBlockTest
            eventTheme={'grey'}
            event={'Webinar'}
            //eventDates={'25  May 2021'}
            eventTimes={'15h00 - 17h00'}
            eventDuration={'2 Hours'}
            eventLocation={'Online'}
          />
          <EventPayments
            eventTheme={'grey'}
            eventMemberPrice={'Free'}
            eventNonMemberPrice={'R 650'}
          />
        </div>
      </section>
      {!isSignedIn && (
        <div>
          <CallToAction />
        </div>
      )}
      <footer>
        <Footer isSignedIn={isSignedIn} />
      </footer>
    </>
  );
}
