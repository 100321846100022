import React from 'react';

import styles from './PageBody.module.css';

interface BodyData {
  pageBody?: string | React.ReactNode;
}

export default function PageBody({ pageBody = '', ...props }: BodyData) {
  return (
    <div className={`${styles.pagebodycontainer}`}>
      <p>{pageBody}</p>
    </div>
  );
}
