import React from 'react';

import { attendee } from 'src/types/user';
import apiAttendees from 'src/services/apiAttendees';

import styles from './AttendeeDetailsForm.module.css';

interface AttendeeDetailsFormProps {
  eventId: string;
  uid?: string;
  attendee: attendee;
}

export default function AttendeeDetailsForm({
  eventId,
  uid,
  attendee,
  ...props
}: AttendeeDetailsFormProps) {
  /////////////////////////////////////////////Functions To Change Strings///////////////////////////////////////

  function handleNameChange(e: { target: { value: string } }) {
    const nameValue: string = e.target.value;
    if (uid && attendee?.name !== nameValue) {
      apiAttendees.updateAttendee(eventId, uid, {
        name: nameValue,
      });
    }
  }

  function handleEmailChange(e: { target: { value: string } }) {
    const emailValue: string = e.target.value;
    if (uid && attendee?.name !== emailValue) {
      apiAttendees.updateAttendee(eventId, uid, {
        email: emailValue,
      });
    }
  }

  function handleNumberChange(e: { target: { value: string } }) {
    const numberValue: string = e.target.value;
    if (uid && attendee?.name !== numberValue) {
      apiAttendees.updateAttendee(eventId, uid, {
        number: numberValue,
      });
    }
  }

  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Attendee Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>Full Name</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="name"
                  value={attendee.name}
                  onChange={handleNameChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Email</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="email"
                  value={attendee.email}
                  onChange={handleEmailChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Number</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <input
                  className={`${styles.searchInput}`}
                  type="text"
                  name="email"
                  value={attendee.number}
                  onChange={handleNumberChange}
                />
              </div>
            </td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Address Type</td>
            <td>
              <div className={`${styles.searchInputContainer}`}>
                <select
                  // onChange={selectAddressType}
                  className={`${styles.addressTypeInputContainer}`}
                >
                  <option selected disabled>
                    Please select your address type
                  </option>
                  <option value="Member">Member</option>
                  <option value="Non Member">Non Member</option>
                </select>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
