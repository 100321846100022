import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Navbar from 'src/components/IFAcomponents/NavBar/NavBar';
import Footer from 'src/components/IFAcomponents/Footer/Footer';
import URLS from '../../config/urls';
import api from '../../services/api';
import EmailInput from '../../components/formControls/EmailInput';
import PasswordInput from '../../components/formControls/PasswordInput';
import PublicPage from '../../components/page/PublicPage/PublicPage';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import {
  isValidEmail,
  displayErrorFromServer,
  hasErrors,
} from '../../utils/validation';
import './SignIn.css';
import { sendEmailVerification } from 'firebase/auth';

interface SignInFormProps {
  setCurrentUser: (user: object) => void;
  isSignedIn: boolean;
}

function SignIn({ setCurrentUser, isSignedIn }: SignInFormProps) {
  const navigate = useNavigate();

  const [state, setState] = useState({
    isSubmitting: false,
    data: {
      email: '',
      password: '',
    },
    errors: {
      email: '',
      password: '',
    },
    resendEmailSuccess: false,
    emailVerifyInputVisible: false,
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { data, errors } = state;
    const { name, value } = e.target;

    setState({
      ...state,
      data: {
        ...data,
        [name]: value,
      },
      errors: {
        ...errors,
        [name]: null,
      },
    });
  };

  const onSignInError = (fieldName: any, errorMessage: any) => {
    setState({
      ...state,
      errors: {
        ...state.errors,
        [fieldName]: errorMessage,
      },
    });
  };

  const onSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    setState({ ...state, isSubmitting: true });

    const { data } = state;

    //clearFormErrors();

    const validate = (inputs: { email: string; password: string }) => ({
      email: isValidEmail(inputs.email) ?? '',
      password: inputs.password.length > 0 ? '' : 'Password is required',
    });

    const errors = validate(data);

    if (hasErrors(errors)) {
      setState({
        ...state,
        data: {
          email: data.email,
          password: data.password,
        },
        errors: {
          email: errors.email,
          password: errors.password,
        },
        isSubmitting: false,
      });
      return;
    }

    api
      .signIn(data)
      .then((authUser: any) => {
        // const { user } = authUser;
        // const dataFromAuthObject = {
        //   email: user?.email,
        //   uid: user?.uid,
        //   username: user?.displayName,
        //   emailVerified: user?.emailVerified,
        //   accountLastSignInTime: user?.metadata.lastSignInTime,
        //   accountCreatedTime: user?.metadata.creationTime,
        // };

        // console.log('SignIn, setCurrentUser', dataFromAuthObject);
        // setCurrentUser(dataFromAuthObject);
        setCurrentUser(authUser);
        navigate(URLS.root);
      })
      .catch((error: Error) => {
        setState({ ...state, isSubmitting: false });
        displayErrorFromServer(error, onSignInError);
      });
  };

  const clearFormErrors = () => {
    setState({
      ...state,
      errors: {
        email: '',
        password: '',
      },
    });
  };

  const resendEmailVerification = () => {
    if (api.auth.currentUser) {
      sendEmailVerification(api.auth.currentUser)
        .then(() => {
          setState({
            ...state,
            resendEmailSuccess: true,
          });
        })
        .catch((error) => {
          setState({
            ...state,
            resendEmailSuccess: false,
            isSubmitting: false,
          });
          console.error(error);
        });
    }
  };

  const displayEmailVerifyInput = () => {
    setState({
      ...state,
      emailVerifyInputVisible: true,
    });
  };

  return (
    <PublicPage testId="sign-in-page" title="Welcome Back!">
      <Navbar isSignedIn={false} />
      <div className="formContainer">
        <div className="afi-SignIn-wrapper">
          <form className="afi-SignIn-form" onSubmit={onSubmit}>
            <EmailInput
              className="afi-SignIn-field"
              errorMessage={state.errors.email}
              disabled={state.isSubmitting}
              hasError={!!state.errors.email}
              name="email"
              onChange={onChange}
              placeholder="Email"
              value={state.data.email}
            />

            <PasswordInput
              className="afi-SignIn-field"
              errorMessage={state.errors.password}
              disabled={state.isSubmitting}
              hasError={!!state.errors.password}
              name="password"
              onChange={onChange}
              placeholder="Password"
              value={state.data.password}
            />

            <PrimaryButton
              className="afi-SignIn-btn"
              data-testid="signin-btn"
              disabled={state.isSubmitting}
              type="submit"
            >
              {state.isSubmitting ? 'Logging in...' : 'Log In'}
            </PrimaryButton>
          </form>

          <Link
            className="afi-SignIn-forgotPassword large-text"
            to={URLS.auth.forgotPassword}
          >
            Forgot Password?
          </Link>

          <p className="fine-print">
            <span className="afi-SignIn-no-account">
              Don't have an account?
            </span>
            <Link to={URLS.auth.signUpRequestPage}>Sign up instead</Link>
          </p>
        </div>
      </div>
      <Footer isSignedIn={isSignedIn} />
    </PublicPage>
  );
}

export default SignIn;
