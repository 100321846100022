import React from 'react';

import styles from './EventTitle.module.css';

interface EventTitleData {
  eventTheme?: 'dark' | 'light';
  eventTitle?: string;
}

export default function EventTitle({
  eventTheme = 'light',
  eventTitle = '',
  ...props
}: EventTitleData) {
  const componentTheme =
    eventTheme === 'light'
      ? styles.pageheaderContainerLight
      : styles.pageheaderContainerDark;
  return (
    <div className={`${componentTheme}`}>
      <h1>{eventTitle}</h1>
    </div>
  );
}
