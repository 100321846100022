import React from 'react';
import { membershipTypeDetails } from 'src/types/user';

import styles from './MembershipType.module.css';

interface MembershipTypeDetails {
  uid?: string;
  membershipTypeDetails?: membershipTypeDetails;
}

export default function MembershipType({
  uid,
  membershipTypeDetails,
  ...props
}: MembershipTypeDetails) {
  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Membership Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>Membership Type</td>
            <td>{membershipTypeDetails?.membershipType}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Billing Party</td>
            <td>{membershipTypeDetails?.billingParty}</td>
          </tr>
          {membershipTypeDetails?.membershipType === 'YIN' && (
            <tr>
              <td>Date Of Birth</td>
              <td>{membershipTypeDetails?.dob}</td>
            </tr>
          )}
          <tr>
            <td className={`${styles.tableContainerName}`}>Company</td>
            <td>{membershipTypeDetails?.company}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
