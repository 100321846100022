import React from 'react';

import styles from './NewsletterFolder.module.css';

import NewsArticle from 'src/components/IFAcomponents/NewsArticle/NewsArticle';
import PrimaryButton from 'src/components/buttons/PrimaryButton';
import apiResources, { FirebaseFolder } from 'src/services/apiResources';
import MessageBar from '../MessageBar/MessageBar';

interface NewsletterFolderData {
  folder: FirebaseFolder;
  indent: number;
  isAdmin: boolean;
}

export default function NewsletterFolder({
  folder,
  indent = 0,
  isAdmin,
  ...props
}: NewsletterFolderData) {
  // const indentPx = `${indent * 24}px`;
  const [dataUrl, setDataUrl] = React.useState<string>();
  const [isUploading, setIsUploading] = React.useState(false);
  const [uploadError, setUploadError] = React.useState('');
  const [attachment, setAttachment] = React.useState({
    id: null,
    name: '',
    href: '',
    isFile: false,
    isUrl: false,
  });

  // This is not recursive - what will you do on a 100th depth nested folder?
  const folderIndentSpace =
    indent === 0
      ? styles.pageheadercontainerZero
      : styles.pageheadercontainerOne;

  const onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const dataUrl = await apiResources.readAsDataURL(file);

      if (dataUrl) {
        setAttachment({ ...attachment, name: file.name });
        setDataUrl(dataUrl as string);
      }
    }
  };

  const uploadAttachment = async (prefixFullPath: string): Promise<void> => {
    if (!dataUrl) return;
    setIsUploading(true);
    const fullPath = `${prefixFullPath}/${attachment.name}`;
    try {
      await apiResources.uploadDatUrl(fullPath, dataUrl);
    } catch {
      setUploadError('Error uploading file');
    }
    setIsUploading(false);
    setDataUrl(undefined);
  };

  return (
    <section>
      <div className={`${folderIndentSpace}`}>
        <h1>{folder?.name}</h1>
        {isAdmin && (
          <div className={`${styles.uploadContainer}`}>
            <input type="file" onChange={onFileChange} />
            {dataUrl && (
              <PrimaryButton
                onClick={() => uploadAttachment(folder.fullPath)}
                disabled={isUploading || !dataUrl}
                className={`${styles.uploadButtonContainer}`}
              >
                Upload
              </PrimaryButton>
            )}
            {uploadError && (
              <MessageBar messageBarType={'error'} messageText={uploadError} />
            )}
          </div>
        )}
      </div>
      {folder?.folders &&
        folder?.folders.map((folder, index) => {
          return (
            <div className={`${styles.pageBlockContainer}`} key={index}>
              <NewsletterFolder
                folder={folder}
                indent={indent + 1}
                isAdmin={isAdmin}
              />
            </div>
          );
        })}
      <div className={styles.newslettersContainer}>
        {folder?.files &&
          folder?.files.map((newsletter, index) => {
            return <NewsArticle newsletterFile={newsletter} key={index} />;
          })}
      </div>
    </section>
  );
}
