import React, { useEffect } from 'react';

import styles from './GeneralPage.module.css';
import { Theme } from '../../types/misc';

import MainTitle from '../ui/MainTitle';
import { CircleInfoIcon } from '../../components/svg';
import { classnames } from '../../utils/universal_utils';
import sharedStyles from '../../assets/css/Shared.module.css';

interface Props {
  headerIcon: any;
  children: React.ReactNode;
  className?: string;
  title?: string;
  onClose?: any;
  theme?: Theme;
  titleTipToggle?: () => void;
  headerCurve?: boolean;
  browserTitle?: string;
}

function GeneralPageLayout({
  className,
  children,
  headerIcon: HeaderIcon,
  onClose = null,
  title = '',
  browserTitle = '',
  theme = 'plan',
  titleTipToggle,
  headerCurve = false,
}: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = `${browserTitle} | Afirefi`;
  }, [browserTitle]);

  const classNames = [styles.root, className].join(' ');
  const hasCloseBtn = 'function' === typeof onClose;
  const headerThemeClass = theme ? styles[`type--${theme}`] : '';

  return (
    <div className={classNames}>
      <header
        className={`${
          headerCurve
            ? `${styles.headerCurve}  ${headerThemeClass}`
            : styles.header
        }`}
      >
        <HeaderIcon
          className={classnames([
            styles.icon,
            headerCurve && styles[`type--${theme}`],
          ])}
        />

        <div className={sharedStyles.flexRow}>
          {title && <MainTitle className={styles.title}>{title}</MainTitle>}

          {titleTipToggle && (
            <button aria-label="circle info icon" onClick={titleTipToggle}>
              <CircleInfoIcon
                className={`${sharedStyles.infoIcon} ${
                  headerCurve && styles.headerCurveTipIcon
                }`}
              />
            </button>
          )}
        </div>

        {hasCloseBtn && (
          <button
            data-testid="general-page-close-button"
            className={styles.close}
            onClick={onClose}
            type="button"
          >
            <span className="visually-hidden">Close Page</span>
          </button>
        )}
      </header>

      <div className={styles.body}>{children}</div>
    </div>
  );
}

export default GeneralPageLayout;
