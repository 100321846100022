import React from 'react';
import { companyAddressDetails } from 'src/types/user';

import styles from './CompanyAddressDetails.module.css';

interface CompanyAddressDetails {
  uid?: string;
  companyAddressDetails?: companyAddressDetails;
}

export default function CompanyAddressDetails({
  uid,
  companyAddressDetails,
  ...props
}: CompanyAddressDetails) {
  return (
    <div className={`${styles.personalDetailsContainer}`}>
      <h2>Address Details</h2>
      <table className={`${styles.tableContainer}`}>
        <tbody>
          <tr>
            <td className={`${styles.tableContainerName}`}>Address Type</td>
            <td>{companyAddressDetails?.addressType}</td>
          </tr>
          <tr>
            <td className={`${styles.tableContainerName}`}>Address Line 1</td>
            <td>{companyAddressDetails?.addressLine1}</td>
          </tr>
          <tr>
            <td>Address Line 2</td>
            <td>{companyAddressDetails?.addressLine2}</td>
          </tr>
          <tr>
            <td>Suburb</td>
            <td>{companyAddressDetails?.suburb}</td>
          </tr>
          <tr>
            <td>City</td>
            <td>{companyAddressDetails?.city}</td>
          </tr>
          <tr>
            <td>Province</td>
            <td>{companyAddressDetails?.province}</td>
          </tr>
          <tr>
            <td>Country</td>
            <td>{companyAddressDetails?.country}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
