import React from 'react';

import PublicPage from '../../components/page/PublicPage/PublicPage';
import ifaImagePath from '../../assets/img/logo-white.svg';

import styles from './Loading.module.css';

interface Props {
  title?: string;
}

export default function Loading({ title }: Props) {
  return (
    <PublicPage testId="loading-page" className={styles.page} isLoadingPage>
      <div className={styles.imageContainer}>
        <div className={styles.spacer}></div>
        <img
          className={styles.image}
          src={ifaImagePath}
          alt="International Fiscal Association"
        />
        <h1 className={styles.title}>{title}</h1>
      </div>
    </PublicPage>
  );
}
