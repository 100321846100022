import React, { useRef, useEffect, forwardRef } from 'react';
import { useStateObject, useCombinedRefs } from '../../utils/hooks';
import { classnames } from '../../utils/universal_utils';

import { ErrorIcon, CircleInfoIcon } from '../svg';

import styles from './BaseInput.module.css';
import sharedStyles from 'src/assets/css/Shared.module.css';

const MAX_LENGTH_DEFAULT = 21;

export interface InputProps {
  name: string;
  value: any;
  placeholder: string;
  onChange: React.FormEventHandler<HTMLInputElement>;
  autoComplete?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  errorMessage?: string;
  hasError?: boolean;
  label?: string | React.ReactNode;
  maxLength?: number | string;
  onBlur?: React.FormEventHandler<HTMLInputElement>;
  onFocus?: React.FormEventHandler<HTMLInputElement>;
  type?: string;
  inputClassName?: string;
  labelTipClick?: () => void;
}

type InputRef = HTMLInputElement;

export default forwardRef<InputRef, InputProps>(
  (
    {
      autoComplete = 'off',
      children,
      className = '',
      disabled = false,
      errorMessage,
      hasError = false,
      label = '',
      maxLength,
      name,
      onBlur,
      onChange,
      onFocus,
      placeholder,
      type,
      value,
      inputClassName,
      labelTipClick,
    },
    ref
  ) => {
    const [state, setState] = useStateObject({
      showError: false,
    });
    const { showError } = state;

    useEffect(() => {
      setState({ showError: errorMessage && hasError });
    }, [errorMessage, hasError, setState]);

    const textInputRef = useCombinedRefs(ref, useRef<HTMLElement | null>(null));

    const onInputChange: React.FormEventHandler<HTMLInputElement> = (
      e
    ): void => {
      onChange(e);
    };

    const onInputBlur = (e: any): void => {
      const onBlurFromProps = onBlur || null;

      if (onBlurFromProps) {
        onBlurFromProps(e);
      }

      setState({
        showError: errorMessage && hasError,
      });
    };

    const onInputFocus = (e: any): void => {
      const onFocusFromProps = onFocus || null;

      if (onFocusFromProps) {
        onFocusFromProps(e);
      }
    };

    const onClickErrorMessage = (e: any): void => {
      setState({ showError: !showError });
      if (
        textInputRef &&
        typeof textInputRef === 'object' &&
        textInputRef.current
      ) {
        textInputRef.current.focus();
      }
    };

    const rootClassNames = classnames([styles.root, className]);
    const inputClassNames = classnames([
      styles.input,
      inputClassName,
      hasError ? styles.inputError : '',
    ]);
    const labelClassNames = classnames([
      styles.label,
      !label && styles.labelIsHidden,
    ]);

    const additionalProps: any = {};

    if (maxLength || maxLength === 0) {
      additionalProps.maxLength =
        maxLength === 'true' ? MAX_LENGTH_DEFAULT : maxLength;
    }

    return (
      <div className={rootClassNames}>
        <div className={styles.labelContainer}>
          <label id={`${name}_lbl`} htmlFor={name} className={labelClassNames}>
            {label || placeholder}{' '}
          </label>

          {labelTipClick && (
            <button className={styles.infoButton} onClick={labelTipClick}>
              <CircleInfoIcon className={sharedStyles.infoIcon} />
            </button>
          )}
        </div>
        <input
          aria-labelledby={`${name}_lbl`}
          autoComplete={autoComplete}
          className={inputClassNames}
          data-testid={name}
          disabled={disabled}
          id={name}
          name={name}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          placeholder={placeholder}
          ref={textInputRef}
          value={value}
          type={type}
          {...additionalProps}
        />
        {showError ? (
          <div className={styles.errorMessage} onClick={onClickErrorMessage}>
            <span
              className={styles.errorMessageInner}
              data-testid={`${name}-error-message`}
            >
              <ErrorIcon className={styles.errorIcon} />
              {errorMessage}
            </span>
          </div>
        ) : null}

        {children ? <div className={styles.content}>{children}</div> : null}
      </div>
    );
  }
);
